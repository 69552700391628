import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HttpsIcon from "@mui/icons-material/Https";
import LockIcon from "@mui/icons-material/Lock";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import {LoadingButton} from "@mui/lab";
import {Grid, LinearProgress, Link, MenuItem, Select} from "@mui/material";
import {Space} from "antd";
import Search from "antd/es/transfer/search";
import dayjs from "dayjs";
import moment from "moment";
import {useEffect, useState} from "react";
import {useLocation} from "react-router-dom";
import {useTheme} from "styled-components";
import api from "../axios/axios";
import Carregar, {CarregarLoading} from "../carregar";
import Erro from "../erro";
import CardMenu, {MenuPhone} from "../menu";
import MenuSuperior from "../menuSuperior";
import {mobile, RangeDatePicker, StyledDatePicker, StyledResultAntd, StyledSearchContainer,} from "../theme";

moment.locale("pt-br");

const Aulas = ({ decodedToken }: any) => {
  const theme = useTheme();
  const [aulas, setAulas] = useState<any>({ items: [], total: 0 });
  const [disciplinas, setDisciplinas] = useState<any>(null);
  const [disciplinaSelecao, setDisciplinaSelecao] = useState<any>({ id: -1 });
  const [turma, setTurma] = useState<any>(null);
  const [escola, setEscola] = useState<any>(null);
  const [escolaSelecao, setEscolaSelecao] = useState({
    nome: "Nenhuma escola",
    id: -1,
  });
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const inicio = params.get("inicio");
  const fim = params.get("fim");
  const classe = window.location.href.includes("/aulas") ? "aula" : "trilha";
  const [data, setData] = useState<any>([
    inicio ? dayjs(inicio, "DD-MM-YYYY") : null,
    fim ? dayjs(inicio, "DD-MM-YYYY") : null,
  ]);

  // useEffect(() => {
  //   let update = false;
  //   const fim = dayjs();
  //   const inicio = dayjs().subtract(7, "day");
  //   if (data[1] === null) {
  //     update = true;
  //   }
  //   if (data[0] === null) {
  //     update = true;
  //   }
  //   if (update) setData([inicio, fim]);
  // }, []);

  const size = 20;
  const [configuracao, setConfiguracao] = useState<any>({
    loading: true,
    erro: false,
    loadingVideo: true,
    carregarMais: false,
    selecaoManual: false,
  });
  const [turmaSelecao, setTurmaSelecao] = useState({
    nome: "Nenhuma turma",
    id: -1,
  });

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [buscar, setBuscar] = useState("");
  useEffect(() => {
    if (buscar !== "") {
      const delay = setTimeout(
        () => {
          if (buscar.trim() !== "") {
            api
              .get(
                `turma/aula/${disciplinaSelecao?.id}?pesquisar=${buscar}&page=1`
              )
              .then((r) => {
                setAulas(r.data);
              })
              .finally(() => {
                setConfiguracao((antes: any) => {
                  return {
                    ...antes,
                    loadingVideo: false,
                  };
                });
              });
          } else if (buscar === "") {
            setConfiguracao((antes: any) => {
              return {
                ...antes,
                loadingVideo: false,
              };
            });
          }
        },

        2000
      );

      return () => clearTimeout(delay);
    } else if (buscar === "" && disciplinaSelecao?.id !== -1) {
      let url;
      if (data && data[0] && data[1]) {
        url = `turma/disciplina/aula/${
          disciplinaSelecao?.id
        }?page=${1}&inicio=${data[0].format("DD-MM-YYYY")}&fim=${data[1].format(
          "DD-MM-YYYY"
        )}`;
      } else {
        url = `turma/disciplina/aula/${disciplinaSelecao?.id}?page=${1}`;
      }
      api
        .get(url)
        .then((r) => {
          setAulas((ant: any) => {
            let novo = [...ant.items];
            r.data.items.map((i: any) => novo.push(i));

            return { ...r.data, items: novo };
          });
        })
        .finally(() =>
          setConfiguracao((antes: any) => {
            return { ...antes, carregarMais: false, loadingVideo: false };
          })
        );
    }
  }, [buscar]);

  const handleBuscar = (e: any) => {
    if (e.target.value === "") {
      setConfiguracao((antes: any) => {
        return {
          ...antes,
          loadingVideo: false,
        };
      });
    } else {
      setConfiguracao((antes: any) => {
        return {
          ...antes,
          loadingVideo: true,
        };
      });
    }

    setAulas({ items: [], total: 0 });
    setBuscar(e.target.value);
  };

  useEffect(() => {
    api
      .get("escola")
      .then((r): any => {
        setEscola(r.data);
        if (r.data?.items?.length > 0) {
          const escola = r.data?.items[0];
          setEscola(r.data);
          setEscolaSelecao(escola);
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loading: false,
            };
          });
        } else {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loading: false,
              loadingVideo: false,
            };
          });
        }
      })
      .catch((e) => {
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            loading: false,
            loadingVideo: false,
          };
        });
        console.log(e);
      });
  }, []);

  useEffect(() => {
    if (escolaSelecao?.id && escolaSelecao?.id !== -1) {
      setConfiguracao((antes: any) => {
        return { ...antes, loadingVideo: true };
      });

      setTurma({
        items: [
          {
            nome: "Nenhuma turma",
            id: -1,
          },
        ],
      });

      setTurmaSelecao({
        nome: "Nenhuma turma",
        id: -1,
      });

      setDisciplinaSelecao({ id: -1 });
      setDisciplinas({ id: -1 });

      api
        .get("escola/turma/" + escolaSelecao?.id)
        .then((r): any => {
          setTurma(r.data);
          if (r.data?.items?.length > 0) {
            const turma = r.data?.items[0];
            setTurmaSelecao(turma);
          } else {
            setConfiguracao((antes: any) => {
              return {
                ...antes,
                loading: false,
                loadingVideo: false,
              };
            });
          }
        })

        .catch((e) => {
          setConfiguracao((antes: any) => {
            return {
              ...antes,
              loading: false,
              loadingVideo: false,
            };
          });
        });
    }
  }, [escolaSelecao]);

  const handlePage = (page: number) => {
    setConfiguracao((antes: any) => {
      return { ...antes, carregarMais: true };
    });
    if (buscar !== "" && disciplinaSelecao?.id !== -1) {
      api
        .get(
          `turma/aula/${disciplinaSelecao?.id}?pesquisar=${buscar}&page=${page}`
        )
        .then((r) => {
          setAulas((ant: any) => {
            let novo = [...ant.items];
            r.data.items.map((i: any) => novo.push(i));

            return { ...r.data, items: novo };
          });
        })
        .finally(() => {
          setConfiguracao((antes: any) => {
            return { ...antes, carregarMais: false };
          });
        });
    } else if (disciplinaSelecao?.id !== -1) {
      let url = "";
      if (data && data[0] && data[1]) {
        url = `turma/disciplina/aula/${
          disciplinaSelecao?.id
        }?page=${page}&size=${size}&inicio=${data[0].format(
          "DD-MM-YYYY"
        )}&fim=${data[1].format("DD-MM-YYYY")}`;
      } else {
        url = `turma/disciplina/aula/${disciplinaSelecao?.id}?page=${page}&size=${size}`;
      }
      api
        .get(url)
        .then((r) => {
          setAulas((ant: any) => {
            let novo = [...ant.items];
            r.data.items.map((i: any) => novo.push(i));

            return { ...r.data, items: novo };
          });
        })
        .finally(() =>
          setConfiguracao((antes: any) => {
            return { ...antes, carregarMais: false };
          })
        );
    }
  };

  useEffect(() => {
    if (buscar !== "" && disciplinaSelecao?.id !== -1) {
      setConfiguracao((antes: any) => {
        return { ...antes, loadingVideo: true };
      });
      setAulas({ items: [], total: 0 });
      const delay = setTimeout(
        () => {
          if (buscar.trim() !== "") {
            api
              .get(
                `turma/aula/${disciplinaSelecao?.id}?pesquisar=${buscar}&page=1`
              )
              .then((r) => {
                setAulas(r.data);
              })
              .finally(() => {
                setConfiguracao((antes: any) => {
                  return {
                    ...antes,
                    loadingVideo: false,
                  };
                });
              });
          } else if (buscar === "") {
            setConfiguracao((antes: any) => {
              return {
                ...antes,
                loadingVideo: false,
              };
            });
          }
        },

        2000
      );

      return () => clearTimeout(delay);
    } else if (disciplinaSelecao?.id && disciplinaSelecao?.id !== -1) {
      setConfiguracao((antes: any) => {
        return {
          ...antes,
          loadingVideo: true,
        };
      });
      setAulas({ items: [], total: 0 });
      let url;

      if (data && data[0] && data[1]) {
        url = `turma/disciplina/aula/${
          disciplinaSelecao?.id
        }?page=${1}&size=${size}&inicio=${data[0].format(
          "DD-MM-YYYY"
        )}&fim=${data[1].format("DD-MM-YYYY")}`;
      } else {
        url = `turma/disciplina/aula/${
          disciplinaSelecao?.id
        }?page=${1}&size=${size}`;
      }
      api
        .get(url)
        .then((r) => {
          setAulas(r.data);
        })
        .finally(() =>
          setConfiguracao((antes: any) => {
            return { ...antes, carregarMais: false, loadingVideo: false };
          })
        );
    }
  }, [disciplinaSelecao, data]);

  useEffect(() => {
    if (turmaSelecao?.id && turmaSelecao?.id !== -1) {
      setConfiguracao((antes: any) => {
        return { ...antes, loadingVideo: true };
      });
      setDisciplinaSelecao({ id: -1 });
      setDisciplinas({ id: -1 });
      api
        .get(
          "turma/disciplina/" +
            turmaSelecao?.id +
            `?classe=${classe}&conteudo=true`
        )
        .then((r) => {
          setDisciplinas(r.data);

          setDisciplinaSelecao(r.data?.items[0]);
          if (r.data?.items?.length === 0)
            setConfiguracao((antes: any) => {
              return { ...antes, loadingVideo: false };
            });
        })
        .catch(() =>
          setConfiguracao((antes: any) => {
            return { ...antes, loadingVideo: false };
          })
        );
    }
  }, [turmaSelecao]);

  if (configuracao?.erro) return <Erro />;

  if (configuracao?.loading) return <Carregar />;

  return (
    <>
      <Grid
        container
        direction="row"
        style={{ background: theme.cor.cor_fundo }}
      >
        <CardMenu
          decodedToken={decodedToken}
          menuSelecao={3}
          ativo={mobile(windowSize)}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto" }}
          width={"100%"}
        >
          <MenuPhone
            ativo={mobile(windowSize) ? true : false}
            decodedToken={decodedToken}
          />
          <Grid
            item
            style={{
              background: theme.cor.cor_branco_100,
              padding: 0,
              margin: 0,
            }}
          >
            <MenuSuperior ativo={mobile(windowSize)} texto={"Aulas"} />
          </Grid>
          <Grid
            item
            padding={3}
            paddingRight={1}
            marginTop={mobile(windowSize) ? "" : "4rem"}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="flex-start"
            >
              <Grid item>
                <Space direction="vertical">
                  <StyledSearchContainer>
                    <Search
                      handleClear={() =>
                        setConfiguracao((antes: any) => {
                          return {
                            ...antes,
                            loadingVideo: true,
                          };
                        })
                      }
                      disabled={disciplinaSelecao?.id === -1 ? true : false}
                      value={buscar}
                      onChange={handleBuscar}
                      placeholder="Pesquisar..."
                    />
                  </StyledSearchContainer>
                </Space>
              </Grid>

              <Grid item xs={true}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="flex-start"
                  spacing={2}
                  paddingBottom={"1rem"}
                >
                  <Grid item sx={{ maxWidth: "100%" }}>
                    <Select
                      inputProps={{
                        MenuProps: {
                          MenuListProps: {
                            sx: {
                              backgroundColor: theme.cor.cor_branco_100,
                            },
                          },
                        },
                      }}
                      id="escola"
                      disabled={buscar !== "" ? true : false}
                      value={escolaSelecao.id}
                      label="Escola"
                      style={{
                        color: theme.cor.cor_preto_300,
                        height: "33px",
                        maxWidth: "100%",
                        fontFamily: theme.fonte.font.fonte_1,
                      }}
                      // {...register("tipo")}
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme.cor.cor_principal_500,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },

                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                        background: theme.cor.cor_branco_100,
                        color: theme.cor.cor_preto_300,
                        fontFamily: theme.fonte.font.fonte_1,
                      }}
                    >
                      {escola?.items?.length > 0 ? (
                        escola?.items?.map((t: any) => {
                          return (
                            <MenuItem
                              sx={{
                                color: theme.cor.cor_preto_300,
                                fontFamily: theme.fonte.font.fonte_1,
                                "&:hover": {
                                  background: theme.cor.cor_principal_300,
                                  color: theme.cor.cor_branco_100,
                                },
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                                "&.Mui-selected": {
                                  background: theme.cor.cor_principal_200,
                                  color: theme.cor.cor_branco_100,
                                },
                              }}
                              onClick={(e) => {
                                if (escolaSelecao?.id !== t?.id) {
                                  setEscolaSelecao(t);
                                  setAulas({ items: [], total: 0 });
                                  setTurma({ items: [], total: 0 });
                                  setConfiguracao((antes: any) => {
                                    return {
                                      ...antes,
                                      loadingVideo: true,
                                      selecaoManual: true,
                                    };
                                  });
                                }
                              }}
                              key={t?.id}
                              value={t?.id}
                            >
                              {t?.nome}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          sx={{
                            color: theme.cor.cor_preto_300,
                            fontFamily: theme.fonte.font.fonte_1,
                            "&:hover": {
                              background: theme.cor.cor_principal_300,
                              color: theme.cor.cor_branco_100,
                            },
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            "&.Mui-selected": {
                              background: theme.cor.cor_principal_200,
                              color: theme.cor.cor_branco_100,
                            },
                          }}
                          key={-1}
                          value={-1}
                        >
                          Nenhuma escola
                        </MenuItem>
                      )}
                    </Select>
                  </Grid>
                  <Grid item sx={{ maxWidth: "100%" }}>
                    <Select
                      inputProps={{
                        MenuProps: {
                          MenuListProps: {
                            sx: {
                              backgroundColor: theme.cor.cor_branco_100,
                            },
                          },
                        },
                      }}
                      disabled={buscar !== "" ? true : false}
                      id="turma"
                      value={turmaSelecao.id}
                      label="Turma"
                      style={{
                        color: theme.cor.cor_preto_300,
                        height: "33px",
                        maxWidth: "100%",
                        fontFamily: theme.fonte.font.fonte_1,
                      }}
                      // {...register("tipo")}
                      sx={{
                        ".MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: theme.cor.cor_principal_500,
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "transparent",
                        },
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                        background: theme.cor.cor_branco_100,
                        color: theme.cor.cor_preto_300,
                        fontFamily: theme.fonte.font.fonte_1,
                      }}
                    >
                      {turma?.items?.length > 0 ? (
                        turma?.items?.map((t: any) => {
                          return (
                            <MenuItem
                              sx={{
                                color: theme.cor.cor_preto_300,
                                fontFamily: theme.fonte.font.fonte_1,
                                "&:hover": {
                                  background: theme.cor.cor_principal_300,
                                  color: theme.cor.cor_branco_100,
                                },
                                whiteSpace: "normal",
                                wordWrap: "break-word",
                                "&.Mui-selected": {
                                  background: theme.cor.cor_principal_200,
                                  color: theme.cor.cor_branco_100,
                                },
                              }}
                              onClick={(e) => {
                                if (turmaSelecao?.id !== t?.id) {
                                  setConfiguracao((antes: any) => {
                                    return {
                                      ...antes,
                                      loadingVideo: true,
                                    };
                                  });
                                  setAulas({ items: [], total: 0 });
                                  setTurmaSelecao(t);
                                }
                              }}
                              key={t?.id}
                              value={t?.id}
                            >
                              {t?.nome}
                            </MenuItem>
                          );
                        })
                      ) : (
                        <MenuItem
                          sx={{
                            color: theme.cor.cor_preto_300,
                            fontFamily: theme.fonte.font.fonte_1,
                            "&:hover": {
                              background: theme.cor.cor_principal_300,
                              color: theme.cor.cor_branco_100,
                            },
                            whiteSpace: "normal",
                            wordWrap: "break-word",
                            "&.Mui-selected": {
                              background: theme.cor.cor_principal_200,
                              color: theme.cor.cor_branco_100,
                            },
                          }}
                          key={-1}
                          value={-1}
                        >
                          Nenhuma turma
                        </MenuItem>
                      )}
                    </Select>
                  </Grid>
                  {disciplinas?.items?.length > 0 ? (
                    <Grid item>
                      <StyledDatePicker>
                        <RangeDatePicker
                          format="DD/MM/YYYY"
                          style={{
                            width: "100%",
                            height: "34px",
                            boxShadow:
                              "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                          }}
                          disabled={buscar !== "" ? true : false}
                          defaultValue={data}
                          onChange={(e: any) => {
                            setData(e);
                          }}
                        />
                      </StyledDatePicker>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  {disciplinas?.items?.length > 0
                    ? disciplinas?.items?.map((item: any) => (
                        <Grid
                          item
                          key={item?.id}
                          style={{
                            marginTop:
                              disciplinaSelecao?.id === item?.id ? "" : ".5rem",
                          }}
                        >
                          <p
                            onClick={() => {
                              if (disciplinaSelecao?.id !== item?.id) {
                                setAulas({ items: [], total: 0 });
                                setConfiguracao((antes: any) => {
                                  return {
                                    ...antes,
                                    loadingVideo: true,
                                  };
                                });

                                setDisciplinaSelecao(item);
                              }
                            }}
                            style={{
                              fontWeight: theme.fonte.bold.semi_bold,
                              fontSize: "15px",
                              fontFamily: theme.fonte.font.fonte_1,
                              cursor: "pointer",
                              userSelect: "none",
                              color:
                                disciplinaSelecao?.id === item?.id
                                  ? theme.cor.cor_principal_500
                                  : theme.cor.cor_preto_300,
                            }}
                          >
                            {item.nome}
                          </p>
                        </Grid>
                      ))
                    : ""}
                </Grid>
              </Grid>
              {disciplinas?.items?.length > 0 ? (
                <Grid item xs={12} style={{ marginRight: "1rem" }}>
                  <LinearProgress
                    sx={{
                      "& .MuiLinearProgress-bar": {
                        backgroundColor:
                        theme.cor
                            .cor_principal_400 /* `linear-gradient(45deg, rgba(${hexToRgb(
                                                          theme.cor
                                                            .cor_principal_500
                                                        )}, 1) 0%, rgba(${hexToRgb(
                                                          theme.cor
                                                            .cor_principal_400
                                                        )}, 1) 100%)`,*/,
                        borderRadius: "1rem",
                      },

                      height: 5,
                      borderRadius: 5,
                      background: theme.cor.cor_branco_300,
                    }}
                    variant="determinate"
                    value={
                      ((disciplinas?.items?.findIndex(
                        (item: any) => item?.id === disciplinaSelecao?.id
                      ) +
                        1) /
                        disciplinas?.items?.length) *
                      100
                    }
                  />
                </Grid>
              ) : configuracao?.loadingVideo === false && buscar !== "" ? (
                ""
              ) : configuracao?.loadingVideo ? (
                <CarregarLoading />
              ) : (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <StyledResultAntd
                      status="success"
                      title="Nenhuma disciplina cadastrada!"
                      subTitle="Nenhuma disciplina cadastrada!"
                    />
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            xs={true}
            marginTop={"2rem"}
            marginRight={"1rem"}
            maxHeight={"calc(100vh - 260px)"}
            style={{
              overflow: "auto",
              marginLeft: "1.5rem",
              marginBottom: 0,
              scrollbarColor: `${theme.cor.cor_principal_500} ${theme.cor.scroll}`,
              scrollbarWidth: "thin",
            }}
          >
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
            >
              {configuracao?.loadingVideo && disciplinas?.items?.length > 0 ? (
                <CarregarLoading />
              ) : disciplinas?.items?.length > 0 &&
                aulas?.items?.length === 0 &&
                disciplinaSelecao?.id !== -1 ? (
                <Grid container justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <StyledResultAntd
                      status="success"
                      title="Nenhuma aula encontrada!"
                      subTitle="Nenhuma aula encontrada!"
                    />
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              {aulas?.items?.map((aula: any, index: any) => (
                <Grid
                  item
                  height={200}
                  width={theme.fonte.bold.normal}
                  style={{
                    marginTop: "1rem",
                    marginLeft: "1rem",
                    background: theme.cor.cor_principal_500,
                    borderRadius: "1rem",
                  }}
                  key={aula?.id}
                >
                  {(aula?.submissao?.length > 0 &&
                    (aula?.tipo === "avaliacao" ||
                      aula?.tipo === "avaliacao_quiz")) ||
                  moment(aula.data_entrega)
                    .startOf("day")
                    .isBefore(moment().startOf("day")) ? (
                    <Grid
                      container
                      direction="column"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <div
                        style={{
                          position: "relative",
                          cursor: "default",
                          width: "100%",
                          height: "200px",
                          overflow: "hidden",
                        }}
                      >
                        <iframe
                          style={{
                            border: 0,
                            position: "absolute",
                            width: "130%",
                            height: "130%",
                            right: -40,
                            top: -1,
                          }}
                          src={aula.link}
                        ></iframe>
                        <div
                          style={{
                            background: "rgba(0, 0, 0, 0.7)",
                            position: "absolute",
                            width: "100%",
                            height: "100%",
                          }}
                        ></div>
                        <div
                          style={{
                            position: "absolute",
                            top: 10,
                            right: 10,
                            color: theme.cor.cor_branco_100,
                          }}
                        >
                          <LockIcon style={{ width: "40px", height: "40px" }} />
                        </div>
                        {aula?.submissao?.length > 0 ? (
                          <div
                            style={{
                              position: "absolute",
                              bottom: 10,
                              right: 10,
                              color: theme.cor.cor_verde_500,
                            }}
                          >
                            <CheckCircleIcon
                              style={{ width: "40px", height: "40px" }}
                            />
                          </div>
                        ) : (
                          ""
                        )}
                        {aula?.data_entrega ? (
                          <div
                            style={{
                              position: "absolute",
                              left: 10,
                              top: 10,
                              background: theme.cor.cor_amarelo_500,
                              fontFamily: theme.fonte.font.fonte_1,
                              padding: ".5rem",
                              borderRadius: "1rem",
                              fontSize: "11px",
                            }}
                          >
                            Data de Entrega:{" "}
                            {moment(aula?.data_entrega).format("DD/MM/YYYY")}
                          </div>
                        ) : (
                          ""
                        )}
                        {/* <div
                                  style={{
                                    position: "absolute",
                                    left: 10,
                                    top: 10,
                                    background: theme.cor.cor_amarelo_500,
                                    fontFamily: theme.fonte.font.fonte_1,
                                    padding: ".5rem",
                                    borderRadius: "1rem",
                                    fontSize: "11px",
                                  }}
                                >
                                  D{index + 1}
                                </div> */}
                        {aula.status ? (
                          ""
                        ) : (
                          <div
                            style={{
                              position: "absolute",
                              left: 0,
                              top: 0,
                              color: theme.cor.cor_branco_100,
                              padding: ".5rem",
                              borderRadius: "1rem",
                              fontSize: "11px",
                            }}
                          >
                            <HttpsIcon />
                          </div>
                        )}

                        <div
                          style={{
                            position: "absolute",
                            left: 10,
                            top: 80,
                            fontWeight: theme.fonte.bold.semi_bold,
                            background: theme.cor.cor_branco_100,
                            fontFamily: theme.fonte.font.fonte_1,
                            color: theme.cor.cor_preto_400,
                            padding: ".5rem",
                            borderRadius: "1rem",
                            fontSize: "18px",
                            textOverflow: "ellipsis",
                            maxWidth: "321px",
                            overflow: "hidden",
                            margin: 0,
                            maxHeight: "2.57rem",
                            WebkitLineClamp: 1,
                            WebkitBoxOrient: "vertical",
                            display: "-webkit-box",
                          }}
                        >
                          {aula?.nome}
                        </div>
                        <div>
                          <p
                            style={{
                              textAlign: "justify",
                              position: "absolute",
                              left: 20,
                              top: 120,
                              fontFamily: theme.fonte.font.fonte_1,
                              color: theme.cor.cor_branco_100,
                              fontSize: "11px",
                              marginRight: "1rem",
                              overflowWrap: "break-word",
                              width: "90%",
                              maxHeight: "34%",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                              WebkitLineClamp: 6,
                              WebkitBoxOrient: "vertical",
                              display: "-webkit-box",
                            }}
                          >
                            {aula?.descricao}
                          </p>
                        </div>
                      </div>
                    </Grid>
                  ) : (
                    <Link href={"/aulas/" + aula?.id}>
                      <Grid
                        container
                        direction="column"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <div
                          style={{
                            position: "relative",
                            cursor: "pointer",
                            width: "100%",
                            height: "200px",
                            overflow: "hidden",
                          }}
                        >
                          <iframe
                            style={{
                              border: 0,
                              position: "absolute",
                              width: "130%",
                              height: "130%",
                              right: -40,
                              top: -1,
                            }}
                            src={aula.link}
                          ></iframe>
                          <div
                            style={{
                              background: "rgba(0, 0, 0, 0.7)",
                              position: "absolute",
                              width: "100%",
                              height: "100%",
                            }}
                          ></div>

                          {aula?.data_entrega ? (
                            <div
                              style={{
                                position: "absolute",
                                left: 10,
                                top: 10,
                                background: theme.cor.cor_amarelo_500,
                                fontFamily: theme.fonte.font.fonte_1,
                                padding: ".5rem",
                                borderRadius: "1rem",
                                fontSize: "11px",
                              }}
                            >
                              Data de Entrega:{" "}
                              {moment(aula?.data_entrega).format("DD/MM/YYYY")}
                            </div>
                          ) : (
                            ""
                          )}
                          <div
                            style={{
                              position: "absolute",
                              top: 10,
                              right: 10,
                              color: theme.cor.cor_branco_100,
                            }}
                          >
                            <PlayCircleOutlineIcon
                              style={{ width: "40px", height: "40px" }}
                            />
                          </div>
                          {aula?.submissao?.length > 0 ? (
                            <div
                              style={{
                                position: "absolute",
                                bottom: 10,
                                right: 10,
                                color: theme.cor.cor_verde_500,
                              }}
                            >
                              <CheckCircleIcon
                                style={{ width: "40px", height: "40px" }}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                          {/* <div
                          style={{
                            position: "absolute",
                            left: 10,
                            top: 10,
                            background: theme.cor.cor_amarelo_500,
                            fontFamily: theme.fonte.font.fonte_1,
                            padding: ".5rem",
                            borderRadius: "1rem",
                            fontSize: "11px",
                          }}
                        >
                          D{index + 1}
                        </div> */}
                          {aula.status ? (
                            ""
                          ) : (
                            <div
                              style={{
                                position: "absolute",
                                left: 0,
                                top: 0,
                                color: theme.cor.cor_branco_100,
                                padding: ".5rem",
                                borderRadius: "1rem",
                                fontSize: "11px",
                              }}
                            >
                              <HttpsIcon />
                            </div>
                          )}
                          <div
                            style={{
                              position: "absolute",
                              left: 10,
                              top: 80,
                              fontWeight: theme.fonte.bold.semi_bold,
                              background: theme.cor.cor_branco_100,
                              fontFamily: theme.fonte.font.fonte_1,
                              color: theme.cor.cor_preto_400,
                              padding: ".5rem",
                              borderRadius: "1rem",
                              fontSize: "18px",
                              textOverflow: "ellipsis",
                              maxWidth: "321px",
                              overflow: "hidden",
                              margin: 0,
                              maxHeight: "2.57rem",
                              WebkitLineClamp: 1,
                              WebkitBoxOrient: "vertical",
                              display: "-webkit-box",
                            }}
                          >
                            {aula?.nome}
                          </div>
                          <div>
                            <p
                              style={{
                                textAlign: "justify",
                                position: "absolute",
                                left: 20,
                                top: 120,
                                fontFamily: theme.fonte.font.fonte_1,
                                color: theme.cor.cor_branco_100,
                                fontSize: "11px",
                                marginRight: "1rem",
                                overflowWrap: "break-word",
                                width: "90%",
                                maxHeight: "34%",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                WebkitLineClamp: 6,
                                WebkitBoxOrient: "vertical",
                                display: "-webkit-box",
                              }}
                            >
                              {aula?.descricao}
                            </p>
                          </div>
                        </div>
                      </Grid>
                    </Link>
                  )}
                </Grid>
              ))}
            </Grid>
            {aulas?.items?.length !== aulas?.total ? (
              <Grid item xs={12}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item>
                    <LoadingButton
                      loading={configuracao?.carregarMais}
                      variant="outlined"
                      sx={{
                        background: theme.cor.cor_branco_100,
                        color: theme.cor.cor_preto_300,
                        fontFamily: theme.fonte.font.fonte_1,
                        borderColor: theme.cor.cor_branco_100,
                        marginTop: "1rem",
                        marginBottom: ".5rem",
                        "&:hover": {
                          borderColor: theme.cor.cor_principal_500,
                        },
                      }}
                      onClick={(e) => handlePage(aulas.page + 1)}
                    >
                      Carregar mais
                    </LoadingButton>
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Aulas;

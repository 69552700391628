import { Grid, Typography } from "@mui/material";
import Ips from "../../img/logo_ips.png";
import BonecoIPS from "../../img/ips/Theophilos_03_novo 2.png";

const BoasVindas = ({ theme, Ret04, Boneco, decodedToken }: any) => {
  if (decodedToken.contrato === "ips") {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="stretch"
        style={{
          backgroundImage: `linear-gradient(to right, ${theme.cor.cor_principal_500}, ${theme.cor.cor_principal_500})`,
          borderRadius: "1rem",
          boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
        }}
      >
        <Grid item xs minHeight={"100%"}>
          <Grid
            container
            wrap="nowrap"
            direction="row"
            justifyContent="center"
            alignItems="center"
            padding={2}
            paddingRight={0}
            minHeight={"100%"}
          >
            <Grid
              item
              paddingRight={0}
              style={{
                overflow: "hidden",
              }}
            >
              <Grid item paddingRight={0}>
                <Typography
                  paddingRight={0}
                  style={{
                    fontWeight: theme.fonte.bold.semi_bold,
                    fontSize: "24px",
                    fontFamily: theme.fonte.font.fonte_1,
                    color: theme.cor.cor_branco_100,
                  }}
                >
                  É muito bom te ter de volta!
                </Typography>
              </Grid>
              <Grid item paddingRight={0}>
                <Typography
                  paddingRight={0}
                  style={{
                    fontFamily: theme.fonte.font.fonte_1,
                    color: theme.cor.cor_branco_100,
                  }}
                >
                  Juntos, fazemos a diferença todos os dias.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={4} sm={4} md={5} lg={2} xl={0.5} marginTop={"1.5rem"}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-end"
            minHeight={"100%"}
            height={150}
            marginLeft={"-1rem"}
          >
            <Grid item>
              <img
                src={Ips}
                height={"170px"}
                style={{ marginTop: "-1.4rem", paddingLeft: "7rem" }}
                alt="Imagem de boas vindas"
              />
            </Grid>
            <Grid item>
              <img
                src={BonecoIPS}
                height={"140px"}
                style={{
                  left: 33,
                  marginTop: "-21rem",
                  paddingBottom: "1rem",
                  marginRight: "5.8rem",
                  zIndex: 1, // Garante que fique na frente
                }}
                alt="Imagem de boas vindas"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      style={{
        backgroundImage: `linear-gradient(to right, ${theme.cor.cor_principal_500}, ${theme.cor.cor_amarelo_500})`,
        borderRadius: "1rem",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      }}
    >
      <Grid item minHeight={"100%"}>
        <img
          style={{ marginTop: "-.8rem" }}
          src={Ret04}
          alt="Imagem de boas vindas"
        />
      </Grid>
      <Grid item minHeight={"100%"}>
        <Grid
          container
          wrap="nowrap"
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          padding={2}
          paddingRight={0}
          minHeight={"100%"}
          style={{ maxHeight: 150 }}
        >
          <Grid item xs paddingRight={0} style={{ overflow: "hidden" }}>
            <Grid item paddingRight={0}>
              <Typography
                paddingRight={0}
                style={{
                  fontWeight: theme.fonte.bold.semi_bold,
                  fontSize: "24px",
                  fontFamily: theme.fonte.font.fonte_1,
                  color: theme.cor.cor_branco_100,
                }}
              >
                É muito bom te ter de volta!
              </Typography>
            </Grid>
            <Grid item paddingRight={0}>
              <Typography
                paddingRight={0}
                style={{
                  fontFamily: theme.fonte.font.fonte_1,
                  color: theme.cor.cor_branco_100,
                }}
              >
                Juntos, fazemos a diferença todos os dias.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs marginTop={"1.5rem"}>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="flex-end"
          minHeight={"100%"}
          height={150}
          marginLeft={"-1rem"}
        >
          <Grid item>
            <img
              src={Boneco}
              height={"200"}
              style={{ marginTop: "-50px" }}
              alt="Imagem de boas vindas"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BoasVindas;

import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { DatePicker, Result } from "antd";
import { renderToString } from "react-dom/server";
import { SiGooglegemini } from "react-icons/si";
import styled from "styled-components";
import { DateCalendar } from "@mui/x-date-pickers";
import { Table } from "@mui/material";

export const theme = {
  govtech: {
    cor: {
      cor_cinza_300: "#929292",

      cor_principal_500: "#00519E",
      cor_principal_400: "#2C6FAE",
      cor_principal_300: "#548ABD",
      cor_principal_200: "#92B1CE",
      cor_principal_100: "#BED0E2",

      cor_principal: "#201547",
      cor_secundaria: "#6638B6",
      cor_terciaria: "#F3069F",
      cor_sappens: "#391547",
      cor_sappens_2: "#152347",
      cor_fundo: "#F6F6F6",
      sappens: "#4EE6B3",
      scroll: "#EDF2F6",

      cor_verde_secundaria_500: "#1FA22E",
      cor_verde_secundaria_400: "#3DB14B",
      cor_verde_secundaria_300: "#63C56E",
      cor_verde_secundaria_200: "#8CD294",
      cor_verde_secundaria_100: "#A4DAAA",

      cor_amarelo_500: "#FFDD00",
      cor_amarelo_400: "#FFE84C",
      cor_amarelo_300: "#FFEE76",
      cor_amarelo_200: "#FFF5AD",
      cor_amarelo_100: "#FFF9D2",

      cor_verde_escuro_secundaria_500: "#004F4B",
      cor_verde_escuro_secundaria_400: "#175855",
      cor_verde_escuro_secundaria_300: "#367572",
      cor_verde_escuro_secundaria_200: "#5C9693",
      cor_verde_escuro_secundaria_100: "#97C0BE",

      cor_amarelo_escuro_secundaria_500: "#F7931E",
      cor_amarelo_escuro_secundaria_400: "#FEAA48",
      cor_amarelo_escuro_secundaria_300: "#FCC380",
      cor_amarelo_escuro_secundaria_200: "#FFD6A7",
      cor_amarelo_escuro_secundaria_100: "#FFECD7",

      cor_preto_500: "#030C15",
      cor_preto_400: "#0D2237",
      cor_preto_300: "#28425B",
      cor_preto_200: "#727D99",
      cor_preto_100: "#929CB7",

      cor_branco_500: "#ACB3CD",
      cor_branco_400: "#C6CCDC",
      cor_branco_300: "#D5D9E2",
      cor_branco_200: "#F4F7FE",
      cor_branco_100: "#FFFFFF",

      cor_verde_500: "#01913A",
      cor_verde_400: "#0BAB4B",
      cor_verde_300: "#0BC556",
      cor_verde_200: "#56D689",
      cor_verde_100: "#EBFFF4",

      cor_alerta_500: "#FFCD07",
      cor_alerta_400: "#FFD326",
      cor_alerta_300: "#FFDA48",
      cor_alerta_200: "#FFE580",
      cor_alerta_100: "#FFEFAE",

      cor_erro_500: "#B20000",
      cor_erro_400: "#CC0E0E",
      cor_erro_300: "#E01010",
      cor_erro_200: "#E95959",
      cor_erro_100: "#FEEAEB",
    },

    fonte: {
      bold: {
        normal: 400,
        medio: 500,
        semi_bold: 600,
        bold: 700,
      },
      font: {
        fonte_1: "poppins",
      },
    },
  },

  sappens: {
    cor: {
      cor_cinza_300: "#929292",

      cor_principal_500: "#00519E",
      cor_principal_400: "#2C6FAE",
      cor_principal_300: "#548ABD",
      cor_principal_200: "#92B1CE",
      cor_principal_100: "#BED0E2",

      cor_principal: "#201547",
      cor_secundaria: "#6638B6",
      cor_terciaria: "#F3069F",
      cor_sappens: "#391547",
      cor_sappens_2: "#152347",
      cor_fundo: "#F6F6F6",
      sappens: "#4EE6B3",
      scroll: "#EDF2F6",

      cor_verde_secundaria_500: "#1FA22E",
      cor_verde_secundaria_400: "#3DB14B",
      cor_verde_secundaria_300: "#63C56E",
      cor_verde_secundaria_200: "#8CD294",
      cor_verde_secundaria_100: "#A4DAAA",

      cor_amarelo_500: "#FFDD00",
      cor_amarelo_400: "#FFE84C",
      cor_amarelo_300: "#FFEE76",
      cor_amarelo_200: "#FFF5AD",
      cor_amarelo_100: "#FFF9D2",

      cor_verde_escuro_secundaria_500: "#004F4B",
      cor_verde_escuro_secundaria_400: "#175855",
      cor_verde_escuro_secundaria_300: "#367572",
      cor_verde_escuro_secundaria_200: "#5C9693",
      cor_verde_escuro_secundaria_100: "#97C0BE",

      cor_amarelo_escuro_secundaria_500: "#F7931E",
      cor_amarelo_escuro_secundaria_400: "#FEAA48",
      cor_amarelo_escuro_secundaria_300: "#FCC380",
      cor_amarelo_escuro_secundaria_200: "#FFD6A7",
      cor_amarelo_escuro_secundaria_100: "#FFECD7",

      cor_preto_500: "#030C15",
      cor_preto_400: "#0D2237",
      cor_preto_300: "#28425B",
      cor_preto_200: "#727D99",
      cor_preto_100: "#929CB7",

      cor_branco_500: "#ACB3CD",
      cor_branco_400: "#C6CCDC",
      cor_branco_300: "#D5D9E2",
      cor_branco_200: "#F4F7FE",
      cor_branco_100: "#FFFFFF",

      cor_verde_500: "#01913A",
      cor_verde_400: "#0BAB4B",
      cor_verde_300: "#0BC556",
      cor_verde_200: "#56D689",
      cor_verde_100: "#EBFFF4",

      cor_alerta_500: "#FFCD07",
      cor_alerta_400: "#FFD326",
      cor_alerta_300: "#FFDA48",
      cor_alerta_200: "#FFE580",
      cor_alerta_100: "#FFEFAE",

      cor_erro_500: "#B20000",
      cor_erro_400: "#CC0E0E",
      cor_erro_300: "#E01010",
      cor_erro_200: "#E95959",
      cor_erro_100: "#FEEAEB",
    },

    fonte: {
      bold: {
        normal: 400,
        medio: 500,
        semi_bold: 600,
        bold: 700,
      },
      font: {
        fonte_1: "poppins",
      },
    },
  },

  cesan: {
    cor: {
      cor_cinza_300: "#929292",

      cor_principal_500: "#00519E",
      cor_principal_400: "#2C6FAE",
      cor_principal_300: "#548ABD",
      cor_principal_200: "#92B1CE",
      cor_principal_100: "#BED0E2",

      cor_principal: "#201547",
      cor_secundaria: "#6638B6",
      cor_terciaria: "#F3069F",
      cor_sappens: "#391547",
      cor_sappens_2: "#152347",
      cor_fundo: "#F6F6F6",
      sappens: "#4EE6B3",
      scroll: "#EDF2F6",

      cor_verde_secundaria_500: "#1FA22E",
      cor_verde_secundaria_400: "#3DB14B",
      cor_verde_secundaria_300: "#63C56E",
      cor_verde_secundaria_200: "#8CD294",
      cor_verde_secundaria_100: "#A4DAAA",

      cor_amarelo_500: "#FFDD00",
      cor_amarelo_400: "#FFE84C",
      cor_amarelo_300: "#FFEE76",
      cor_amarelo_200: "#FFF5AD",
      cor_amarelo_100: "#FFF9D2",

      cor_verde_escuro_secundaria_500: "#004F4B",
      cor_verde_escuro_secundaria_400: "#175855",
      cor_verde_escuro_secundaria_300: "#367572",
      cor_verde_escuro_secundaria_200: "#5C9693",
      cor_verde_escuro_secundaria_100: "#97C0BE",

      cor_amarelo_escuro_secundaria_500: "#F7931E",
      cor_amarelo_escuro_secundaria_400: "#FEAA48",
      cor_amarelo_escuro_secundaria_300: "#FCC380",
      cor_amarelo_escuro_secundaria_200: "#FFD6A7",
      cor_amarelo_escuro_secundaria_100: "#FFECD7",

      cor_preto_500: "#030C15",
      cor_preto_400: "#0D2237",
      cor_preto_300: "#28425B",
      cor_preto_200: "#727D99",
      cor_preto_100: "#929CB7",

      cor_branco_500: "#ACB3CD",
      cor_branco_400: "#C6CCDC",
      cor_branco_300: "#D5D9E2",
      cor_branco_200: "#F4F7FE",
      cor_branco_100: "#FFFFFF",

      cor_verde_500: "#01913A",
      cor_verde_400: "#0BAB4B",
      cor_verde_300: "#0BC556",
      cor_verde_200: "#56D689",
      cor_verde_100: "#EBFFF4",

      cor_alerta_500: "#FFCD07",
      cor_alerta_400: "#FFD326",
      cor_alerta_300: "#FFDA48",
      cor_alerta_200: "#FFE580",
      cor_alerta_100: "#FFEFAE",

      cor_erro_500: "#B20000",
      cor_erro_400: "#CC0E0E",
      cor_erro_300: "#E01010",
      cor_erro_200: "#E95959",
      cor_erro_100: "#FEEAEB",
    },

    fonte: {
      bold: {
        normal: 400,
        medio: 500,
        semi_bold: 600,
        bold: 700,
      },
      font: {
        fonte_1: "poppins",
      },
    },
  },

  ips: {
    cor: {
      cor_cinza_300: "#929292",

      cor_principal_500: "#C9B665",
      cor_principal_400: "#404040",
      cor_principal_300: "#656AAF",
      cor_principal_200: "#B5B6B8",
      cor_principal_100: "#C4C4C4",

      cor_principal: "#6638B6",
      cor_secundaria: "#201547",
      cor_terciaria: "#F3069F",
      cor_sappens: "#391547",
      cor_sappens_2: "#152347",
      cor_fundo: "#F6F6F6",
      sappens: "#391547",
      scroll: "#EDF2F6",

      cor_verde_secundaria_500: "#1FA22E",
      cor_verde_secundaria_400: "#3DB14B",
      cor_verde_secundaria_300: "#63C56E",
      cor_verde_secundaria_200: "#8CD294",
      cor_verde_secundaria_100: "#A4DAAA",

      cor_amarelo_500: "#FFDD00",
      cor_amarelo_400: "#FFE84C",
      cor_amarelo_300: "#FFEE76",
      cor_amarelo_200: "#FFF5AD",
      cor_amarelo_100: "#FFF9D2",

      cor_verde_escuro_secundaria_500: "#004F4B",
      cor_verde_escuro_secundaria_400: "#175855",
      cor_verde_escuro_secundaria_300: "#367572",
      cor_verde_escuro_secundaria_200: "#5C9693",
      cor_verde_escuro_secundaria_100: "#97C0BE",

      cor_amarelo_escuro_secundaria_500: "#F7931E",
      cor_amarelo_escuro_secundaria_400: "#FEAA48",
      cor_amarelo_escuro_secundaria_300: "#FCC380",
      cor_amarelo_escuro_secundaria_200: "#FFD6A7",
      cor_amarelo_escuro_secundaria_100: "#FFECD7",

      cor_preto_500: "#030C15",
      cor_preto_400: "#0D2237",
      cor_preto_300: "#28425B",
      cor_preto_200: "#727D99",
      cor_preto_100: "#929CB7",

      cor_branco_500: "#ACB3CD",
      cor_branco_400: "#C6CCDC",
      cor_branco_300: "#D5D9E2",
      cor_branco_200: "#F4F7FE",
      cor_branco_100: "#FFFFFF",

      cor_verde_500: "#BDB079",
      cor_verde_400: "#C9B665",
      cor_verde_300: "#C9B665",
      cor_verde_200: "#BDB079",
      cor_verde_100: "#A4DAAA",

      cor_alerta_500: "#FFDD00",
      cor_alerta_400: "#FFE84C",
      cor_alerta_300: "#FFEE76",
      cor_alerta_200: "#FFF5AD",
      cor_alerta_100: "#FFF9D2",

      cor_erro_500: "#B20000",
      cor_erro_400: "#CC0E0E",
      cor_erro_300: "#E01010",
      cor_erro_200: "#E95959",
      cor_erro_100: "#FEEAEB",
    },

    fonte: {
      bold: {
        normal: 400,
        medio: 500,
        semi_bold: 600,
        bold: 700,
      },
      font: {
        fonte_1: "poppins",
      },
    },
  },
};

export const getDataSvg = (data: any, site = true) => {
  if (site)
    return encodeURIComponent(
      renderToString(data).replace(
        "<svg ",
        `<svg xmlns='http://www.w3.org/2000/svg'  `
      )
    );
  else return encodeURIComponent(renderToString(data));
};

const sendIcon = (color: any) =>
  `url("data:image/svg+xml,${getDataSvg(
    <SendIcon style={{ fill: color }} />
    //<SendIcon />
  )}")`;

//<SiGooglegemini style={{ fill: cor_branco }} />
const gemini = (color: any) =>
  `url("data:image/svg+xml,${getDataSvg(
    <SiGooglegemini style={{ fill: color }} />,
    false
  )}")`;

//<CloseIcon style={{ fill: cor_branco }} />
const closeIcon = (color: any) => {
  return `url("data:image/svg+xml,${getDataSvg(
    <CloseIcon style={{ fill: color }} />
  )}")`;
};
// console.log(renderToString(<StyledSendIcon />));

export const ComponentGemini = styled.div`
  font-family: ${(props) => props.theme.fonte.font.fonte_1} !important;
  color: ${(props) => props.theme.cor.cor_preto_500};

  .rcw-close {
    content: ${(props: any) =>
      closeIcon(props.theme.cor.cor_branco_100)} !important;
    cursor: pointer;
  }
  .rcw-close-button {
    background: none;
  }

  .rcw-launcher {
    background-color: ${(props) => props.theme.cor.cor_principal_500};
  }

  .rcw-sender {
    background-color: ${(props) => props.theme.cor.cor_fundo} !important;
  }

  .rcw-send {
    background: ${(props) => props.theme.cor.cor_fundo} !important;
  }

  .rcw-picker-btn {
    display: none;
  }

  .rcw-messages-container {
    background: ${(props) => props.theme.cor.cor_branco_100};
  }

  .rcw-header {
    background-color: ${(props) => props.theme.cor.cor_principal_500};
  }

  .rcw-client .rcw-message-text {
    background-color: ${(props) => props.theme.cor.cor_principal_500};
    color: ${(props) => props.theme.cor.cor_branco_100};
  }

  .rcw-response .rcw-message-text {
    background-color: ${(props) => props.theme.cor.cor_fundo};
    color: ${(props) => props.theme.cor.cor_preto_200};
  }

  .rcw-widget-container.rcw-close-widget-container {
    z-index: 2 !important;
  }

  .rcw-widget-container {
    padding-top: 5rem !important;
    z-index: 2 !important;
  }

  @media only screen and (max-width: 881px), (max-height: 610px) {
    .rcw-widget-container.rcw-close-widget-container {
      z-index: 9999 !important;
    }
    .rcw-widget-container {
      z-index: 9999 !important;
    }
  }
  .rcw-widget-container {
    max-width: 370px !important;
    max-height: 100% !important;
  }
  .rcw-send-icon {
    content: ${(props: any) =>
      sendIcon(props.theme.cor.cor_branco_500)} !important;
    margin-bottom: 0.1rem;
  }
  .rcw-header {
    color: ${(props) => props.theme.cor.cor_branco_100};
  }
  .rcw-new-message {
    max-width: 100% !important;
    background: ${(props) => props.theme.cor.cor_branco_100};
    color: ${(props) => props.theme.cor.cor_preto_300};
  }
  .rcw-close-launcher {
    content: ${(props: any) =>
      closeIcon(props.theme.cor.cor_branco_100)} !important;
    width: 40px;
    margin-top: 5px;
  }
  .rcw-new-message div {
    max-width: 100% !important;
  }
  .rcw-input[placeholder]:empty:before {
    color: ${(props) => props.theme.cor.cor_preto_300};
  }
  .rcw-open-launcher {
    height: 45px;
    width: 45px;
    margin-top: 4px;
    content: ${(props: any) =>
      gemini(props.theme.cor.cor_branco_100)} !important;
  }

  .rcw-conversation-container {
    min-width: none !important;
  }
  .loader-container {
    background: ${(props) => props.theme.cor.cor_fundo} !important;
  }
  .loader-dots {
    background: ${(props) => props.theme.cor.cor_preto_100} !important;
  }
  .rcw-avatar {
    margin-top: 4px;
  }
`;

export const ListContainerStyle = styled.div`
  svg {
    color: ${(props) => props.theme.cor.cor_preto_100};
  }
  .ant-pagination-item {
    border-color: ${(props) => props.theme.cor.cor_principal_500};
    background-color: ${(props) => props.theme.cor.cor_branco_100};
  }
  a {
    color: ${(props) => props.theme.cor.cor_principal_500} !important;
  }
`;

const StyledRangePickerContainer = styled.div`
  background-color: ${(props) => props.theme.cor.cor_branco_100};
  font-family: ${(props) => props.theme.fonte.font.fonte_1} !important;
  th {
    color: ${(props) => props.theme.cor.cor_preto_300} !important;
    font-family: ${(props) => props.theme.fonte.font.fonte_1} !important;
  }
  .ant-picker-header button {
    color: ${(props) => props.theme.cor.cor_preto_300} !important;
    font-family: ${(props) => props.theme.fonte.font.fonte_1} !important;
  }
  .ant-picker-header-view:hover {
    color: ${(props) => props.theme.cor.cor_principal_500};
    font-family: ${(props) => props.theme.fonte.font.fonte_1} !important;
  }
  .ant-picker-header-view {
    color: ${(props) => props.theme.cor.cor_preto_300} !important;
    font-family: ${(props) => props.theme.fonte.font.fonte_1} !important;
  }
  .ant-picker-cell {
    color: ${(props) => props.theme.cor.cor_preto_300} !important;
    font-family: ${(props) => props.theme.fonte.font.fonte_1} !important;
  }
  .ant-picker-cell-in-view.ant-picker-cell-range-start {
    color: ${(props) => props.theme.cor.cor_preto_300} !important;
    font-family: ${(props) => props.theme.fonte.font.fonte_1} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner {
    background-color: ${(props) =>
      props.theme.cor.cor_principal_500} !important;
    color: ${(props) => props.theme.cor.cor_branco_100} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background-color: ${(props) =>
      props.theme.cor.cor_principal_500} !important;
    color: ${(props) => props.theme.cor.cor_branco_100} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-today
    .ant-picker-cell-inner::before {
    border-color: ${(props) => props.theme.cor.cor_principal_500} !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    )::before,
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    )::before,
  .ant-picker-time-panel-column
    > li.ant-picker-time-panel-cell-selected
    .ant-picker-time-panel-cell-inner {
    background: ${(props) => props.theme.cor.cor_principal_200} !important;
  }

  .ant-btn-primary {
    background-color: ${(props) =>
      props.theme.cor.cor_principal_500} !important;
    border-color: ${(props) => props.theme.cor.cor_principal_500} !important;
  }
`;
export const RangeDatePicker = (props: any) => {
  return (
    <DatePicker.RangePicker
      panelRender={(panelNode) => (
        <StyledRangePickerContainer>{panelNode}</StyledRangePickerContainer>
      )}
      {...props}
    />
  );
};

export const StyledDatePicker = styled.span`
  .ant-picker-outlined {
    border-color: ${(props) => props.theme.cor.cor_branco_300};
  }
  .ant-picker {
    background-color: ${(props) => props.theme.cor.cor_branco_100} !important;
    color: ${(props) => props.theme.cor.cor_preto_300};
    font-family: ${(props) => props.theme.fonte.font.fonte_1};
  }
  .ant-picker-active-bar {
    background-color: ${(props) =>
      props.theme.cor.cor_principal_500} !important;
  }
  svg {
    color: ${(props) => props.theme.cor.cor_preto_300};
  }
  path {
    color: ${(props) => props.theme.cor.cor_preto_300};
  }
  &:hover .ant-picker {
    border-color: ${(props) => props.theme.cor.cor_principal_500} !important;
  }
`;

export const StyledSearchContainer = styled.div`
  span {
    background-color: ${(props) => props.theme.cor.cor_branco_100} !important;
    color: ${(props) => props.theme.cor.cor_preto_300};
    font-family: ${(props) => props.theme.fonte.font.fonte_1};
    border-color: ${(props) => props.theme.cor.cor_branco_300};
  }
  .ant-input-disabled {
    background-color: ${(props) => props.theme.cor.cor_branco_100} !important;
    color: ${(props) => props.theme.cor.cor_preto_300};
    font-family: ${(props) => props.theme.fonte.font.fonte_1};
    border-color: ${(props) => props.theme.cor.cor_branco_300};
  }
  ::placeholder {
    color: ${(props) => props.theme.cor.cor_preto_100};
    font-family: ${(props) => props.theme.fonte.font.fonte_1};
  }
  &:hover .ant-input-affix-wrapper {
    border-color: ${(props) => props.theme.cor.cor_principal_500} !important;
  }
  padding-bottom: 1rem;
`;

export const StyledResultAntd = styled(Result)`
  path {
    color: ${(props) => props.theme.cor.cor_verde_secundaria_300} !important;
  }

  .ant-result-title {
    color: ${(props) => props.theme.cor.cor_preto_300};
    font-family: ${(props) => props.theme.fonte.font.fonte_1};
  }

  .ant-result-subtitle {
    color: ${(props) => props.theme.cor.cor_preto_300};
    font-family: ${(props) => props.theme.fonte.font.fonte_1};
  }
`;

export const Perguntas = styled.p`
  margin-top: 0;
  color: ${(props) => props.theme.cor.cor_preto_300};
  font-weight: ${(props) => props.theme.fonte.bold.semi_bold};
  font-size: 20px;
  font-family: ${(props) => props.theme.fonte.font.fonte_1};
  overflow-wrap: break-word;
  margin-bottom: 0;
`;

export const ConfiguracoesPerfil = styled.p`
  margin-top: 0;
  color: ${(props) => props.theme.cor.cor_preto_400};
  font-weight: ${(props) => props.theme.fonte.bold.semi_bold};
  font-size: 24px;
  font-family: ${(props) => props.theme.fonte.font.fonte_1};
  overflow-wrap: break-word;
  margin-bottom: 0.5rem;
`;

export const StyledDateCalendar = styled(DateCalendar)`
  span {
    color: ${(props) => props.theme.cor.cor_preto_100};
    font-family: ${(props) => props.theme.fonte.font.fonte_1};
    font-weight: ${(props) => props.theme.fonte.bold.bold};
    font-size: 18px;
  }

  button.Mui-selected {
    background: ${(props) => props.theme.cor.cor_principal_500} !important;
  }

  svg.MuiPickersCalendarHeader-switchViewIcon {
    fill: ${(props) => props.theme.cor.cor_principal_500} !important;
  }

  .MuiDayCalendar-weekDayLabel {
    cursor: default;
    user-select: none;
  }

  .MuiPickersSlideTransition-root {
    margin-bottom: -1rem;
  }

  & .MuiPickersCalendarHeader-root {
    flex-direction: row-reverse !important;
    position: relative;
  }

  & .MuiIconButton-edgeEnd {
    padding: 0px !important;
    left: 5px;
  }

  & .MuiIconButton-edgeStart {
    position: absolute;
    right: 30px;
    padding: 0px !important;
  }

  & .MuiPickersCalendarHeader-labelContainer {
    /* margin-left: auto !important; */
    margin-left: 35px;
    text-transform: capitalize;
  }

  .MuiTypography-root-MuiDayCalendar-weekDayLabel {
    color: ${(props) => props.theme.cor.cor_preto_100};
    font-family: ${(props) => props.theme.fonte.font.fonte_1};
    font-weight: ${(props) => props.theme.fonte.bold.bold};
  }

  .MuiSvgIcon-fontSizeInherit {
    background: ${(props) => props.theme.cor.cor_principal_500};
    border-radius: 0.5rem;
    color: ${(props) => props.theme.cor.cor_branco_100};
    margin-right: -1px;
  }

  .MuiPickersFadeTransitionGroup-root {
    color: ${(props) => props.theme.cor.cor_preto_200};
    font-family: ${(props) => props.theme.fonte.font.fonte_1};
    font-weight: ${(props) => props.theme.fonte.bold.bold};
  }
`;
export const AlterarSenha = styled.p`
  margin-top: 0;
  color: ${(props) => props.theme.cor.cor_preto_300};
  width: 100%;
  font-size: 20px;
  font-family: ${(props) => props.theme.fonte.font.fonte_1};
  overflow-wrap: break-word;
  text-align: "justify";
  margin-bottom: 0.5rem;
`;

export const ConfiguracoesPerfilTextoNotificacao = styled.p`
  margin-top: 0;
  margin-left: 3.5rem;
  color: ${(props) => props.theme.cor.cor_preto_200};
  font-size: 12px;
  font-family: ${(props) => props.theme.fonte.font.fonte_1};
  overflow-wrap: break-word;
  margin-bottom: 0.5rem;
`;

export const ConfiguracoesPerfilNome = styled.p`
  margin-top: 0;
  color: ${(props) => props.theme.cor.cor_preto_500};
  font-weight: ${(props) => props.theme.fonte.bold.semi_bold};
  font-size: 17.8px;
  font-family: ${(props) => props.theme.fonte.font.fonte_1};
  overflow-wrap: break-word;
  margin-bottom: 0;
`;

export const ConfiguracoesPerfilTexto = styled.p`
  margin-top: 0;
  color: ${(props) => props.theme.cor.cor_preto_500};
  font-weight: ${(props) => props.theme.fonte.bold.semi_bold};
  font-size: 14px;
  font-family: ${(props) => props.theme.fonte.font.fonte_1};
  overflow-wrap: break-word;
  margin-bottom: 0;
`;

export const ConfiguracoesPerfilNomeArroba = styled.p`
  margin-top: 0;
  color: ${(props) => props.theme.cor.cor_preto_500};
  font-weight: ${(props) => props.theme.fonte.bold.semi_bold};
  font-size: 12px;
  font-family: ${(props) => props.theme.fonte.font.fonte_1};
  overflow-wrap: break-word;
  text-align: "center";
`;

export const ConfiguracoesTextoPerfil = styled.p`
  margin: 0;
  color: ${(props) => props.theme.cor.cor_preto_300};
  font-size: 12px;
  font-family: ${(props) => props.theme.fonte.font.fonte_1};
  overflow-wrap: break-word;
`;

export const ConfiguracoesTextoSelecao = styled.p<{ $selecao?: boolean }>`
  margin: 0;
  color: ${(props) =>
    props.$selecao
      ? props.theme.cor.cor_principal_500
      : props.theme.cor.cor_preto_300};
  font-size: 18px;
  font-weight: ${(props) =>
    props.$selecao
      ? props.theme.fonte.bold.semi_bold
      : props.theme.fonte.bold.normal};
  font-family: ${(props) => props.theme.fonte.font.fonte_1};
  overflow-wrap: break-word;
  cursor: pointer;
`;

export const ConfiguracoesTexto = styled.p`
  margin-top: 0;
  color: ${(props) => props.theme.cor.cor_preto_300};
  font-size: 12px;
  font-family: ${(props) => props.theme.fonte.font.fonte_1};
  overflow-wrap: break-word;
`;

export const FaleTitulo = styled.p`
  color: ${(props) => props.theme.cor.cor_principal_500};
  font-weight: ${(props) => props.theme.fonte.bold.bold};
  font-size: 16px;
  font-family: ${(props) => props.theme.fonte.font.fonte_1};
  margin-top: 0;
  padding-top: 0;
  overflow-wrap: break-word;
`;

export const FaleTexto = styled.p`
  color: ${(props) => props.theme.cor.cor_preto_300};
  font-size: 14px;
  font-family: ${(props) => props.theme.fonte.font.fonte_1};
  text-align: "justify";
  overflow-wrap: break-word;
`;

export const EntreTexto = styled.p`
  color: ${(props) => props.theme.cor.cor_branco_100};
  font-size: 16px;
  font-family: ${(props) => props.theme.fonte.font.fonte_1};
  padding-right: 0.5rem;
  overflow-wrap: break-word;
`;

export const Guia = styled.p`
  color: ${(props) => props.theme.cor.cor_preto_400};
  font-size: 14px;
  font-family: ${(props) => props.theme.fonte.font.fonte_1};
  text-decoration: underline;
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
  overflow-wrap: break-word;
  padding-right: 1rem;
`;

export const FaleTextoContato = styled.p`
  color: ${(props) => props.theme.cor.cor_preto_300};
  font-size: 16px;
  font-family: ${(props) => props.theme.fonte.font.fonte_1};
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
`;

export const MenuSupeior = styled.p`
  color: ${(props) => props.theme.cor.cor_preto_300};
  font-size: 24px;
  font-family: ${(props) => props.theme.fonte.font.fonte_1};
  font-weight: ${(props) => props.theme.fonte.bold.semi_bold};
  margin-top: 0;
  padding-top: 0;
  padding-bottom: 0;
  margin-bottom: 0;
`;

export const mobile = (windowSize: any): any => {
  return windowSize?.height > 716 && windowSize?.width > 881;
};

export const StyledTable = styled(Table)`
  th:first-child {
    border-top-left-radius: 1rem;
  }

  th:last-child {
    border-top-right-radius: 1rem;
  }

  td:first-child {
    border-bottom-left-radius: 10px;
  }

  td:last-child {
    border-bottom-right-radius: 10px;
  }

  th {
    background-color: ${(props) => props.theme.cor.cor_branco_100};
  }
`;

export const StyledTableTurma = styled(Table)`
  th:first-child {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem;
  }

  th:last-child {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  td:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  td:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }
`;

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { Button, Grid, Link, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { useTheme } from "styled-components";
import { ReactComponent as FactCheckIcon } from "../../img/svg/aula.svg";

const SubMenuAulas = ({ setEscurecer, btnSelecao }: any) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setEscurecer(false);
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        fullWidth
        onClick={(e: any) => {
          handleClick(e);

          setEscurecer(true);
        }}
        variant={btnSelecao === 3 ? "contained" : "text"}
        style={{
          alignItems: "center",
          color:
            btnSelecao === 3
              ? theme.cor.cor_preto_300
              : theme.cor.cor_branco_100,
          background: btnSelecao === 3 ? theme.cor.cor_branco_100 : "none",
          textTransform: "capitalize",
          fontFamily: theme.fonte.font.fonte_1,
          marginTop: ".2rem",
          marginBottom: ".2rem",
        }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          style={{
            maxHeight: "22.75px",
          }}
        >
          <Grid item style={{ marginTop: "-.2rem" }}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item>
                <FactCheckIcon
                  style={{
                    marginRight: 10,
                    marginLeft: -4,
                    fill:
                      btnSelecao === 3
                        ? theme.cor.cor_preto_300
                        : theme.cor.cor_branco_100,
                  }}
                />
              </Grid>
              <Grid item style={{ marginTop: "-.3rem" }}>
                Aulas
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <NavigateNextIcon />
          </Grid>
        </Grid>
      </Button>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        style={{
          color:
            btnSelecao === 4
              ? theme.cor.cor_preto_300
              : theme.cor.cor_branco_100,

          textTransform: "capitalize",
          fontFamily: theme.fonte.font.fonte_1,
          left: ".5rem",
        }}
        sx={{
          ".MuiPaper-root": { background: theme.cor.cor_branco_100 },
        }}
      >
        <Link
          href="/aulas"
          style={{
            textDecoration: "none",
            color: theme.cor.cor_preto_300,
          }}
        >
          <MenuItem
            sx={{
              color: theme.cor.cor_preto_300,
              fontFamily: theme.fonte.font.fonte_1,
              "&:hover": {
                background: theme.cor.cor_principal_300,
                color: theme.cor.cor_branco_100,
              },
              "&.Mui-selected": {
                background: theme.cor.cor_principal_200,
                color: theme.cor.cor_branco_100,
              },
            }}
          >
            <FactCheckIcon
              style={{
                marginRight: ".5rem",
                height: "1.4rem",
              }}
            />
            Aulas
          </MenuItem>
        </Link>
        <Link
          href="/aulas/turma"
          style={{
            textDecoration: "none",
            color: theme.cor.cor_preto_300,
          }}
        >
          <MenuItem
            sx={{
              color: theme.cor.cor_preto_300,
              fontFamily: theme.fonte.font.fonte_1,
              "&:hover": {
                background: theme.cor.cor_principal_300,
                color: theme.cor.cor_branco_100,
              },
              "&.Mui-selected": {
                background: theme.cor.cor_principal_200,
                color: theme.cor.cor_branco_100,
              },
            }}
          >
            <AddCircleOutlineIcon
              style={{
                marginRight: ".5rem",
                height: "1.4rem",
              }}
            />
            Adicionar Conteúdo
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
};

React.memo(SubMenuAulas);

export default SubMenuAulas;

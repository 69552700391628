import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import { Box, Button, FormControl, Grid, Paper, Popover } from "@mui/material";
import { ReactComponent as SvgLocalizacao } from "../../img/svg/locaizacao.svg";
import { ReactComponent as SvgEscola } from "../../img/svg/escola.svg";
import { ReactComponent as SvgPessoa } from "../../img/svg/pessoa.svg";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Popper } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
import api from "../axios/axios";

const POP_UP_DELETAR = ({
  setOpen,
  open,
  theme,
  anchorEl,
  setTurmaSelecao,
  turmaSelecao,
  cidadeSelecao,
  escolaSelecao,
  setEscolaSelecao,
  setCidadeSelecao,
  pessoas,
  setPessoas,
  check,
  setCheck,
}: any) => {
  return (
    <Popover
      disablePortal={false}
      open={open}
      anchorEl={anchorEl}
      sx={{ marginTop: "5rem", zIndex: 1 }}
      slotProps={{
        paper: {
          sx: {
            overflow: "visible",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 35,
              width: 10,
              height: 10,
              backgroundColor: "inherit",
              transform: "translateY(-50%) rotate(45deg)",
              boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)",
            },
          },
        },
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        maxWidth={"300px"}
        spacing={2}
        padding={3}
      >
        <Grid item xs={12}>
          <p></p>
        </Grid>

        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12}>
              <Button
                variant="outlined"
                style={{
                  color: theme.cor.cor_principal_500,
                  borderColor: theme.cor.cor_principal_500,
                  width: "100%",
                }}
                onClick={() => {
                  setOpen(false);
                }}
              >
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="outlined"
                style={{
                  color: theme.cor.cor_erro_500,
                  borderColor: theme.cor.cor_erro_500,
                  width: "100%",
                }}
                onClick={() => {
                  const remover = {
                    de: {
                      escola: escolaSelecao,
                      cidade: cidadeSelecao,
                      turma: turmaSelecao,
                    },
                    pessoas: Array.from(check),
                  };

                  api
                    .post("turma/pessoas/remover", remover)
                    .then((r) => {
                      setPessoas((antes: any) => {
                        return {
                          ...antes,
                          total: antes.total - 1,
                          items: antes?.items?.filter(
                            (p: any) => !check.has(p.id_keycloak)
                          ),
                        };
                      });
                      setCheck(new Set());
                    })
                    .finally(() => {
                      setOpen(false);
                    });
                }}
              >
                Deletar estudantes
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default POP_UP_DELETAR;

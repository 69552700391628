import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import { Box, Button, FormControl, Grid, Paper, Popover } from "@mui/material";
import { ReactComponent as SvgLocalizacao } from "../../img/svg/locaizacao.svg";
import { ReactComponent as SvgEscola } from "../../img/svg/escola.svg";
import { ReactComponent as SvgPessoa } from "../../img/svg/pessoa.svg";
import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Popper } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import CircleTwoToneIcon from "@mui/icons-material/CircleTwoTone";
import api from "../axios/axios";

const POP_UP = ({
  setOpen,
  open,
  theme,
  anchorEl,
  setTurmaSelecaoFiltro,
  turmaSelecaoFiltro,
  cidadeSelecaoFiltro,
  escolaSelecaoFiltro,
  setEscolaSelecaoFiltro,
  setCidadeSelecaoFiltro,
  pessoas,
  setPessoas,
  setFiltrar,
  mover = false,
  check,
  setCheck,
}: any) => {
  const [cidade, setCidade] = useState<any>({ total: 0, items: [] });
  const [cidadeSelecao, setCidadeSelecao] = useState<any>({ id: -1 });
  const [escola, setEscola] = useState<any>({ total: 0, items: [] });
  const [escolaSelecao, setEscolaSelecao] = useState<any>({ id: -1 });
  const [turma, setTurma] = useState<any>({ total: 0, items: [] });
  const [turmaSelecao, setTurmaSelecao] = useState<any>({ id: -1 });

  useEffect(() => {
    api.get("cidade").then((r) => {
      setCidade(r.data);
      if (r?.data?.items?.length > 0) setCidadeSelecao(r.data.items[0]);
    });
  }, []);

  useEffect(() => {
    if (cidadeSelecao.id !== -1) {
      setTurma({ total: 0, items: [] });
      setEscola({ total: 0, items: [] });
      setEscolaSelecao({ id: -1 });
      setTurmaSelecao({ id: -1 });
      setPessoas({ total: 0, items: [] });
      api.get("cidade/escola/" + cidadeSelecao.id).then((r) => {
        setEscola(r.data);
        if (r?.data?.items?.length > 0) setEscolaSelecao(r.data.items[0]);
      });
    }
  }, [cidadeSelecao]);

  useEffect(() => {
    if (escolaSelecao.id !== -1) {
      setPessoas({ total: 0, items: [] });

      setTurma({ total: 0, items: [] });
      setTurmaSelecao({ id: -1 });

      api.get("escola/turma/" + escolaSelecao?.id).then((r) => {
        setTurma(r.data);
        if (r?.data?.items?.length > 0) setTurmaSelecao(r.data.items[0]);
      });
    }
  }, [escolaSelecao]);

  return (
    <Popover
      disablePortal={false}
      open={open}
      anchorEl={anchorEl}
      sx={{ marginTop: mover ? "5rem" : "1.8rem", zIndex: 1 }}
      slotProps={{
        paper: {
          sx: {
            overflow: "visible",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              left: mover ? "none" : 10,
              right: mover ? 105 : "none",
              width: 10,
              height: 10,
              backgroundColor: "inherit",
              transform: "translateY(-50%) rotate(45deg)",
              boxShadow: "-3px -3px 5px -2px rgba(0,0,0,0.1)",
            },
          },
        },
      }}
    >
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        maxWidth={"300px"}
        spacing={2}
        padding={3}
      >
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="flex-start"
            style={{ marginTop: "-.5rem" }}
          >
            <Grid item>
              <CloseIcon
                style={{ fill: theme.cor.cor_preto_300, cursor: "pointer" }}
                onClick={() => {
                  setOpen(false);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ marginTop: "-1rem" }}>
          <span
            style={{
              fontWeight: theme.fonte.bold.bold,
              fontFamily: theme.fonte.font.fonte_1,
              color: theme.cor.cor_preto_300,
              padding: 0,
              margin: 0,
            }}
          >
            Cidade
          </span>
          <FormControl sx={{ maxWidth: "100%" }} fullWidth size="small">
            <Select
              id="cidade"
              startDecorator={<SvgLocalizacao />}
              indicator={<KeyboardArrowDown />}
              style={{
                color: theme.cor.cor_preto_300,
                height: "33px",
                fontFamily: theme.fonte.font.fonte_1,
              }}
              value={cidadeSelecao.id}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.cor.cor_preto_100,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.cor.cor_principal_500,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.cor.cor_preto_100,
                },

                color: theme.cor.cor_preto_300,
                fontFamily: theme.fonte.font.fonte_1,
              }}
            >
              {cidade?.items?.length > 0 ? (
                cidade?.items?.map((c: any) => (
                  <Option
                    onClick={() => setCidadeSelecao(c)}
                    sx={{
                      color: theme.cor.cor_preto_300,
                      fontFamily: theme.fonte.font.fonte_1,
                      "&:hover": {
                        background: theme.cor.cor_principal_300,
                        color: theme.cor.cor_branco_100,
                      },
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      "&.Mui-selected": {
                        background: theme.cor.cor_principal_200,
                        color: theme.cor.cor_branco_100,
                      },
                    }}
                    value={c.id}
                  >
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleOutlineIcon />}
                      checked={cidadeSelecao?.id === c?.id}
                      style={{ color: theme.cor.cor_principal_500 }}
                    />
                    {c.nome}
                  </Option>
                ))
              ) : (
                <Option
                  sx={{
                    color: theme.cor.cor_preto_300,
                    fontFamily: theme.fonte.font.fonte_1,
                    "&:hover": {
                      background: theme.cor.cor_principal_300,
                      color: theme.cor.cor_branco_100,
                    },
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    "&.Mui-selected": {
                      background: theme.cor.cor_principal_200,
                      color: theme.cor.cor_branco_100,
                    },
                  }}
                  value={-1}
                >
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleOutlineIcon />}
                    checked={true}
                    style={{ color: theme.cor.cor_principal_500 }}
                  />
                  Nenhuma cidade
                </Option>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <span
            style={{
              fontWeight: theme.fonte.bold.bold,
              fontFamily: theme.fonte.font.fonte_1,
              color: theme.cor.cor_preto_300,
              padding: 0,
              margin: 0,
            }}
          >
            Escola
          </span>
          <FormControl sx={{ maxWidth: "100%" }} fullWidth size="small">
            <Select
              id="escola"
              startDecorator={<SvgEscola />}
              indicator={<KeyboardArrowDown />}
              style={{
                color: theme.cor.cor_preto_300,
                height: "33px",
                fontFamily: theme.fonte.font.fonte_1,
              }}
              value={escolaSelecao.id}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.cor.cor_preto_100,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.cor.cor_principal_500,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.cor.cor_preto_100,
                },

                color: theme.cor.cor_preto_300,
                fontFamily: theme.fonte.font.fonte_1,
              }}
            >
              {escola?.items?.length > 0 ? (
                escola?.items?.map((c: any) => (
                  <Option
                    onClick={() => setEscolaSelecao(c)}
                    sx={{
                      color: theme.cor.cor_preto_300,
                      fontFamily: theme.fonte.font.fonte_1,
                      "&:hover": {
                        background: theme.cor.cor_principal_300,
                        color: theme.cor.cor_branco_100,
                      },
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      "&.Mui-selected": {
                        background: theme.cor.cor_principal_200,
                        color: theme.cor.cor_branco_100,
                      },
                    }}
                    value={c.id}
                  >
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleOutlineIcon />}
                      checked={escolaSelecao?.id === c?.id}
                      style={{ color: theme.cor.cor_principal_500 }}
                    />
                    {c.nome}
                  </Option>
                ))
              ) : (
                <Option
                  sx={{
                    color: theme.cor.cor_preto_300,
                    fontFamily: theme.fonte.font.fonte_1,
                    "&:hover": {
                      background: theme.cor.cor_principal_300,
                      color: theme.cor.cor_branco_100,
                    },
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    "&.Mui-selected": {
                      background: theme.cor.cor_principal_200,
                      color: theme.cor.cor_branco_100,
                    },
                  }}
                  value={-1}
                >
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleOutlineIcon />}
                    checked={true}
                    style={{ color: theme.cor.cor_principal_500 }}
                  />
                  Nenhuma escola
                </Option>
              )}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <span
            style={{
              fontWeight: theme.fonte.bold.bold,
              fontFamily: theme.fonte.font.fonte_1,
              color: theme.cor.cor_preto_300,
              padding: 0,
              margin: 0,
            }}
          >
            Turma
          </span>
          <FormControl sx={{ maxWidth: "100%" }} fullWidth size="small">
            <Select
              id="turma"
              startDecorator={<SvgPessoa />}
              indicator={<KeyboardArrowDown />}
              style={{
                color: theme.cor.cor_preto_300,
                height: "33px",
                fontFamily: theme.fonte.font.fonte_1,
              }}
              value={turmaSelecao.id}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.cor.cor_preto_100,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.cor.cor_principal_500,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.cor.cor_preto_100,
                },

                color: theme.cor.cor_preto_300,
                fontFamily: theme.fonte.font.fonte_1,
              }}
            >
              {turma?.items?.length > 0 ? (
                turma?.items?.map((c: any) => (
                  <Option
                    onClick={() => setTurmaSelecao(c)}
                    sx={{
                      color: theme.cor.cor_preto_300,
                      fontFamily: theme.fonte.font.fonte_1,
                      "&:hover": {
                        background: theme.cor.cor_principal_300,
                        color: theme.cor.cor_branco_100,
                      },
                      whiteSpace: "normal",
                      wordWrap: "break-word",
                      "&.Mui-selected": {
                        background: theme.cor.cor_principal_200,
                        color: theme.cor.cor_branco_100,
                      },
                    }}
                    value={c.id}
                  >
                    <Checkbox
                      icon={<RadioButtonUncheckedIcon />}
                      checkedIcon={<CheckCircleOutlineIcon />}
                      checked={turmaSelecao?.id === c?.id}
                      style={{ color: theme.cor.cor_principal_500 }}
                    />
                    {c.nome}
                  </Option>
                ))
              ) : (
                <Option
                  sx={{
                    color: theme.cor.cor_preto_300,
                    fontFamily: theme.fonte.font.fonte_1,
                    "&:hover": {
                      background: theme.cor.cor_principal_300,
                      color: theme.cor.cor_branco_100,
                    },
                    whiteSpace: "normal",
                    wordWrap: "break-word",
                    "&.Mui-selected": {
                      background: theme.cor.cor_principal_200,
                      color: theme.cor.cor_branco_100,
                    },
                  }}
                  value={-1}
                >
                  <Checkbox
                    icon={<RadioButtonUncheckedIcon />}
                    checkedIcon={<CheckCircleOutlineIcon />}
                    checked={true}
                    style={{ color: theme.cor.cor_principal_500 }}
                  />
                  Nenhuma turma
                </Option>
              )}
            </Select>
          </FormControl>
        </Grid>
        {/* <Grid item xs={12}>
          <span
            style={{
              fontWeight: theme.fonte.bold.bold,
              fontFamily: theme.fonte.font.fonte_1,
              color: theme.cor.cor_preto_300,
              padding: 0,
              margin: 0,
            }}
          >
            Disciplina
          </span>
          <FormControl sx={{ maxWidth: "100%" }} fullWidth size="small">
            <Select
              id="turma"
              startDecorator={<SvgPessoa />}
              indicator={<KeyboardArrowDown />}
              style={{
                color: theme.cor.cor_preto_300,
                height: "33px",
                fontFamily: theme.fonte.font.fonte_1,
              }}
              sx={{
                ".MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.cor.cor_preto_100,
                },
                "&:hover .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.cor.cor_principal_500,
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.cor.cor_preto_100,
                },

                color: theme.cor.cor_preto_300,
                fontFamily: theme.fonte.font.fonte_1,
              }}
            >
              <Option
                sx={{
                  color: theme.cor.cor_preto_300,
                  fontFamily: theme.fonte.font.fonte_1,
                  "&:hover": {
                    background: theme.cor.cor_principal_300,
                    color: theme.cor.cor_branco_100,
                  },
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                  "&.Mui-selected": {
                    background: theme.cor.cor_principal_200,
                    color: theme.cor.cor_branco_100,
                  },
                }}
                key={-1}
                value={-1}
              >
                Nenhuma disciplina
              </Option>
            </Select>
          </FormControl>
        </Grid> */}

        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid item style={{ cursor: "pointer" }}>
              <Button
                variant="contained"
                style={{ background: theme.cor.cor_principal_500 }}
                onClick={() => {
                  if (mover) {
                    const mover = {
                      de: {
                        escola: escolaSelecaoFiltro,
                        cidade: cidadeSelecaoFiltro,
                        turma: turmaSelecaoFiltro,
                      },
                      para: {
                        escola: escolaSelecao,
                        cidade: cidadeSelecao,
                        turma: turmaSelecao,
                      },
                      pessoas: Array.from(check),
                    };
                    api
                      .post("turma/pessoas/mover", mover)
                      .then((r) => {
                        setPessoas((antes: any) => {
                          return {
                            ...antes,
                            total: antes.total - 1,
                            items: antes?.items?.filter(
                              (p: any) => !check.has(p.id_keycloak)
                            ),
                          };
                        });
                        setCheck(new Set());
                      })
                      .finally(() => {
                        setFiltrar(true);
                        setOpen(false);
                      });
                  } else {
                    api
                      .get("turma/pessoas/" + turmaSelecao?.id)
                      .then((r) => {
                        setPessoas(r.data);
                        setCidadeSelecaoFiltro(cidadeSelecao);
                        setTurmaSelecaoFiltro(turmaSelecao);
                        setEscolaSelecaoFiltro(escolaSelecao);
                      })
                      .finally(() => {
                        setFiltrar(true);
                        setOpen(false);
                      });
                  }
                }}
              >
                {mover ? "mover estudantes" : "Filtrar"}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Popover>
  );
};

export default POP_UP;

import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import {
  Avatar,
  Box,
  Checkbox,
  FormControl,
  Grid,
  InputAdornment,
  Pagination,
  PaginationItem,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { CiFilter } from "react-icons/ci";
import { useTheme } from "styled-components";
import IMG_HUGO from "../../img/hugo/hugo.png";
import CardMenu, { MenuPhone } from "../menu";
import MenuSuperior from "../menuSuperior";
import { mobile, StyledTable } from "../theme";
import POP_UP from "./pop-up";
import api from "../axios/axios";
import { ReactComponent as SvgMover } from "../../img/svg/mover.svg";
import { ReactComponent as SvgExcluir } from "../../img/svg/excluir.svg";
import { ReactComponent as SvgTelefone } from "../../img/svg/telefone.svg";
import { ReactComponent as SvgEmail } from "../../img/svg/email.svg";
import { ReactComponent as SvgVoltar } from "../../img/svg/voltar.svg";
import { ReactComponent as SvgAvancar } from "../../img/svg/avantar.svg";
import LongMenu from "./menu";
import POP_UP_DELETAR from "./pop-up_deletar";

const Turma = ({ decodedToken }: any) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [shrink, setShrink] = useState(false);
  const [cidade, setCidade] = useState<any>({
    total: 0,
    items: [],
  });
  const [cidadeSelecao, setCidadeSelecao] = useState<any>({ id: -1 });

  const [escolaSelecao, setEscolaSelecao] = useState<any>({ id: -1 });

  const [turmaSelecao, setTurmaSelecao] = useState<any>({ id: -1 });
  const [filtrar, setFiltrar] = useState<any>(false);
  const [check, setCheck] = useState<any>(new Set());
  const [checked, setChecked] = useState<boolean>(false);
  const [mover, setMover] = useState<boolean>(false);
  const [editar, setEditar] = useState<boolean>(false);
  const SvgVoltarFill = (props: any) => (
    <SvgVoltar {...props} style={{ fill: theme.cor.cor_cinza_300 }} />
  );
  const SvgAvancarFill = (props: any) => (
    <SvgAvancar {...props} style={{ fill: theme.cor.cor_cinza_300 }} />
  );
  const [pessoas, setPessoas] = useState<any>({
    total: 0,
    items: [],
  });
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePage = (page: any) => {
    api
      .get("turma/pessoas/" + turmaSelecao?.id + "?&page=" + page)
      .then((r) => {
        setPessoas(r.data);
        // if (checked) {
        //   for (const p in r?.data) {
        //     check.add(p?.id_keycloak);
        //   }
        // }
      });

    return;
  };

  return (
    <>
      <Grid
        container
        direction="row"
        style={{ background: theme.cor.cor_fundo }}
      >
        <CardMenu
          decodedToken={decodedToken}
          menuSelecao={14}
          ativo={mobile(windowSize)}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto" }}
          width={"100%"}
        >
          <MenuPhone
            ativo={mobile(windowSize) ? true : false}
            decodedToken={decodedToken}
          />
          <Grid
            item
            style={{
              background: theme.cor.cor_branco_100,
              padding: 0,
              margin: 0,
            }}
          >
            <MenuSuperior ativo={mobile(windowSize)} texto={"Turmas"} />
          </Grid>
          <Grid
            item
            padding={3}
            marginTop={mobile(windowSize) ? "" : "4rem"}
            paddingRight={"1em"}
          >
            <Grid
              item
              xs={12}
              style={{
                borderRadius: "1rem",
                background: theme.cor.cor_branco_100,
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
              }}
              mt="1rem"
            >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                padding={2}
                spacing={1}
                pl="2rem"
                pr="1.5rem"
                pt=".5rem"
              >
                <Grid
                  item
                  style={{
                    fontWeight: theme.fonte.bold.medio,
                    fontSize: "20px",
                    fontFamily: theme.fonte.font.fonte_1,
                    color: theme.cor.cor_preto_300,
                    padding: 0,
                    margin: 0,
                  }}
                >
                  Filtrar
                </Grid>
                <Grid item>
                  <CiFilter
                    style={{
                      fill: theme.cor.cor_preto_300,
                      height: "24px",
                      width: "24px",
                      cursor: "pointer",
                    }}
                    onClick={(event: any) => {
                      setOpen(true);
                      setAnchorEl(event.currentTarget);
                      setMover(false);
                    }}
                  />
                  <POP_UP
                    setOpen={setOpen}
                    open={open}
                    theme={theme}
                    check={check}
                    setCheck={setCheck}
                    anchorEl={anchorEl}
                    setCidadeSelecaoFiltro={setCidadeSelecao}
                    setEscolaSelecaoFiltro={setEscolaSelecao}
                    setTurmaSelecaoFiltro={setTurmaSelecao}
                    turmaSelecaoFiltro={turmaSelecao}
                    cidadeSelecaoFiltro={cidadeSelecao}
                    escolaSelecaoFiltro={escolaSelecao}
                    setPessoas={setPessoas}
                    pessoas={pessoas}
                    setFiltrar={setFiltrar}
                    mover={mover}
                  />
                  <POP_UP_DELETAR
                    setOpen={setOpenDelete}
                    open={openDelete}
                    theme={theme}
                    check={check}
                    setCheck={setCheck}
                    anchorEl={anchorEl}
                    setCidadeSelecao={setCidadeSelecao}
                    setEscolaSelecao={setEscolaSelecao}
                    setTurmaSelecao={setTurmaSelecao}
                    turmaSelecao={turmaSelecao}
                    cidadeSelecao={cidadeSelecao}
                    escolaSelecao={escolaSelecao}
                    setPessoas={setPessoas}
                    pessoas={pessoas}
                    setFiltrar={setFiltrar}
                    mover={mover}
                  />
                </Grid>
                <Grid item xs={true}>
                  <FormControl fullWidth>
                    <TextField
                      onFocus={() => setShrink(true)}
                      onBlur={(e) => setShrink(!!e.target.value)}
                      InputLabelProps={{ sx: { ml: 4.5 }, shrink }}
                      sx={(t) => ({
                        "& .Mui-focused .MuiInputAdornment-root": {
                          color: t.palette.primary.main,
                          border: "transparent",
                        },
                        "& .Mui-error .MuiInputAdornment-root": {
                          color: t.palette.error.main,
                          border: "transparent",
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          px: 5.5,
                          border: 0,
                        },
                        backgroundColor: theme.cor.cor_fundo_500,
                        borderRadius: "1rem",
                        "& label.Mui-focused": {
                          color: "transparent",
                        },
                        "& .MuiOutlinedInput-root": {
                          "&.Mui-focused fieldset": {
                            borderColor: "transparent",
                          },
                        },
                        background: theme.cor.cor_branco_200,
                        "& .MuiInputAdornment-root": {
                          color: theme.cor.cor_preto_300,
                          "& svg": {
                            color: theme.cor.cor_preto_300,
                          },
                        },
                      })}
                      name="buscar"
                      size="small"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      fullWidth
                    />
                  </FormControl>
                </Grid>
                {filtrar ? (
                  <Grid item ml=".5rem">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "100%",
                        width: "40px",
                        height: "40px",
                        background: theme.cor.cor_principal_500,
                        cursor: "pointer",
                        "&:hover": {
                          opacity: 0.5,
                        },
                        boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                      }}
                      onClick={() => setEditar(!editar)}
                    >
                      <EditIcon style={{ fill: theme.cor.cor_branco_100 }} />
                    </Box>
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
            {filtrar === true ? (
              <Grid item padding={"1rem"}>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="flex-start"
                      spacing={2}
                    >
                      <Grid item>
                        <span
                          style={{
                            fontWeight: theme.fonte.bold.bold,

                            fontFamily: theme.fonte.font.normal,
                            color: theme.cor.cor_preto_300,
                          }}
                        >
                          Filtros:
                        </span>
                      </Grid>
                      <Grid item ml={"-.5rem"}>
                        <span
                          style={{
                            fontFamily: theme.fonte.font.normal,
                            color: theme.cor.cor_preto_200,
                          }}
                        >
                          {cidadeSelecao?.id !== -1 ? cidadeSelecao?.nome : ""}
                        </span>
                      </Grid>
                      <Grid item>
                        <span
                          style={{
                            fontFamily: theme.fonte.font.normal,
                            color: theme.cor.cor_preto_200,
                          }}
                        >
                          {escolaSelecao?.id !== -1 ? escolaSelecao?.nome : ""}
                        </span>
                      </Grid>
                      <Grid item>
                        <span
                          style={{
                            fontFamily: theme.fonte.font.normal,
                            color: theme.cor.cor_preto_200,
                          }}
                        >
                          {turmaSelecao?.id !== -1 ? turmaSelecao?.nome : ""}
                        </span>
                      </Grid>
                    </Grid>
                  </Grid>
                  {editar ? (
                    <Grid item>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="flex-start"
                        spacing={2}
                      >
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={1}
                            style={{ cursor: "pointer" }}
                            onClick={(event: any) => {
                              setOpen(true);
                              setAnchorEl(event.currentTarget);
                              setMover(true);
                            }}
                          >
                            <Grid item>
                              <span
                                style={{
                                  fontWeight: theme.fonte.bold.bold,

                                  fontFamily: theme.fonte.font.normal,
                                  color: theme.cor.cor_preto_300,
                                }}
                              >
                                Mover
                              </span>
                            </Grid>
                            <Grid item>
                              <SvgMover
                                style={{ stroke: theme.cor.cor_preto_300 }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item>
                          <Grid
                            container
                            direction="column"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={1}
                            style={{ cursor: "pointer" }}
                            onClick={(event: any) => {
                              setOpenDelete(true);
                              setAnchorEl(event.currentTarget);
                            }}
                          >
                            <Grid item>
                              <span
                                style={{
                                  fontWeight: theme.fonte.bold.bold,

                                  fontFamily: theme.fonte.font.normal,
                                  color: theme.cor.cor_preto_300,
                                }}
                              >
                                Excluir
                              </span>
                            </Grid>
                            <Grid item>
                              <SvgExcluir
                                style={{ stroke: theme.cor.cor_preto_300 }}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            ) : (
              ""
            )}
            {pessoas?.items?.length === 0 ? (
              <Grid item xs={12} mt={"8rem"}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Grid item xs={4}>
                    <p
                      style={{
                        fontWeight: theme.fonte.bold.medio,
                        fontSize: "20px",
                        fontFamily: theme.fonte.font.fonte_1,
                        color: theme.cor.cor_preto_400,
                        padding: 0,
                        margin: 0,
                        textAlign: "center",
                      }}
                    >
                      Nada aqui por enquanto, tente selecionar um filtro
                    </p>
                  </Grid>
                  <Grid item xs={12}></Grid>
                  <Grid item>
                    <img src={IMG_HUGO} style={{ maxHeight: "300px" }} />
                  </Grid>
                </Grid>
              </Grid>
            ) : (
              <Grid item xs={12}>
                <StyledTable>
                  <TableHead
                    style={{
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                    }}
                  >
                    <TableRow>
                      {editar ? (
                        <TableCell align="left">
                          <Checkbox
                            checked={checked}
                            style={{ color: theme.cor.cor_principal_500 }}
                            onClick={() => {
                              setChecked(!checked);
                              setCheck((prevSet: any) => {
                                const newSet = new Set(prevSet);

                                for (const p of pessoas?.items || []) {
                                  if (checked) {
                                    newSet.delete(p.id_keycloak);
                                  } else {
                                    newSet.add(p.id_keycloak);
                                  }
                                }

                                return newSet;
                              });
                            }}
                          />
                        </TableCell>
                      ) : (
                        ""
                      )}
                      <TableCell align="left">
                        <p
                          style={{
                            fontWeight: theme.fonte.bold.bold,
                            fontSize: "14px",
                            fontFamily: theme.fonte.font.normal,
                            color: theme.cor.cor_principal_500,
                            padding: 0,
                            margin: 0,
                            paddingLeft: "1rem",
                          }}
                        >
                          Nome
                        </p>
                      </TableCell>

                      <TableCell align="left">
                        <p
                          style={{
                            fontWeight: theme.fonte.bold.bold,
                            fontSize: "14px",
                            fontFamily: theme.fonte.font.normal,
                            color: theme.cor.cor_principal_500,
                            padding: 0,
                            margin: 0,
                            paddingLeft: "1rem",
                          }}
                        >
                          Tipo
                        </p>
                      </TableCell>

                      <TableCell align="left">
                        <p
                          style={{
                            fontWeight: theme.fonte.bold.bold,
                            fontSize: "14px",
                            fontFamily: theme.fonte.font.normal,
                            color: theme.cor.cor_principal_500,
                            padding: 0,
                            margin: 0,
                            paddingLeft: "1rem",
                          }}
                        >
                          Matrícula
                        </p>
                      </TableCell>

                      <TableCell align="left">
                        <p
                          style={{
                            fontWeight: theme.fonte.bold.bold,
                            fontSize: "14px",
                            fontFamily: theme.fonte.font.normal,
                            color: theme.cor.cor_principal_500,
                            padding: 0,
                            margin: 0,
                            paddingLeft: "1rem",
                          }}
                        >
                          Contato
                        </p>
                      </TableCell>
                      <TableCell align="left">
                        <p
                          style={{
                            fontWeight: theme.fonte.bold.bold,
                            fontSize: "14px",
                            fontFamily: theme.fonte.font.normal,
                            color: theme.cor.cor_principal_500,
                            padding: 0,
                            margin: 0,
                            paddingLeft: "1rem",
                          }}
                        >
                          Ações
                        </p>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody
                    style={{
                      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                      borderRadius: "1rem",
                    }}
                  >
                    {pessoas?.items?.length > 0
                      ? pessoas?.items?.map((p: any, i: number) => (
                          <TableRow
                            key={p.id}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                              background: check.has(p.id_keycloak)
                                ? theme.cor.cor_branco_200
                                : theme.cor.cor_branco_100,
                            }}
                          >
                            {editar ? (
                              <TableCell
                                align="left"
                                style={{
                                  position: "relative",
                                  paddingLeft: "15px",
                                  borderBottom:
                                    pessoas?.items?.length - 1 !== i
                                      ? "1px solid" + theme.cor.cor_branco_500
                                      : "",
                                }}
                              >
                                {check.has(p.id_keycloak) ? (
                                  <span
                                    style={{
                                      content: '""',
                                      position: "absolute",
                                      left: 0,
                                      top: 0,
                                      bottom: 0,
                                      width: "5px",
                                      backgroundColor:
                                        theme.cor.cor_principal_500,
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                                <Checkbox
                                  checked={check.has(p.id_keycloak)}
                                  onClick={() => {
                                    setCheck((prevSet: any) => {
                                      const newSet = new Set(prevSet);

                                      if (newSet.has(p.id_keycloak)) {
                                        newSet.delete(p.id_keycloak);
                                      } else {
                                        newSet.add(p.id_keycloak);
                                      }

                                      return newSet;
                                    });
                                  }}
                                  style={{ color: theme.cor.cor_principal_500 }}
                                />
                              </TableCell>
                            ) : (
                              ""
                            )}
                            <TableCell
                              align="left"
                              style={{
                                borderBottom:
                                  pessoas?.items?.length - 1 !== i
                                    ? "1px solid" + theme.cor.cor_branco_500
                                    : "",
                              }}
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    overflow: "hidden",
                                  }}
                                >
                                  <div>
                                    <Avatar src={p?.foto} />
                                  </div>
                                  <div>
                                    <p
                                      style={{
                                        fontWeight: theme.fonte.bold.medio,
                                        fontSize: "14px",
                                        fontFamily: theme.fonte.font.normal,
                                        color: theme.cor.cor_preto_300,
                                        padding: 0,
                                        margin: 0,
                                        paddingLeft: "1rem",
                                      }}
                                    >
                                      {p.nome}
                                    </p>
                                  </div>
                                </div>
                              </Grid>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                borderBottom:
                                  pessoas?.items?.length - 1 !== i
                                    ? "1px solid" + theme.cor.cor_branco_500
                                    : "",
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: theme.fonte.bold.medio,
                                  fontSize: "14px",
                                  fontFamily: theme.fonte.font.normal,
                                  color: theme.cor.cor_preto_300,
                                  padding: 0,
                                  margin: 0,
                                  paddingLeft: "1rem",
                                }}
                              >
                                {p.tipo}
                              </p>
                            </TableCell>

                            <TableCell
                              align="left"
                              style={{
                                borderBottom:
                                  pessoas?.items?.length - 1 !== i
                                    ? "1px solid" + theme.cor.cor_branco_500
                                    : "",
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: theme.fonte.bold.medio,
                                  fontSize: "14px",
                                  fontFamily: theme.fonte.font.normal,
                                  color: theme.cor.cor_preto_300,
                                  padding: 0,
                                  margin: 0,
                                  paddingLeft: "1rem",
                                }}
                              >
                                {p.id_keycloak}
                              </p>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                borderBottom:
                                  pessoas?.items?.length - 1 !== i
                                    ? "1px solid" + theme.cor.cor_branco_500
                                    : "",
                              }}
                            >
                              <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={2}
                              >
                                <Grid item>
                                  <div
                                    style={{
                                      background: theme.cor.cor_principal_100,
                                      borderRadius: "100%",
                                      width: "32px",
                                      height: "32px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      overflow: "hidden",
                                      padding: ".2rem",
                                    }}
                                  >
                                    <SvgTelefone
                                      style={{
                                        fill: theme.cor.cor_principal_500,
                                      }}
                                    />
                                  </div>
                                </Grid>
                                <Grid item>
                                  <div
                                    style={{
                                      background: theme.cor.cor_principal_100,
                                      borderRadius: "100%",
                                      width: "32px",
                                      height: "32px",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                      overflow: "hidden",
                                      padding: ".2rem",
                                    }}
                                  >
                                    <SvgEmail
                                      style={{
                                        fill: theme.cor.cor_principal_500,
                                      }}
                                    />
                                  </div>
                                </Grid>
                              </Grid>
                            </TableCell>
                            <TableCell
                              align="left"
                              style={{
                                borderBottom:
                                  pessoas?.items?.length - 1 !== i
                                    ? "1px solid" + theme.cor.cor_branco_500
                                    : "",
                              }}
                            >
                              <p
                                style={{
                                  fontWeight: theme.fonte.bold.medio,
                                  fontSize: "14px",
                                  fontFamily: theme.fonte.font.normal,
                                  color: theme.cor.cor_preto_300,
                                  padding: 0,
                                  margin: 0,
                                  paddingLeft: "1rem",
                                }}
                              >
                                <LongMenu />
                              </p>
                            </TableCell>
                          </TableRow>
                        ))
                      : ""}
                    <TableRow
                      sx={{
                        background: theme.cor.cor_branco_100,
                      }}
                    >
                      {editar ? <TableCell align="left"></TableCell> : ""}
                      <TableCell align="left">
                        <p
                          style={{
                            fontWeight: theme.fonte.bold.medio,

                            fontFamily: theme.fonte.font.normal,

                            padding: 0,
                            margin: 0,
                            paddingLeft: "1rem",

                            color: theme.cor.cor_preto_200,
                          }}
                        >{`Exibindo ${pessoas?.items?.length} de ${pessoas.total} alunos`}</p>
                      </TableCell>

                      <TableCell align="left"></TableCell>

                      <TableCell align="right" colSpan={3}>
                        <Grid
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                          pr="1rem"
                        >
                          <Grid item>
                            <Pagination
                              onChange={(e, value) => handlePage(value)}
                              count={Math.ceil(pessoas?.total / pessoas?.size)}
                              defaultPage={1}
                              page={pessoas?.page}
                              renderItem={(item) => {
                                if (item.type === "previous") {
                                  return (
                                    <PaginationItem
                                      {...item}
                                      slots={{ previous: SvgVoltarFill }}
                                    />
                                  );
                                } else if (item.type === "next") {
                                  return (
                                    <PaginationItem
                                      {...item}
                                      slots={{ next: SvgAvancarFill }}
                                    />
                                  );
                                }
                                return <PaginationItem {...item} />;
                              }}
                              sx={{
                                "& li .Mui-selected": {
                                  color: theme.cor.cor_branco_100,
                                  border: "none",
                                  backgroundColor: theme.cor.cor_principal_500,
                                },
                                div: {
                                  color: theme.cor.cor_cinza_300,
                                },

                                svg: {
                                  color: theme.cor.cor_cinza_300,
                                },
                                ".MuiPaginationItem-page": {
                                  color: theme.cor.cor_cinza_300,
                                  border:
                                    "3px solid " + theme.cor.cor_cinza_300,
                                  backgroundColor: theme.cor.cor_branco_100,
                                },
                              }}
                            />
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </StyledTable>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Turma;

import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTheme } from "styled-components";
import * as yup from "yup";
import Deletar from "../confirmar/deletar";
import CardMenu, { MenuPhone } from "../menu";
import MenuSuperior from "../menuSuperior";
import { mobile, StyledTableTurma } from "../theme";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import api from "../axios/axios";
import CloseIcon from "@mui/icons-material/Close";
import Paginacao from "../paginacao";
import { toast } from "react-toastify";
import Erro from "../erro";
import Carregar from "../carregar";
import { LoadingButton } from "@mui/lab";
import axios from "axios";
import InputMask from "react-input-mask";

const schemaCadastro = yup
  .object()
  .shape({
    firstName: yup.string().required("Por favor, preencha este campo."),
    lastName: yup.string().required("Por favor, preencha este campo."),
    genero: yup.string().required("Por favor, preencha este campo."),
    username: yup.string().required("Por favor, preencha este campo."),
    dataNascimento: yup.string().required("Por favor, preencha este campo."),

    tipo: yup.string().required("Por favor, preencha este campo."),
    email: yup.string().email("Email inválido"),

    nome_responsavel: yup.string(),
    nome_emergencia: yup.string(),
    parentesco: yup.string(),
    parentesco_emergencia: yup.string(),
    telefone: yup.string(),
    cep: yup.string(),
    estado: yup.string(),
    turma: yup.string(),
    cidade: yup.string(),
    municipio: yup.string(),
    escola: yup.string(),
    rua: yup.string(),
    formacao: yup.string(),
    telefone_parentesco: yup.string(),
    numero: yup.string(),
    bairro: yup.string(),
    complemento: yup.string(),

    senha1: yup
      .string()
      .oneOf([yup.ref("senha2"), ""], "As senhas precisam ser iguais."),

    senha2: yup
      .string()
      .oneOf([yup.ref("senha1"), ""], "As senhas precisam ser iguais."),
  })
  .required();

const CadastroPessoa = ({ decodedToken }: any) => {
  const theme = useTheme();

  const [pessoaSelecao, setPessoaSelecao] = useState<any>({ id: -1 });
  const [pessoas, setPessoas] = useState<any>({
    total: 0,
    items: [],
  });
  const [open, setOpen] = useState(false);
  const [editar, setEditar] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const [configuracao, setConfiguracao] = useState({
    loading: false,
    carregar: true,
    erro: false,
  });
  const valores_padrao = {
    firstName: "",
    lastName: "",
    senha1: "",
    senha2: "",
    tipo: "Aluno",
    genero: "",
    cidade: "",
    municipio: "",
    escola: "",
    turma: "",
    email: "",
    telefone_parentesco: "",
    username: "",
    dataNascimento: "",
    formacao: "",
    nome_responsavel: "",
    parentesco: "",
    telefone: "",
    cep: "",
    estado: "",
    nome_emergencia: "",
    parentesco_emergencia: "",
    rua: "",
    numero: "",
    bairro: "",
    complemento: "",
  };
  const {
    formState,
    register,
    handleSubmit,
    reset,
    getValues,
    setValue,
    watch,
    control,
  } = useForm({
    defaultValues: valores_padrao,
    resolver: yupResolver(schemaCadastro),
  });

  const [cidade, setCidade] = useState<any>({ total: 0, items: [] });
  const [cidadeSelecao, setCidadeSelecao] = useState<any>({ id: -1 });
  const [escola, setEscola] = useState<any>({ total: 0, items: [] });
  const [escolaSelecao, setEscolaSelecao] = useState<any>({ id: -1 });
  const [turma, setTurma] = useState<any>({ total: 0, items: [] });
  const [turmaSelecao, setTurmaSelecao] = useState<any>({ id: -1 });

  const [tipo, setTipo] = useState("aluno");

  useEffect(() => {
    api.get("cidade").then((r) => {
      setCidade(r.data);
    });
  }, []);

  async function buscarEndereco(cep: any) {
    try {
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      console.log(response.data);
      return response.data;
    } catch (error) {
      console.error("Erro ao buscar o CEP", error);
      return null;
    }
  }

  const [uf, setUf] = useState<any>();
  const [listUf, setListUf] = useState<any>([]);
  const [city, setCity] = useState<any>("");
  const [listCity, setListCity] = useState<any>([]);
  function loadUf() {
    let url = "https://servicodados.ibge.gov.br/";
    url = url + "api/v1/localidades/estados";
    fetch(url)
      .then((response) => response.json())
      .then((data: any) => {
        data.sort((a: any, b: any) => a.nome.localeCompare(b.nome));
        setListUf([...data]);
      });
  }
  function loadCity(id: any) {
    let url = "https://servicodados.ibge.gov.br/api/v1/";
    url = url + `localidades/estados/${id}/municipios`;
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        data.sort((a: any, b: any) => a.nome.localeCompare(b.nome));
        setListCity([...data]);
      });
  }
  useEffect(() => {
    loadUf();
  }, []);
  useEffect(() => {
    if (uf) {
      loadCity(uf);
    }
  }, [uf]);

  const handleCepChange = async (event: any) => {
    const cep = event.target.value;
    setValue("cep", cep);
    if (/^\d{5}-\d{3}$/.test(cep)) {
      const endereco = await buscarEndereco(cep);

      if (endereco && endereco.uf && endereco.localidade) {
        setValue("estado", endereco.uf);
        setValue("cidade", endereco.localidade);
        setValue("rua", endereco.logradouro);
        setValue("bairro", endereco.bairro);
        setCity(endereco.localidade);
      }
    }
  };

  useEffect(() => {
    if (cidadeSelecao.id !== -1) {
      setTurma({ total: 0, items: [] });
      setEscola({ total: 0, items: [] });
      setEscolaSelecao({ id: -1 });
      setTurmaSelecao({ id: -1 });
      setPessoas({ total: 0, items: [] });
      api.get("cidade/escola/" + cidadeSelecao.id).then((r) => {
        setEscola(r.data);
      });
    }
  }, [cidadeSelecao]);

  useEffect(() => {
    if (escolaSelecao.id !== -1) {
      setPessoas({ total: 0, items: [] });

      setTurma({ total: 0, items: [] });
      setTurmaSelecao({ id: -1 });

      api.get("escola/turma/" + escolaSelecao?.id).then((r) => {
        setTurma(r.data);
      });
    }
  }, [escolaSelecao]);

  const cadastro = (data: any) => {
    if (editar) {
      toast.info("Editando pessoa");
      api
        .post(`/admin/${data.username}`, data)
        .then((r) => {
          setPessoas(() => {
            let items = pessoas.items.map((e: any) => {
              if (String(e.username) === String(data.username)) {
                return data;
              } else {
                return e;
              }
            });

            return { ...pessoas, items: items };
          });
          reset(valores_padrao);
          setEditar(false);
          toast.success("Pessoa editada");
        })
        .catch(() => {
          toast.error("Pessoa não editada");
        })
        .finally(() => {
          setConfiguracao((antes: any) => {
            return { ...antes, loading: false };
          });
        });
    } else {
      toast.info("Criando pessoa");
      api
        .post(`/admin`, data)
        .then((r) => {
          reset(valores_padrao);
          toast.success("Pessoa criada");
        })
        .catch(() => {
          toast.error("Pessoa não criada");
        })
        .finally(() => {
          setConfiguracao((antes: any) => {
            return { ...antes, loading: false };
          });
        });
    }
  };

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <Grid
        container
        direction="row"
        style={{ background: theme.cor.cor_fundo }}
      >
        <CardMenu
          decodedToken={decodedToken}
          menuSelecao={15}
          ativo={mobile(windowSize)}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto" }}
          width={"100%"}
        >
          <MenuPhone
            ativo={mobile(windowSize) ? true : false}
            decodedToken={decodedToken}
          />
          <Grid
            item
            style={{
              background: theme.cor.cor_branco_100,
              padding: 0,
              margin: 0,
            }}
          >
            <MenuSuperior ativo={mobile(windowSize)} texto={"Admin"} />
          </Grid>
          <Grid item padding={3} marginTop={mobile(windowSize) ? "" : "4rem"}>
            <Grid
              item
              style={{
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 10px 0px",
                borderRadius: "1rem",
                background: theme.cor.cor_branco_100,
                paddingBottom: "1rem",
              }}
            >
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                style={{
                  background: theme.cor.cor_principal_500,
                  color: theme.cor.cor_branco_100,
                  fontFamily: theme.fonte.font.fonte_1,
                  fontSize: "18px",
                  fontWeight: theme.fonte.bold.medio,
                  padding: ".5rem",
                  borderTopLeftRadius: ".5rem",
                  borderTopRightRadius: ".5rem",
                }}
              >
                <Grid item style={{ paddingLeft: "1rem" }}>
                  Cadastro de Pessoa
                </Grid>
              </Grid>

              <Grid item>
                <form onSubmit={handleSubmit(cadastro)}>
                  <Grid item xs={12} style={{ paddingTop: "1rem" }}>
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      spacing={1}
                      padding={2}
                    >
                      <Grid
                        item
                        xs={12}
                        sx={{ maxWidth: "100%" }}
                        style={{ paddingBottom: ".5rem", paddingTop: "0rem" }}
                      >
                        <FormControl sx={{ maxWidth: "100%" }} fullWidth>
                          <p
                            style={{
                              fontWeight: theme.fonte.bold.bold,
                              fontFamily: theme.fonte.font.fonte_1,
                              color: theme.cor.cor_preto_300,
                              padding: 0,
                              margin: 0,
                              paddingBottom: ".5rem",
                            }}
                          >
                            Tipo
                          </p>

                          <Controller
                            name="tipo"
                            control={control}
                            render={({ field: { ref, value, ...field } }) => (
                              <Select
                                {...field}
                                inputRef={ref}
                                value={value}
                                id="tipo"
                                style={{
                                  color: theme.cor.cor_preto_300,
                                  height: "40px",
                                  fontFamily: theme.fonte.font.fonte_1,
                                }}
                                required
                                displayEmpty
                                renderValue={(value: any) => {
                                  if (value === "") {
                                    return (
                                      <p
                                        style={{
                                          color: theme.cor.cor_preto_300,

                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        Selecione
                                      </p>
                                    );
                                  }
                                  return value;
                                }}
                                sx={{
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: theme.cor.cor_preto_100,
                                    },
                                  height: "80px",
                                  color: theme.cor.cor_preto_300,
                                  fontFamily: theme.fonte.font.fonte_1,
                                }}
                              >
                                <MenuItem
                                  sx={{
                                    color: theme.cor.cor_preto_300,
                                    fontFamily: theme.fonte.font.fonte_1,
                                    "&:hover": {
                                      background: theme.cor.cor_principal_300,
                                      color: theme.cor.cor_branco_100,
                                    },
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    "&.Mui-selected": {
                                      background: theme.cor.cor_principal_200,
                                      color: theme.cor.cor_branco_100,
                                    },
                                  }}
                                  key={"aluno"}
                                  value={"Aluno"}
                                  onClick={() => {
                                    setTipo("aluno");
                                  }}
                                >
                                  Aluno
                                </MenuItem>

                                <MenuItem
                                  sx={{
                                    color: theme.cor.cor_preto_300,
                                    fontFamily: theme.fonte.font.fonte_1,
                                    "&:hover": {
                                      background: theme.cor.cor_principal_300,
                                      color: theme.cor.cor_branco_100,
                                    },
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    "&.Mui-selected": {
                                      background: theme.cor.cor_principal_200,
                                      color: theme.cor.cor_branco_100,
                                    },
                                  }}
                                  key={"professor"}
                                  value={"Professor"}
                                  onClick={() => {
                                    setTipo("professor");
                                  }}
                                >
                                  Professor
                                </MenuItem>
                                <MenuItem
                                  sx={{
                                    color: theme.cor.cor_preto_300,
                                    fontFamily: theme.fonte.font.fonte_1,
                                    "&:hover": {
                                      background: theme.cor.cor_principal_300,
                                      color: theme.cor.cor_branco_100,
                                    },
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    "&.Mui-selected": {
                                      background: theme.cor.cor_principal_200,
                                      color: theme.cor.cor_branco_100,
                                    },
                                  }}
                                  key={"gestor"}
                                  value={"Gestor"}
                                  onClick={() => {
                                    setTipo("gestor");
                                  }}
                                >
                                  Gestor
                                </MenuItem>
                                <MenuItem
                                  sx={{
                                    color: theme.cor.cor_preto_300,
                                    fontFamily: theme.fonte.font.fonte_1,
                                    "&:hover": {
                                      background: theme.cor.cor_principal_300,
                                      color: theme.cor.cor_branco_100,
                                    },
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    "&.Mui-selected": {
                                      background: theme.cor.cor_principal_200,
                                      color: theme.cor.cor_branco_100,
                                    },
                                  }}
                                  key={"administrador"}
                                  value={"Administrador"}
                                  onClick={() => {
                                    setTipo("administrador");
                                  }}
                                >
                                  Administrador
                                </MenuItem>
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <p
                          style={{
                            fontWeight: theme.fonte.bold.bold,
                            fontFamily: theme.fonte.font.fonte_1,
                            color: theme.cor.cor_preto_300,
                            padding: 0,
                            margin: 0,
                            paddingBottom: ".5rem",
                          }}
                        >
                          Nome
                        </p>
                        <Controller
                          name="firstName"
                          control={control}
                          render={({ field: { ref, value, ...field } }) => (
                            <TextField
                              {...field}
                              inputRef={ref}
                              value={value || ""}
                              size="small"
                              fullWidth
                              sx={{
                                ".MuiOutlinedInput-input": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label.Mui-focused": {
                                  color: theme.cor.cor_principal_500,
                                },
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "& fieldset": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                },
                              }}
                              required
                              error={Boolean(formState.errors?.firstName)}
                              helperText={formState?.errors?.firstName?.message}
                              placeholder={"Nome"}
                              type="text"
                              InputLabelProps={{
                                shrink: value ? true : false,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <p
                          style={{
                            fontWeight: theme.fonte.bold.bold,
                            fontFamily: theme.fonte.font.fonte_1,
                            color: theme.cor.cor_preto_300,
                            padding: 0,
                            margin: 0,
                            paddingBottom: ".5rem",
                          }}
                        >
                          Sobrenome
                        </p>
                        <Controller
                          name="lastName"
                          control={control}
                          render={({ field: { ref, value, ...field } }) => (
                            <TextField
                              {...field}
                              inputRef={ref}
                              value={value || ""}
                              size="small"
                              fullWidth
                              sx={{
                                ".MuiOutlinedInput-input": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label.Mui-focused": {
                                  color: theme.cor.cor_principal_500,
                                },
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "& fieldset": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                },
                              }}
                              required
                              error={Boolean(formState.errors?.firstName)}
                              helperText={formState?.errors?.firstName?.message}
                              placeholder={"Sobrenome"}
                              type="text"
                              InputLabelProps={{
                                shrink: value ? true : false,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <p
                          style={{
                            fontWeight: theme.fonte.bold.bold,
                            fontFamily: theme.fonte.font.fonte_1,
                            color: theme.cor.cor_preto_300,
                            padding: 0,
                            margin: 0,
                            paddingBottom: ".5rem",
                          }}
                        >
                          Data de nascimento
                        </p>
                        <Controller
                          name="dataNascimento"
                          control={control}
                          render={({
                            field: { ref, value, onBlur, onChange, ...field },
                          }) => (
                            <InputMask
                              mask="99/99/9999"
                              value={value || ""}
                              onChange={onChange}
                              onBlur={onBlur}
                            >
                              <TextField
                                {...field}
                                inputRef={ref}
                                size="small"
                                fullWidth
                                sx={{
                                  ".MuiOutlinedInput-input": {
                                    color: theme.cor.cor_preto_300,
                                  },
                                  "& label": {
                                    color: theme.cor.cor_preto_300,
                                  },
                                  "& label.Mui-focused": {
                                    color: theme.cor.cor_principal_500,
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: theme.cor.cor_principal_500,
                                    },
                                    "& fieldset": {
                                      borderColor: theme.cor.cor_preto_100,
                                    },
                                    "&:hover fieldset": {
                                      borderColor: theme.cor.cor_principal_500,
                                    },
                                  },
                                }}
                                required
                                error={Boolean(
                                  formState.errors?.telefone_parentesco
                                )}
                                helperText={
                                  formState?.errors?.telefone_parentesco
                                    ?.message
                                }
                                placeholder={"Dia/Mês/Ano"}
                                type="text"
                                InputLabelProps={{
                                  shrink: value ? true : false,
                                }}
                              />
                            </InputMask>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormControl sx={{ maxWidth: "100%" }} fullWidth>
                          <p
                            style={{
                              fontWeight: theme.fonte.bold.bold,
                              fontFamily: theme.fonte.font.fonte_1,
                              color: theme.cor.cor_preto_300,
                              padding: 0,
                              margin: 0,
                              paddingBottom: ".5rem",
                            }}
                          >
                            Gênero
                          </p>

                          <Controller
                            name="genero"
                            control={control}
                            render={({ field: { ref, value, ...field } }) => (
                              <Select
                                {...field}
                                inputRef={ref}
                                value={value}
                                id="genero"
                                style={{
                                  color: theme.cor.cor_preto_300,
                                  height: "40px",
                                  fontFamily: theme.fonte.font.fonte_1,
                                }}
                                required
                                displayEmpty
                                renderValue={(value: any) => {
                                  if (value === "") {
                                    return (
                                      <p
                                        style={{
                                          color: theme.cor.cor_preto_300,

                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        Selecione
                                      </p>
                                    );
                                  }
                                  return value;
                                }}
                                sx={{
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: theme.cor.cor_preto_100,
                                    },
                                  height: "80px",
                                  color: theme.cor.cor_preto_300,
                                  fontFamily: theme.fonte.font.fonte_1,
                                }}
                              >
                                <MenuItem
                                  sx={{
                                    color: theme.cor.cor_preto_300,
                                    fontFamily: theme.fonte.font.fonte_1,
                                    "&:hover": {
                                      background: theme.cor.cor_principal_300,
                                      color: theme.cor.cor_branco_100,
                                    },
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    "&.Mui-selected": {
                                      background: theme.cor.cor_principal_200,
                                      color: theme.cor.cor_branco_100,
                                    },
                                  }}
                                  key={"Feminino"}
                                  value={"Feminino"}
                                >
                                  Feminino
                                </MenuItem>

                                <MenuItem
                                  sx={{
                                    color: theme.cor.cor_preto_300,
                                    fontFamily: theme.fonte.font.fonte_1,
                                    "&:hover": {
                                      background: theme.cor.cor_principal_300,
                                      color: theme.cor.cor_branco_100,
                                    },
                                    whiteSpace: "normal",
                                    wordWrap: "break-word",
                                    "&.Mui-selected": {
                                      background: theme.cor.cor_principal_200,
                                      color: theme.cor.cor_branco_100,
                                    },
                                  }}
                                  key={"Masculino"}
                                  value={"Masculino"}
                                >
                                  Masculino
                                </MenuItem>
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} lg={3}>
                        <p
                          style={{
                            fontWeight: theme.fonte.bold.bold,
                            fontFamily: theme.fonte.font.fonte_1,
                            color: theme.cor.cor_preto_300,
                            padding: 0,
                            margin: 0,
                            paddingBottom: ".5rem",
                            paddingTop: ".5rem",
                          }}
                        >
                          Número de matrícula
                        </p>
                        <Controller
                          name="username"
                          control={control}
                          render={({ field: { ref, value, ...field } }) => (
                            <TextField
                              {...field}
                              inputRef={ref}
                              value={value || ""}
                              size="small"
                              fullWidth
                              disabled={editar}
                              sx={{
                                ".MuiOutlinedInput-input": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label.Mui-focused": {
                                  color: theme.cor.cor_principal_500,
                                },
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "& fieldset": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                },
                              }}
                              required
                              error={Boolean(formState.errors?.username)}
                              helperText={formState?.errors?.username?.message}
                              placeholder={"Matrícula"}
                              type="text"
                              InputLabelProps={{
                                shrink: value ? true : false,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormControl sx={{ maxWidth: "100%" }} fullWidth>
                          <p
                            style={{
                              fontWeight: theme.fonte.bold.bold,
                              fontFamily: theme.fonte.font.fonte_1,
                              color: theme.cor.cor_preto_300,
                              padding: 0,
                              margin: 0,
                              paddingBottom: ".5rem",
                              paddingTop: ".5rem",
                            }}
                          >
                            Cidade
                          </p>

                          <Controller
                            name="cidade"
                            control={control}
                            render={({ field: { ref, value, ...field } }) => (
                              <Select
                                {...field}
                                inputRef={ref}
                                value={value}
                                id="cidade"
                                style={{
                                  color: theme.cor.cor_preto_300,
                                  height: "40px",
                                  fontFamily: theme.fonte.font.fonte_1,
                                }}
                                required
                                displayEmpty
                                renderValue={(value: any) => {
                                  if (value === "") {
                                    return (
                                      <p
                                        style={{
                                          color: theme.cor.cor_preto_300,

                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        Selecione
                                      </p>
                                    );
                                  }
                                  return value;
                                }}
                                sx={{
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: theme.cor.cor_preto_100,
                                    },
                                  height: "80px",
                                  color: theme.cor.cor_preto_300,
                                  fontFamily: theme.fonte.font.fonte_1,
                                }}
                              >
                                {cidade?.items?.length > 0 ? (
                                  cidade?.items?.map((c: any) => (
                                    <MenuItem
                                      onClick={() => setCidadeSelecao(c)}
                                      sx={{
                                        color: theme.cor.cor_preto_300,
                                        fontFamily: theme.fonte.font.fonte_1,
                                        "&:hover": {
                                          background:
                                            theme.cor.cor_principal_300,
                                          color: theme.cor.cor_branco_100,
                                        },
                                        whiteSpace: "normal",
                                        wordWrap: "break-word",
                                        "&.Mui-selected": {
                                          background:
                                            theme.cor.cor_principal_200,
                                          color: theme.cor.cor_branco_100,
                                        },
                                      }}
                                      value={c.nome}
                                    >
                                      {c.nome}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem
                                    sx={{
                                      color: theme.cor.cor_preto_300,
                                      fontFamily: theme.fonte.font.fonte_1,
                                      "&:hover": {
                                        background: theme.cor.cor_principal_300,
                                        color: theme.cor.cor_branco_100,
                                      },
                                      whiteSpace: "normal",
                                      wordWrap: "break-word",
                                      "&.Mui-selected": {
                                        background: theme.cor.cor_principal_200,
                                        color: theme.cor.cor_branco_100,
                                      },
                                    }}
                                    value={"Nenhuma cidade"}
                                  >
                                    Nenhuma cidade
                                  </MenuItem>
                                )}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormControl sx={{ maxWidth: "100%" }} fullWidth>
                          <p
                            style={{
                              fontWeight: theme.fonte.bold.bold,
                              fontFamily: theme.fonte.font.fonte_1,
                              color: theme.cor.cor_preto_300,
                              padding: 0,
                              margin: 0,
                              paddingBottom: ".5rem",
                              paddingTop: ".5rem",
                            }}
                          >
                            Escola
                          </p>

                          <Controller
                            name="escola"
                            control={control}
                            render={({ field: { ref, value, ...field } }) => (
                              <Select
                                {...field}
                                inputRef={ref}
                                value={
                                  escolaSelecao?.id != -1
                                    ? escolaSelecao?.nome
                                    : ""
                                }
                                id="escola"
                                style={{
                                  color: theme.cor.cor_preto_300,
                                  height: "40px",
                                  fontFamily: theme.fonte.font.fonte_1,
                                }}
                                required
                                displayEmpty
                                renderValue={(value: any) => {
                                  if (value === "") {
                                    return (
                                      <p
                                        style={{
                                          color: theme.cor.cor_preto_300,

                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        Selecione
                                      </p>
                                    );
                                  }
                                  return value;
                                }}
                                sx={{
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: theme.cor.cor_preto_100,
                                    },
                                  height: "80px",
                                  color: theme.cor.cor_preto_300,
                                  fontFamily: theme.fonte.font.fonte_1,
                                }}
                              >
                                {escola?.items?.length > 0 ? (
                                  escola?.items?.map((c: any) => (
                                    <MenuItem
                                      onClick={() => setEscolaSelecao(c)}
                                      sx={{
                                        color: theme.cor.cor_preto_300,
                                        fontFamily: theme.fonte.font.fonte_1,
                                        "&:hover": {
                                          background:
                                            theme.cor.cor_principal_300,
                                          color: theme.cor.cor_branco_100,
                                        },
                                        whiteSpace: "normal",
                                        wordWrap: "break-word",
                                        "&.Mui-selected": {
                                          background:
                                            theme.cor.cor_principal_200,
                                          color: theme.cor.cor_branco_100,
                                        },
                                      }}
                                      value={c.nome}
                                    >
                                      {c.nome}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem
                                    sx={{
                                      color: theme.cor.cor_preto_300,
                                      fontFamily: theme.fonte.font.fonte_1,
                                      "&:hover": {
                                        background: theme.cor.cor_principal_300,
                                        color: theme.cor.cor_branco_100,
                                      },
                                      whiteSpace: "normal",
                                      wordWrap: "break-word",
                                      "&.Mui-selected": {
                                        background: theme.cor.cor_principal_200,
                                        color: theme.cor.cor_branco_100,
                                      },
                                    }}
                                    value={"Nenhuma escola"}
                                  >
                                    Nenhuma escola
                                  </MenuItem>
                                )}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={3}>
                        <FormControl sx={{ maxWidth: "100%" }} fullWidth>
                          <p
                            style={{
                              fontWeight: theme.fonte.bold.bold,
                              fontFamily: theme.fonte.font.fonte_1,
                              color: theme.cor.cor_preto_300,
                              padding: 0,
                              margin: 0,
                              paddingBottom: ".5rem",
                              paddingTop: ".5rem",
                            }}
                          >
                            Turma
                          </p>

                          <Controller
                            name="turma"
                            control={control}
                            render={({ field: { ref, value, ...field } }) => (
                              <Select
                                {...field}
                                inputRef={ref}
                                value={
                                  turmaSelecao?.id != -1
                                    ? turmaSelecao?.nome
                                    : ""
                                }
                                id="turma"
                                style={{
                                  color: theme.cor.cor_preto_300,
                                  height: "40px",
                                  fontFamily: theme.fonte.font.fonte_1,
                                }}
                                required
                                displayEmpty
                                renderValue={(value: any) => {
                                  if (value === "") {
                                    return (
                                      <p
                                        style={{
                                          color: theme.cor.cor_preto_300,

                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        Selecione
                                      </p>
                                    );
                                  }
                                  return value;
                                }}
                                sx={{
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: theme.cor.cor_preto_100,
                                    },
                                  height: "80px",
                                  color: theme.cor.cor_preto_300,
                                  fontFamily: theme.fonte.font.fonte_1,
                                }}
                              >
                                {turma?.items?.length > 0 ? (
                                  turma?.items?.map((c: any) => (
                                    <MenuItem
                                      onClick={() => setTurmaSelecao(c)}
                                      sx={{
                                        color: theme.cor.cor_preto_300,
                                        fontFamily: theme.fonte.font.fonte_1,
                                        "&:hover": {
                                          background:
                                            theme.cor.cor_principal_300,
                                          color: theme.cor.cor_branco_100,
                                        },
                                        whiteSpace: "normal",
                                        wordWrap: "break-word",
                                        "&.Mui-selected": {
                                          background:
                                            theme.cor.cor_principal_200,
                                          color: theme.cor.cor_branco_100,
                                        },
                                      }}
                                      value={c.nome}
                                    >
                                      {c.nome}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem
                                    sx={{
                                      color: theme.cor.cor_preto_300,
                                      fontFamily: theme.fonte.font.fonte_1,
                                      "&:hover": {
                                        background: theme.cor.cor_principal_300,
                                        color: theme.cor.cor_branco_100,
                                      },
                                      whiteSpace: "normal",
                                      wordWrap: "break-word",
                                      "&.Mui-selected": {
                                        background: theme.cor.cor_principal_200,
                                        color: theme.cor.cor_branco_100,
                                      },
                                    }}
                                    value={"Nenhuma turma"}
                                  >
                                    Nenhuma turma
                                  </MenuItem>
                                )}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12}>
                        <p
                          style={{
                            fontWeight: theme.fonte.bold.bold,
                            fontFamily: theme.fonte.font.fonte_1,
                            color: theme.cor.cor_preto_300,
                            padding: 0,
                            margin: 0,
                            paddingBottom: ".5rem",
                            paddingTop: ".5rem",
                          }}
                        >
                          E-mail institucional
                        </p>
                        <Controller
                          name="email"
                          control={control}
                          render={({ field: { ref, value, ...field } }) => (
                            <TextField
                              {...field}
                              inputRef={ref}
                              value={value || ""}
                              size="small"
                              fullWidth
                              disabled={editar}
                              sx={{
                                ".MuiOutlinedInput-input": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label.Mui-focused": {
                                  color: theme.cor.cor_principal_500,
                                },
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "& fieldset": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                },
                              }}
                              required
                              error={Boolean(formState.errors?.email)}
                              helperText={formState?.errors?.email?.message}
                              placeholder={"escola@escola.com"}
                              type="text"
                              InputLabelProps={{
                                shrink: value ? true : false,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      {tipo === "aluno" ? (
                        <>
                          <Grid item xs={12} lg={4}>
                            <p
                              style={{
                                fontWeight: theme.fonte.bold.bold,
                                fontFamily: theme.fonte.font.fonte_1,
                                color: theme.cor.cor_preto_300,
                                padding: 0,
                                margin: 0,
                                paddingBottom: ".5rem",
                                paddingTop: ".5rem",
                              }}
                            >
                              Nome do Responsável
                            </p>
                            <Controller
                              name="nome_responsavel"
                              control={control}
                              render={({ field: { ref, value, ...field } }) => (
                                <TextField
                                  {...field}
                                  inputRef={ref}
                                  value={value || ""}
                                  size="small"
                                  fullWidth
                                  disabled={editar}
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: theme.cor.cor_preto_300,
                                    },
                                    "& label": {
                                      color: theme.cor.cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: theme.cor.cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor:
                                          theme.cor.cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: theme.cor.cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor:
                                          theme.cor.cor_principal_500,
                                      },
                                    },
                                  }}
                                  required
                                  error={Boolean(
                                    formState.errors?.nome_responsavel
                                  )}
                                  helperText={
                                    formState?.errors?.nome_responsavel?.message
                                  }
                                  placeholder={"Nome do Responsável"}
                                  type="text"
                                  InputLabelProps={{
                                    shrink: value ? true : false,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            <p
                              style={{
                                fontWeight: theme.fonte.bold.bold,
                                fontFamily: theme.fonte.font.fonte_1,
                                color: theme.cor.cor_preto_300,
                                padding: 0,
                                margin: 0,
                                paddingBottom: ".5rem",
                                paddingTop: ".5rem",
                              }}
                            >
                              Parentesco
                            </p>
                            <Controller
                              name="parentesco"
                              control={control}
                              render={({ field: { ref, value, ...field } }) => (
                                <TextField
                                  {...field}
                                  inputRef={ref}
                                  value={value || ""}
                                  size="small"
                                  fullWidth
                                  disabled={editar}
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: theme.cor.cor_preto_300,
                                    },
                                    "& label": {
                                      color: theme.cor.cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: theme.cor.cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor:
                                          theme.cor.cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: theme.cor.cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor:
                                          theme.cor.cor_principal_500,
                                      },
                                    },
                                  }}
                                  required
                                  error={Boolean(formState.errors?.parentesco)}
                                  helperText={
                                    formState?.errors?.parentesco?.message
                                  }
                                  placeholder={"Parentesco"}
                                  type="text"
                                  InputLabelProps={{
                                    shrink: value ? true : false,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            <p
                              style={{
                                fontWeight: theme.fonte.bold.bold,
                                fontFamily: theme.fonte.font.fonte_1,
                                color: theme.cor.cor_preto_300,
                                padding: 0,
                                margin: 0,
                                paddingBottom: ".5rem",
                                paddingTop: ".5rem",
                              }}
                            >
                              Telefone de contato
                            </p>
                            <Controller
                              name="telefone_parentesco"
                              control={control}
                              render={({
                                field: {
                                  ref,
                                  value,
                                  onBlur,
                                  onChange,
                                  ...field
                                },
                              }) => (
                                <InputMask
                                  mask="(99) 9 9999-9999"
                                  value={value || ""}
                                  onChange={onChange}
                                  onBlur={onBlur}
                                >
                                  <TextField
                                    {...field}
                                    inputRef={ref}
                                    size="small"
                                    fullWidth
                                    sx={{
                                      ".MuiOutlinedInput-input": {
                                        color: theme.cor.cor_preto_300,
                                      },
                                      "& label": {
                                        color: theme.cor.cor_preto_300,
                                      },
                                      "& label.Mui-focused": {
                                        color: theme.cor.cor_principal_500,
                                      },
                                      "& .MuiOutlinedInput-root": {
                                        "&.Mui-focused fieldset": {
                                          borderColor:
                                            theme.cor.cor_principal_500,
                                        },
                                        "& fieldset": {
                                          borderColor: theme.cor.cor_preto_100,
                                        },
                                        "&:hover fieldset": {
                                          borderColor:
                                            theme.cor.cor_principal_500,
                                        },
                                      },
                                    }}
                                    required
                                    error={Boolean(
                                      formState.errors?.telefone_parentesco
                                    )}
                                    helperText={
                                      formState?.errors?.telefone_parentesco
                                        ?.message
                                    }
                                    placeholder={"(00) 0 0000-0000"}
                                    type="text"
                                    InputLabelProps={{
                                      shrink: value ? true : false,
                                    }}
                                  />
                                </InputMask>
                              )}
                            />
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid item xs={12} lg={6}>
                            <p
                              style={{
                                fontWeight: theme.fonte.bold.bold,
                                fontFamily: theme.fonte.font.fonte_1,
                                color: theme.cor.cor_preto_300,
                                padding: 0,
                                margin: 0,
                                paddingBottom: ".5rem",
                                paddingTop: ".5rem",
                              }}
                            >
                              Formação acadêmica
                            </p>
                            <Controller
                              name="formacao"
                              control={control}
                              render={({ field: { ref, value, ...field } }) => (
                                <TextField
                                  {...field}
                                  inputRef={ref}
                                  value={value || ""}
                                  size="small"
                                  fullWidth
                                  disabled={editar}
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: theme.cor.cor_preto_300,
                                    },
                                    "& label": {
                                      color: theme.cor.cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: theme.cor.cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor:
                                          theme.cor.cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: theme.cor.cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor:
                                          theme.cor.cor_principal_500,
                                      },
                                    },
                                  }}
                                  required
                                  error={Boolean(formState.errors?.formacao)}
                                  helperText={
                                    formState?.errors?.formacao?.message
                                  }
                                  placeholder={"Formação"}
                                  type="text"
                                  InputLabelProps={{
                                    shrink: value ? true : false,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} lg={6}>
                            <p
                              style={{
                                fontWeight: theme.fonte.bold.bold,
                                fontFamily: theme.fonte.font.fonte_1,
                                color: theme.cor.cor_preto_300,
                                padding: 0,
                                margin: 0,
                                paddingBottom: ".5rem",
                                paddingTop: ".5rem",
                              }}
                            >
                              Telefone de contato
                            </p>
                            <Controller
                              name="telefone"
                              control={control}
                              render={({ field: { ref, value, ...field } }) => (
                                <TextField
                                  {...field}
                                  inputRef={ref}
                                  value={value || ""}
                                  size="small"
                                  fullWidth
                                  disabled={editar}
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: theme.cor.cor_preto_300,
                                    },
                                    "& label": {
                                      color: theme.cor.cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: theme.cor.cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor:
                                          theme.cor.cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: theme.cor.cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor:
                                          theme.cor.cor_principal_500,
                                      },
                                    },
                                  }}
                                  required
                                  error={Boolean(formState.errors?.telefone)}
                                  helperText={
                                    formState?.errors?.telefone?.message
                                  }
                                  placeholder={"(00) 0 0000-0000"}
                                  type="text"
                                  InputLabelProps={{
                                    shrink: value ? true : false,
                                  }}
                                />
                              )}
                            />
                          </Grid>

                          <Grid item xs={12} lg={4}>
                            <p
                              style={{
                                fontWeight: theme.fonte.bold.bold,
                                fontFamily: theme.fonte.font.fonte_1,
                                color: theme.cor.cor_preto_300,
                                padding: 0,
                                margin: 0,
                                paddingBottom: ".5rem",
                                paddingTop: ".5rem",
                              }}
                            >
                              Contato de emergência
                            </p>
                            <Controller
                              name="nome_emergencia"
                              control={control}
                              render={({ field: { ref, value, ...field } }) => (
                                <TextField
                                  {...field}
                                  inputRef={ref}
                                  value={value || ""}
                                  size="small"
                                  fullWidth
                                  disabled={editar}
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: theme.cor.cor_preto_300,
                                    },
                                    "& label": {
                                      color: theme.cor.cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: theme.cor.cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor:
                                          theme.cor.cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: theme.cor.cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor:
                                          theme.cor.cor_principal_500,
                                      },
                                    },
                                  }}
                                  required
                                  error={Boolean(
                                    formState.errors?.nome_responsavel
                                  )}
                                  helperText={
                                    formState?.errors?.nome_responsavel?.message
                                  }
                                  placeholder={"Nome do Responsável"}
                                  type="text"
                                  InputLabelProps={{
                                    shrink: value ? true : false,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            <p
                              style={{
                                fontWeight: theme.fonte.bold.bold,
                                fontFamily: theme.fonte.font.fonte_1,
                                color: theme.cor.cor_preto_300,
                                padding: 0,
                                margin: 0,
                                paddingBottom: ".5rem",
                                paddingTop: ".5rem",
                              }}
                            >
                              Parentesco
                            </p>
                            <Controller
                              name="parentesco_emergencia"
                              control={control}
                              render={({ field: { ref, value, ...field } }) => (
                                <TextField
                                  {...field}
                                  inputRef={ref}
                                  value={value || ""}
                                  size="small"
                                  fullWidth
                                  disabled={editar}
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: theme.cor.cor_preto_300,
                                    },
                                    "& label": {
                                      color: theme.cor.cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: theme.cor.cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor:
                                          theme.cor.cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: theme.cor.cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor:
                                          theme.cor.cor_principal_500,
                                      },
                                    },
                                  }}
                                  required
                                  error={Boolean(
                                    formState.errors?.parentesco_emergencia
                                  )}
                                  helperText={
                                    formState?.errors?.parentesco_emergencia
                                      ?.message
                                  }
                                  placeholder={"Parentesco"}
                                  type="text"
                                  InputLabelProps={{
                                    shrink: value ? true : false,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} lg={4}>
                            <p
                              style={{
                                fontWeight: theme.fonte.bold.bold,
                                fontFamily: theme.fonte.font.fonte_1,
                                color: theme.cor.cor_preto_300,
                                padding: 0,
                                margin: 0,
                                paddingBottom: ".5rem",
                                paddingTop: ".5rem",
                              }}
                            >
                              Telefone de contato
                            </p>
                            <Controller
                              name="telefone_parentesco"
                              control={control}
                              render={({ field: { ref, value, ...field } }) => (
                                <TextField
                                  {...field}
                                  inputRef={ref}
                                  value={value || ""}
                                  size="small"
                                  fullWidth
                                  disabled={editar}
                                  sx={{
                                    ".MuiOutlinedInput-input": {
                                      color: theme.cor.cor_preto_300,
                                    },
                                    "& label": {
                                      color: theme.cor.cor_preto_300,
                                    },
                                    "& label.Mui-focused": {
                                      color: theme.cor.cor_principal_500,
                                    },
                                    "& .MuiOutlinedInput-root": {
                                      "&.Mui-focused fieldset": {
                                        borderColor:
                                          theme.cor.cor_principal_500,
                                      },
                                      "& fieldset": {
                                        borderColor: theme.cor.cor_preto_100,
                                      },
                                      "&:hover fieldset": {
                                        borderColor:
                                          theme.cor.cor_principal_500,
                                      },
                                    },
                                  }}
                                  required
                                  error={Boolean(
                                    formState.errors?.telefone_parentesco
                                  )}
                                  helperText={
                                    formState?.errors?.telefone_parentesco
                                      ?.message
                                  }
                                  placeholder={"(00) 0 0000-0000"}
                                  type="text"
                                  InputLabelProps={{
                                    shrink: value ? true : false,
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </>
                      )}
                      <Grid item xs={12} lg={4}>
                        <p
                          style={{
                            fontWeight: theme.fonte.bold.bold,
                            fontFamily: theme.fonte.font.fonte_1,
                            color: theme.cor.cor_preto_300,
                            padding: 0,
                            margin: 0,
                            paddingBottom: ".5rem",
                            paddingTop: ".5rem",
                          }}
                        >
                          CEP
                        </p>
                        <Controller
                          name="cep"
                          control={control}
                          render={({
                            field: { ref, value, onBlur, onChange, ...field },
                          }) => (
                            <InputMask
                              mask="99999-999"
                              value={value || ""}
                              onChange={(e: any) => {
                                onChange();
                                handleCepChange(e);
                              }}
                              onBlur={onBlur}
                            >
                              <TextField
                                {...field}
                                inputRef={ref}
                                size="small"
                                fullWidth
                                sx={{
                                  ".MuiOutlinedInput-input": {
                                    color: theme.cor.cor_preto_300,
                                  },
                                  "& label": {
                                    color: theme.cor.cor_preto_300,
                                  },
                                  "& label.Mui-focused": {
                                    color: theme.cor.cor_principal_500,
                                  },
                                  "& .MuiOutlinedInput-root": {
                                    "&.Mui-focused fieldset": {
                                      borderColor: theme.cor.cor_principal_500,
                                    },
                                    "& fieldset": {
                                      borderColor: theme.cor.cor_preto_100,
                                    },
                                    "&:hover fieldset": {
                                      borderColor: theme.cor.cor_principal_500,
                                    },
                                  },
                                }}
                                required
                                error={Boolean(
                                  formState.errors?.telefone_parentesco
                                )}
                                helperText={
                                  formState?.errors?.telefone_parentesco
                                    ?.message
                                }
                                placeholder={"12345..."}
                                type="text"
                                InputLabelProps={{
                                  shrink: value ? true : false,
                                }}
                              />
                            </InputMask>
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl sx={{ maxWidth: "100%" }} fullWidth>
                          <p
                            style={{
                              fontWeight: theme.fonte.bold.bold,
                              fontFamily: theme.fonte.font.fonte_1,
                              color: theme.cor.cor_preto_300,
                              padding: 0,
                              margin: 0,
                              paddingBottom: ".5rem",
                              paddingTop: ".5rem",
                            }}
                          >
                            Estado
                          </p>

                          <Controller
                            name="estado"
                            control={control}
                            render={({ field: { ref, value, ...field } }) => (
                              <Select
                                {...field}
                                inputRef={ref}
                                value={value}
                                id="estado"
                                style={{
                                  color: theme.cor.cor_preto_300,
                                  height: "40px",
                                  fontFamily: theme.fonte.font.fonte_1,
                                }}
                                required
                                displayEmpty
                                renderValue={(value: any) => {
                                  if (value === "") {
                                    return (
                                      <p
                                        style={{
                                          color: theme.cor.cor_preto_300,

                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        Selecione
                                      </p>
                                    );
                                  }
                                  return value;
                                }}
                                sx={{
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: theme.cor.cor_preto_100,
                                    },
                                  height: "80px",
                                  color: theme.cor.cor_preto_300,
                                  fontFamily: theme.fonte.font.fonte_1,
                                }}
                              >
                                {listUf?.length > 0 ? (
                                  listUf?.map((c: any) => (
                                    <MenuItem
                                      onClick={() => {
                                        setUf(c.sigla);
                                        setCity("");
                                      }}
                                      sx={{
                                        color: theme.cor.cor_preto_300,
                                        fontFamily: theme.fonte.font.fonte_1,
                                        "&:hover": {
                                          background:
                                            theme.cor.cor_principal_300,
                                          color: theme.cor.cor_branco_100,
                                        },
                                        whiteSpace: "normal",
                                        wordWrap: "break-word",
                                        "&.Mui-selected": {
                                          background:
                                            theme.cor.cor_principal_200,
                                          color: theme.cor.cor_branco_100,
                                        },
                                      }}
                                      value={c.sigla}
                                    >
                                      {c.nome}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem
                                    sx={{
                                      color: theme.cor.cor_preto_300,
                                      fontFamily: theme.fonte.font.fonte_1,
                                      "&:hover": {
                                        background: theme.cor.cor_principal_300,
                                        color: theme.cor.cor_branco_100,
                                      },
                                      whiteSpace: "normal",
                                      wordWrap: "break-word",
                                      "&.Mui-selected": {
                                        background: theme.cor.cor_principal_200,
                                        color: theme.cor.cor_branco_100,
                                      },
                                    }}
                                    value={-1}
                                  >
                                    Selecione
                                  </MenuItem>
                                )}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <FormControl sx={{ maxWidth: "100%" }} fullWidth>
                          <p
                            style={{
                              fontWeight: theme.fonte.bold.bold,
                              fontFamily: theme.fonte.font.fonte_1,
                              color: theme.cor.cor_preto_300,
                              padding: 0,
                              margin: 0,
                              paddingBottom: ".5rem",
                              paddingTop: ".5rem",
                            }}
                          >
                            Cidade
                          </p>

                          <Controller
                            name="municipio"
                            control={control}
                            render={({ field: { ref, value, ...field } }) => (
                              <Select
                                {...field}
                                inputRef={ref}
                                value={city}
                                id="municipio"
                                style={{
                                  color: theme.cor.cor_preto_300,
                                  height: "40px",
                                  fontFamily: theme.fonte.font.fonte_1,
                                }}
                                required
                                displayEmpty
                                renderValue={(value: any) => {
                                  if (value === "") {
                                    return (
                                      <p
                                        style={{
                                          color: theme.cor.cor_preto_300,

                                          whiteSpace: "normal",
                                          wordWrap: "break-word",
                                        }}
                                      >
                                        Selecione
                                      </p>
                                    );
                                  }
                                  return value;
                                }}
                                sx={{
                                  ".MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover .MuiOutlinedInput-notchedOutline": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: theme.cor.cor_preto_100,
                                    },
                                  height: "80px",
                                  color: theme.cor.cor_preto_300,
                                  fontFamily: theme.fonte.font.fonte_1,
                                }}
                              >
                                {listCity?.length > 0 ? (
                                  listCity?.map((c: any) => (
                                    <MenuItem
                                      onClick={() => setCity(c.nome)}
                                      sx={{
                                        color: theme.cor.cor_preto_300,
                                        fontFamily: theme.fonte.font.fonte_1,
                                        "&:hover": {
                                          background:
                                            theme.cor.cor_principal_300,
                                          color: theme.cor.cor_branco_100,
                                        },
                                        whiteSpace: "normal",
                                        wordWrap: "break-word",
                                        "&.Mui-selected": {
                                          background:
                                            theme.cor.cor_principal_200,
                                          color: theme.cor.cor_branco_100,
                                        },
                                      }}
                                      value={c.nome}
                                    >
                                      {c.nome}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem
                                    sx={{
                                      color: theme.cor.cor_preto_300,
                                      fontFamily: theme.fonte.font.fonte_1,
                                      "&:hover": {
                                        background: theme.cor.cor_principal_300,
                                        color: theme.cor.cor_branco_100,
                                      },
                                      whiteSpace: "normal",
                                      wordWrap: "break-word",
                                      "&.Mui-selected": {
                                        background: theme.cor.cor_principal_200,
                                        color: theme.cor.cor_branco_100,
                                      },
                                    }}
                                    value={-1}
                                  >
                                    Selecione
                                  </MenuItem>
                                )}
                              </Select>
                            )}
                          />
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <p
                          style={{
                            fontWeight: theme.fonte.bold.bold,
                            fontFamily: theme.fonte.font.fonte_1,
                            color: theme.cor.cor_preto_300,
                            padding: 0,
                            margin: 0,
                            paddingBottom: ".5rem",
                            paddingTop: ".5rem",
                          }}
                        >
                          Rua
                        </p>
                        <Controller
                          name="rua"
                          control={control}
                          render={({ field: { ref, value, ...field } }) => (
                            <TextField
                              {...field}
                              inputRef={ref}
                              value={value || ""}
                              size="small"
                              fullWidth
                              disabled={editar}
                              sx={{
                                ".MuiOutlinedInput-input": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label.Mui-focused": {
                                  color: theme.cor.cor_principal_500,
                                },
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "& fieldset": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                },
                              }}
                              required
                              error={Boolean(formState.errors?.rua)}
                              helperText={formState?.errors?.rua?.message}
                              placeholder={"Avenida..."}
                              type="text"
                              InputLabelProps={{
                                shrink: value ? true : false,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <p
                          style={{
                            fontWeight: theme.fonte.bold.bold,
                            fontFamily: theme.fonte.font.fonte_1,
                            color: theme.cor.cor_preto_300,
                            padding: 0,
                            margin: 0,
                            paddingBottom: ".5rem",
                            paddingTop: ".5rem",
                          }}
                        >
                          Número
                        </p>
                        <Controller
                          name="numero"
                          control={control}
                          render={({ field: { ref, value, ...field } }) => (
                            <TextField
                              {...field}
                              inputRef={ref}
                              value={value || ""}
                              size="small"
                              fullWidth
                              disabled={editar}
                              sx={{
                                ".MuiOutlinedInput-input": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label.Mui-focused": {
                                  color: theme.cor.cor_principal_500,
                                },
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "& fieldset": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                },
                              }}
                              required
                              error={Boolean(formState.errors?.numero)}
                              helperText={formState?.errors?.numero?.message}
                              placeholder={"123"}
                              type="text"
                              InputLabelProps={{
                                shrink: value ? true : false,
                              }}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <p
                          style={{
                            fontWeight: theme.fonte.bold.bold,
                            fontFamily: theme.fonte.font.fonte_1,
                            color: theme.cor.cor_preto_300,
                            padding: 0,
                            margin: 0,
                            paddingBottom: ".5rem",
                            paddingTop: ".5rem",
                          }}
                        >
                          Bairro
                        </p>
                        <Controller
                          name="bairro"
                          control={control}
                          render={({ field: { ref, value, ...field } }) => (
                            <TextField
                              {...field}
                              inputRef={ref}
                              value={value || ""}
                              size="small"
                              fullWidth
                              disabled={editar}
                              sx={{
                                ".MuiOutlinedInput-input": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label.Mui-focused": {
                                  color: theme.cor.cor_principal_500,
                                },
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "& fieldset": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                },
                              }}
                              required
                              error={Boolean(formState.errors?.bairro)}
                              helperText={formState?.errors?.bairro?.message}
                              placeholder={"Bairro"}
                              type="text"
                              InputLabelProps={{
                                shrink: value ? true : false,
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <p
                          style={{
                            fontWeight: theme.fonte.bold.bold,
                            fontFamily: theme.fonte.font.fonte_1,
                            color: theme.cor.cor_preto_300,
                            padding: 0,
                            margin: 0,
                            paddingBottom: ".5rem",
                            paddingTop: ".5rem",
                          }}
                        >
                          Complemento
                        </p>
                        <Controller
                          name="complemento"
                          control={control}
                          render={({ field: { ref, value, ...field } }) => (
                            <TextField
                              {...field}
                              inputRef={ref}
                              value={value || ""}
                              size="small"
                              fullWidth
                              disabled={editar}
                              sx={{
                                ".MuiOutlinedInput-input": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label": {
                                  color: theme.cor.cor_preto_300,
                                },
                                "& label.Mui-focused": {
                                  color: theme.cor.cor_principal_500,
                                },
                                "& .MuiOutlinedInput-root": {
                                  "&.Mui-focused fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                  "& fieldset": {
                                    borderColor: theme.cor.cor_preto_100,
                                  },
                                  "&:hover fieldset": {
                                    borderColor: theme.cor.cor_principal_500,
                                  },
                                },
                              }}
                              error={Boolean(formState.errors?.complemento)}
                              helperText={
                                formState?.errors?.complemento?.message
                              }
                              placeholder={"Quadra..."}
                              type="text"
                              InputLabelProps={{
                                shrink: value ? true : false,
                              }}
                            />
                          )}
                        />
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{ paddingTop: "1.5rem" }}
                      ></Grid>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                      >
                        <Grid item>
                          <LoadingButton
                            loading={configuracao?.loading}
                            loadingPosition="end"
                            style={{
                              color: theme.cor.cor_branco_100,
                              fontFamily: theme.fonte.font.fonte_1,
                              fontWeight: theme.fonte.bold.medio,
                              background: theme.cor.cor_principal_500,
                              marginTop: ".3rem",
                              paddingLeft: "4.5rem",
                              paddingRight: "4.5rem",
                              padding: "1rem",
                            }}
                            size="small"
                            type="submit"
                          >
                            {editar ? "Editar" : "Cadastrar"}
                          </LoadingButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default CadastroPessoa;

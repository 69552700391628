import AssignmentIcon from "@mui/icons-material/Assignment";
import CloseIcon from "@mui/icons-material/Close";
import HttpsIcon from "@mui/icons-material/Https";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import SchoolIcon from "@mui/icons-material/School";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemContent from "@mui/joy/ListItemContent";
import ListItemDecorator from "@mui/joy/ListItemDecorator";
import Typography from "@mui/joy/Typography";
import { LoadingButton } from "@mui/lab";
import {
  Avatar,
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  LinearProgress,
  Link,
} from "@mui/material";
import { LocalizationProvider, PickersDay } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTheme } from "styled-components";
import Ret04 from "../../img/Group 125.png";
import Boneco from "../../img/bonecoOi.png";
import LogoThumbnailIPS from "../../img/thumbnail/logo-ips.jpeg";
import { getComprarContrato, getContratoIps, hexToRgb } from "../../types";
import api from "../axios/axios";
import Carregar, { Loading } from "../carregar";
import Erro from "../erro";
import CardMenu, { MenuPhone } from "../menu";
import MenuSuperior from "../menuSuperior";
import { mobile, StyledDateCalendar } from "../theme";
import BoasVindas from "./boas_vindas";

const Cards = ({ aula, decodedToken }: any) => {
  const theme = useTheme();
  return (
    <Link
      style={{ textDecoration: "none", cursor: "pointer" }}
      href={"/aulas/" + aula?.id}
    >
      <Grid
        style={{ width: "100%" }}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        spacing={1}
        sx={{
          padding: ".5rem",
          background: theme.cor.cor_branco_100,
          borderRadius: "1rem",
          borderColor: theme.cor.cor_branco_100,
          boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
          overflowWrap: "break-word",
          height: "100%",
        }}
      >
        <Grid
          item
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop: 0,
            marginTop: 0,
          }}
        >
          <Grid item style={{ height: "100%" }}>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <Grid
                item
                style={{
                  height: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {getContratoIps(decodedToken) ? (
                  <img
                    src={LogoThumbnailIPS}
                    style={{
                      objectFit: "cover",
                    }}
                    width={80}
                    height={60}
                  />
                ) : (
                  <iframe
                    src={aula?.link}
                    style={{
                      border: "0rem",
                      pointerEvents: "none",
                      paddingBottom: ".5rem",
                    }}
                    width={80}
                    height={60}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={true} paddingRight={"1rem"}>
          <Grid
            container
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
          >
            <Grid item>
              <span
                style={{
                  color: theme.cor.cor_verde_500,
                  fontFamily: theme.fonte.font.fonte_1,
                  fontSize: "12px",
                  fontWeight: theme.fonte.bold.semi_bold,
                  overflowWrap: "break-word",
                }}
              >
                Dia {moment(aula?.timestamp).format("DD/MM/YY")} - {aula?.nome}
              </span>
            </Grid>
            <Grid item>
              <p
                style={{
                  textAlign: "justify",
                  fontFamily: theme.fonte.font.fonte_1,
                  fontSize: "10px",
                  color: theme.cor.cor_preto_300,
                  overflowWrap: "break-word",
                }}
              >
                {aula?.descricao}
              </p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Link>
  );
};

const Recados = ({
  recados,
  setRecados,
  setConfiguracao,
  configuracao,
}: any) => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [item, setItem] = useState<any>({});

  const handlePage = (page: number) => {
    setConfiguracao((antes: any) => {
      return { ...antes, carregarMais: true };
    });
    api
      .get(`/recado?page=` + page)
      .then((r2) => {
        setRecados((antes: any) => {
          return {
            ...antes,
            items: [...antes?.items, ...r2.data?.items],
            total: r2?.data?.total,
            page: r2?.data?.page,
          };
        });
      })
      .finally(() =>
        setConfiguracao((antes: any) => {
          return { ...antes, carregarMais: false };
        })
      );
  };

  return (
    <>
      <React.Fragment>
        <Dialog
          onClose={handleClose}
          open={open}
          PaperProps={{
            sx: {
              borderRadius: "1.5rem",
              minHeight: "50%",
              minWidth: "50%",
              background: theme.cor.cor_branco_100,
            },
          }}
          fullWidth
        >
          <DialogTitle sx={{ m: 0, p: 2 }} style={{ marginBottom: "1rem" }}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.cor.cor_preto_400,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>

          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{
              color: theme.cor.cor_preto_300,
              fontFamily: theme.fonte.font.fonte_1,
              fontSize: "18px",
              fontWeight: theme.fonte.bold.semi_bold,
              marginTop: ".5rem",
              borderTopLeftRadius: ".5rem",
              borderTopRightRadius: ".5rem",
              padding: "2rem",
            }}
          >
            <Grid item>
              <p style={{ textAlign: "center" }}>{item?.titulo}</p>
            </Grid>
            <Grid item>
              <p style={{ textAlign: "center" }}>
                {moment(item?.timestamp).format("DD/MM/YYYY HH:mm")}
              </p>
            </Grid>
          </Grid>

          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            style={{
              color: theme.cor.cor_preto_300,
              fontFamily: theme.fonte.font.fonte_1,
              fontSize: "18px",

              paddingTop: "0rem",
              borderTopLeftRadius: ".5rem",
              borderTopRightRadius: ".5rem",
              padding: "2rem",
            }}
          >
            <Grid item style={{ height: "auto", width: "100%" }}>
              <p style={{ textAlign: "center" }}>{item?.mensagem}</p>
            </Grid>
          </Grid>
        </Dialog>
      </React.Fragment>
      <Typography
        id="ellipsis-list-demo"
        level="body-xs"
        textTransform="uppercase"
        style={{
          fontFamily: theme.fonte.font.fonte_1,
          textTransform: "capitalize",
          fontWeight: theme.fonte.bold.semi_bold,
          fontSize: "24px",
        }}
      >
        <span
          style={{
            fontFamily: theme.fonte.font.fonte_1,
            color: theme.cor.cor_preto_200,
          }}
        >
          Recados
        </span>
      </Typography>
      <List
        aria-labelledby="ellipsis-list-demo"
        sx={{ "--ListItemDecorator-size": "56px", marginLeft: "-1rem" }}
      >
        {recados?.items.length > 0 ? (
          recados?.items?.map((item: any, index: any) => (
            <ListItem
              key={item?.id}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setItem(item);
                setOpen(true);
              }}
            >
              <ListItemDecorator>
                <Avatar
                  style={{
                    backgroundColor: theme.cor.cor_branco_300,
                    padding: ".3rem",
                  }}
                >
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.823 7.78025L6.62622 6.39998L7.57128 2.92264C7.64757 2.64889 7.82894 2.41637 8.07587 2.27573C8.32281 2.13508 8.61531 2.09769 8.88968 2.17171L12.0044 3.00798C12.1401 3.04389 12.2674 3.10625 12.379 3.19147C12.4905 3.27669 12.5842 3.3831 12.6545 3.5046C12.7249 3.62609 12.7706 3.76028 12.789 3.89947C12.8074 4.03866 12.7981 4.18011 12.7617 4.31571L11.823 7.78025ZM2.18035 23.473C2.12115 23.2817 2.11745 23.0776 2.16968 22.8842L6.05022 8.53545L11.2449 9.91571L7.36435 24.2645C7.31238 24.4584 7.20662 24.6337 7.05928 24.7701L3.95102 27.6437C3.91086 27.6802 3.86218 27.7061 3.80943 27.7189C3.75669 27.7317 3.70157 27.7311 3.64913 27.7171C3.59669 27.7031 3.54861 27.6761 3.50929 27.6387C3.46997 27.6013 3.44067 27.5546 3.42408 27.5029L2.18035 23.473ZM21.3334 10.6666C16.5334 10.6666 14.9334 9.06665 14.9334 4.26664C19.7334 4.26664 21.3334 5.86665 21.3334 10.6666ZM18.3468 27.6992C15.4241 28.1472 11.678 24.0597 10.8374 19.5925L12.734 12.5866C13.5852 11.84 14.6604 11.4496 15.7612 11.4496C16.9543 11.4447 18.1198 11.8091 19.0977 12.4928C19.4419 12.7238 19.848 12.8454 20.2626 12.8416C20.6772 12.8378 21.0809 12.7088 21.4209 12.4714C22.3218 11.804 23.4143 11.4456 24.5356 11.4496C26.1782 11.4496 28.0406 12.4928 28.9089 14.0117C32.1857 19.7632 26.3617 28.3242 22.2017 27.7013C22.0111 27.6641 21.8275 27.5972 21.6577 27.5029C21.2279 27.2826 20.7519 27.1677 20.2689 27.1677C19.7859 27.1677 19.3099 27.2826 18.8801 27.5029C18.7135 27.5954 18.5336 27.6617 18.3468 27.6992Z"
                      fill={theme.cor.cor_preto_400}
                    />
                  </svg>
                </Avatar>
              </ListItemDecorator>
              <ListItemContent>
                <Grid
                  container
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                >
                  <Grid item>
                    <Grid
                      item
                      style={{
                        fontWeight: theme.fonte.bold.semi_bold,
                        fontFamily: theme.fonte.font.fonte_1,
                        color: theme.cor.cor_preto_400,
                      }}
                    >
                      {item?.titulo}
                    </Grid>
                    <Grid item>
                      <span
                        style={{
                          fontFamily: theme.fonte.font.fonte_1,
                          color: theme.cor.cor_preto_400,
                        }}
                      >
                        {item?.mensagem}
                      </span>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    style={{
                      fontFamily: theme.fonte.font.fonte_1,
                      fontSize: "13px",
                    }}
                  >
                    <span
                      style={{
                        fontFamily: theme.fonte.font.fonte_1,
                        color: theme.cor.cor_preto_400,
                      }}
                    >
                      {moment(item?.timestamp).format("DD/MM/YYYY HH:mm")}
                    </span>
                  </Grid>
                </Grid>
              </ListItemContent>
            </ListItem>
          ))
        ) : (
          <ListItem key={-1} style={{ width: "100%" }}>
            <ListItemDecorator style={{ width: "100%" }}>
              <p
                style={{
                  textAlign: "center",
                  width: "100%",
                  color: theme.cor.cor_preto_300,
                  fontFamily: theme.fonte.font.fonte_1,
                  marginBottom: "-1rem",
                  marginTop: "-.5rem",
                }}
              >
                Nenhum recado!
              </p>
            </ListItemDecorator>
          </ListItem>
        )}

        {recados?.items?.length < recados?.total ? (
          <ListItem key={-2} style={{ width: "100%" }}>
            <ListItemDecorator style={{ width: "100%" }}>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Grid item>
                  <LoadingButton
                    variant="outlined"
                    sx={{
                      background: theme.cor.cor_branco_100,
                      color: theme.cor.cor_preto_300,
                      fontFamily: theme.fonte.font.fonte_1,
                      borderColor: theme.cor.cor_preto_300,
                      marginTop: "1rem",
                      marginBottom: ".5rem",
                      "&:hover": {
                        borderColor: theme.cor.cor_principal_500,
                      },
                    }}
                    loading={configuracao?.carregarMais}
                    onClick={(e) => handlePage(recados.page + 1)}
                  >
                    Carregar mais
                  </LoadingButton>
                </Grid>
              </Grid>
            </ListItemDecorator>
          </ListItem>
        ) : (
          ""
        )}
      </List>
    </>
  );
};

const Home = ({ decodedToken }: any) => {
  const theme = useTheme();
  const deParaData: any = {
    Do: "D",
    "2ª": "S",
    "3ª": "T",
    "4ª": "Q",
    "5ª": "Q",
    "6ª": "S",
    Sá: "S",
  };
  const navigate = useNavigate();
  const [recados, setRecados] = useState<any>({
    items: [],
    total: 0,
  });
  const [aulas, setAulas] = useState<any>({
    items: [],
    total: 0,
  });

  const [disciplinas, setDisciplinas] = useState<any>({
    items: [],
    total: 0,
  });

  const handlePageDisciplina = (page: number) => {
    setConfiguracao((antes: any) => {
      return { ...antes, progresso: true };
    });
    api
      .get(`/turma/progresso?page=` + page)
      .then((r) => {
        setDisciplinas(r.data);
      })
      .finally(() =>
        setConfiguracao((antes: any) => {
          return { ...antes, progresso: false };
        })
      );
  };

  const [configuracao, setConfiguracao] = useState<any>({
    loading: true,
    erro: false,
    carregarMais: false,
    progresso: false,
  });

  useEffect(() => {
    Promise.all([
      api.get(`/recado`),
      api.get(`/aula`),
      api.get("turma/progresso"),
    ])
      .then(([r2, r1, r3]: any) => {
        setRecados(r2.data);
        setAulas(r1.data);
        setDisciplinas(r3.data);
      })
      .then((e: any) =>
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            loading: false,
          };
        })
      )
      .catch((e) => {
        setConfiguracao((antes: any) => {
          return {
            ...antes,
            loading: false,
          };
        });
        console.log(e);
      });
  }, []);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (configuracao?.erro) return <Erro />;

  if (configuracao?.loading) return <Carregar />;

  return (
    <>
      <Grid
        container
        direction="row"
        style={{ background: theme.cor.cor_fundo }}
      >
        <CardMenu
          menuSelecao={1}
          ativo={mobile(windowSize)}
          decodedToken={decodedToken}
        />

        <Grid
          item
          xs={mobile(windowSize) ? true : false}
          style={{ height: "100vh", overflow: "auto" }}
          width={"100%"}
        >
          <MenuPhone
            decodedToken={decodedToken}
            ativo={mobile(windowSize) ? true : false}
          />
          <Grid
            item
            xs={12}
            style={{
              background: theme.cor.cor_branco_100,
              padding: 0,
              margin: 0,
            }}
          >
            <MenuSuperior
              ativo={mobile(windowSize)}
              texto={"Olá, " + decodedToken?.given_name}
            />
          </Grid>

          <Grid
            item
            xs={12}
            padding={3}
            paddingRight={2}
            marginTop={mobile(windowSize) ? "" : "4rem"}
          >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              spacing={2}
            >
              <Grid item xs>
                <Grid item xs={true}>
                  <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="flex-start"
                    spacing={2}
                  >
                    <Grid item xs={12} mb=".5rem">
                      <Grid item>
                        <BoasVindas
                          theme={theme}
                          Ret04={Ret04}
                          Boneco={Boneco}
                          decodedToken={decodedToken}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs>
                      <Grid
                        container
                        sx={{
                          flexDirection: {
                            xs: "column",
                            sm: "column",
                            md: "column",
                            lg: "column",
                            xl: "row",
                          },
                        }}
                        justifyContent="center"
                        alignItems="stretch"
                        spacing={2}
                      >
                        {getContratoIps(decodedToken) ? (
                          <>
                            <Grid item xs>
                              <Grid
                                item
                                style={{
                                  background: theme.cor.cor_branco_100,
                                  borderRadius: "1rem",
                                  marginBottom: "0px",
                                  height: "100%",
                                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                }}
                                padding={"1rem"}
                              >
                                <Grid item style={{ marginTop: ".5rem" }}>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <Grid item style={{ marginTop: "-1.2rem" }}>
                                      <svg
                                        width="37"
                                        height="38"
                                        viewBox="0 0 37 38"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M25.3079 14.9765C22.3294 9.01457 12.2941 10.3265 10.8606 16.876C9.99574 20.828 13.6426 23.5937 14.1137 27.2946H22.474C22.7199 26.305 23.22 25.1281 23.5664 24.4047C24.2863 22.9018 25.3045 21.9195 25.6911 20.2429C26.0589 18.6475 26.0163 16.3945 25.3079 14.9765Z"
                                          stroke={theme.cor.cor_preto_300}
                                          stroke-width="2"
                                          stroke-miterlimit="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M13.9603 32.3382C13.9579 31.1962 13.9019 30.0543 13.9217 28.9126C13.9296 28.4606 13.8523 27.9076 14.2436 27.5915C14.8149 27.1302 15.6648 27.063 16.3738 27.0459C17.7027 27.0142 19.0418 27.0254 20.3721 27.0281C20.9779 27.0291 21.8821 26.9239 22.4281 27.225C22.9479 27.511 23.0794 28.2906 23.1379 28.8172C23.2499 29.8263 23.5482 32.0795 22.6916 32.8606C22.152 33.3527 21.3104 33.4556 20.6195 33.5407C18.7866 33.7662 13.9631 33.7214 13.9603 32.3382Z"
                                          stroke={theme.cor.cor_preto_300}
                                          stroke-width="2"
                                          stroke-miterlimit="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M18.5266 33.952C18.5416 34.5735 18.5489 35.1983 18.5 35.8188"
                                          stroke={theme.cor.cor_preto_300}
                                          stroke-width="2.5"
                                          stroke-miterlimit="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M4.3501 24.1495C5.00219 23.8053 5.68321 23.5222 6.34228 23.193"
                                          stroke={theme.cor.cor_preto_300}
                                          stroke-width="5"
                                          stroke-miterlimit="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M4.3501 10.7737C5.07801 11.2359 5.77432 11.7467 6.50389 12.205"
                                          stroke={theme.cor.cor_preto_300}
                                          stroke-width="5"
                                          stroke-miterlimit="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M18.718 5.29514C18.6924 4.37968 18.698 3.47079 18.7273 2.55566"
                                          stroke={theme.cor.cor_preto_300}
                                          stroke-width="5"
                                          stroke-miterlimit="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M29.9863 11.5311C30.9001 10.9262 31.8412 10.3579 32.7706 9.77734"
                                          stroke={theme.cor.cor_preto_300}
                                          stroke-width="5"
                                          stroke-miterlimit="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M28.3918 24.0056C29.0965 24.6371 29.8041 25.2613 30.4861 25.9173"
                                          stroke={theme.cor.cor_preto_300}
                                          stroke-width="5"
                                          stroke-miterlimit="1.5"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                    </Grid>
                                    <Grid item xs>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          fontFamily: theme.fonte.font.fonte_1,
                                          color: theme.cor.cor_preto_300,
                                          fontWeight: theme.fonte.bold.bold,
                                          fontSize: "20px",
                                        }}
                                      >
                                        Aproveite melhor o curso!
                                      </p>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item style={{ marginTop: "1rem" }}>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <Grid item style={{ marginTop: "-1.2rem" }}>
                                      <svg
                                        width="26"
                                        height="28"
                                        viewBox="0 0 26 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M0.0122459 2.06973C-0.144316 5.00527 1.22562 12.2658 2.67382 15.1427C4.21988 18.2152 5.9812 19.0567 10.8933 19.0567H13.3201C13.692 21.7183 14.1225 24.6147 14.1225 25.4562C14.1225 27.2567 16.6666 27.8046 17.3516 26.1607C18.5845 23.2056 21.7547 19.6438 24.5337 18.0978C26.6669 16.904 26.6081 16.7866 23.4573 15.8472C20.8545 15.0644 19.3867 14.3794 17.3123 12.9704C14.6898 11.2091 12.9286 10.8177 12.7133 11.9723C12.5959 12.5203 13.2025 17.7064 13.2025 17.7064H11.0695C6.21602 17.7064 5.21798 16.1212 3.28052 5.37708C2.90869 3.3222 2.61509 2.01102 2.26283 1.28692C1.69529 0.13227 0.110097 0.13227 0.0122459 2.06973Z"
                                          fill={theme.cor.cor_preto_300}
                                        />
                                      </svg>
                                    </Grid>
                                    <Grid item xs={true}>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          fontFamily: theme.fonte.font.fonte_1,
                                          color: theme.cor.cor_preto_300,
                                        }}
                                      >
                                        Separe 1 hora do seu dia para se dedicar
                                        aos seus estudos
                                      </p>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item style={{ marginTop: "1rem" }}>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <Grid item style={{ marginTop: "-1.2rem" }}>
                                      <svg
                                        width="26"
                                        height="28"
                                        viewBox="0 0 26 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M0.0122459 2.06973C-0.144316 5.00527 1.22562 12.2658 2.67382 15.1427C4.21988 18.2152 5.9812 19.0567 10.8933 19.0567H13.3201C13.692 21.7183 14.1225 24.6147 14.1225 25.4562C14.1225 27.2567 16.6666 27.8046 17.3516 26.1607C18.5845 23.2056 21.7547 19.6438 24.5337 18.0978C26.6669 16.904 26.6081 16.7866 23.4573 15.8472C20.8545 15.0644 19.3867 14.3794 17.3123 12.9704C14.6898 11.2091 12.9286 10.8177 12.7133 11.9723C12.5959 12.5203 13.2025 17.7064 13.2025 17.7064H11.0695C6.21602 17.7064 5.21798 16.1212 3.28052 5.37708C2.90869 3.3222 2.61509 2.01102 2.26283 1.28692C1.69529 0.13227 0.110097 0.13227 0.0122459 2.06973Z"
                                          fill={theme.cor.cor_preto_300}
                                        />
                                      </svg>
                                    </Grid>
                                    <Grid item xs={true}>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          fontFamily: theme.fonte.font.fonte_1,
                                          color: theme.cor.cor_preto_300,
                                        }}
                                      >
                                        Participe da comunidade: Interaja com
                                        outros alunos e professores
                                      </p>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item style={{ marginTop: "1rem" }}>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <Grid item style={{ marginTop: "-1.2rem" }}>
                                      <svg
                                        width="26"
                                        height="28"
                                        viewBox="0 0 26 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M0.0122459 2.06973C-0.144316 5.00527 1.22562 12.2658 2.67382 15.1427C4.21988 18.2152 5.9812 19.0567 10.8933 19.0567H13.3201C13.692 21.7183 14.1225 24.6147 14.1225 25.4562C14.1225 27.2567 16.6666 27.8046 17.3516 26.1607C18.5845 23.2056 21.7547 19.6438 24.5337 18.0978C26.6669 16.904 26.6081 16.7866 23.4573 15.8472C20.8545 15.0644 19.3867 14.3794 17.3123 12.9704C14.6898 11.2091 12.9286 10.8177 12.7133 11.9723C12.5959 12.5203 13.2025 17.7064 13.2025 17.7064H11.0695C6.21602 17.7064 5.21798 16.1212 3.28052 5.37708C2.90869 3.3222 2.61509 2.01102 2.26283 1.28692C1.69529 0.13227 0.110097 0.13227 0.0122459 2.06973Z"
                                          fill={theme.cor.cor_preto_300}
                                        />
                                      </svg>
                                    </Grid>
                                    <Grid item xs={true}>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          fontFamily: theme.fonte.font.fonte_1,
                                          color: theme.cor.cor_preto_300,
                                        }}
                                      >
                                        Faça todas as atividades e exercícios
                                        propostos durante o curso
                                      </p>
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid item style={{ marginTop: "1rem" }}>
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="center"
                                    spacing={1}
                                  >
                                    <Grid item style={{ marginTop: "-1.2rem" }}>
                                      <svg
                                        width="26"
                                        height="28"
                                        viewBox="0 0 26 28"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          clip-rule="evenodd"
                                          d="M0.0122459 2.06973C-0.144316 5.00527 1.22562 12.2658 2.67382 15.1427C4.21988 18.2152 5.9812 19.0567 10.8933 19.0567H13.3201C13.692 21.7183 14.1225 24.6147 14.1225 25.4562C14.1225 27.2567 16.6666 27.8046 17.3516 26.1607C18.5845 23.2056 21.7547 19.6438 24.5337 18.0978C26.6669 16.904 26.6081 16.7866 23.4573 15.8472C20.8545 15.0644 19.3867 14.3794 17.3123 12.9704C14.6898 11.2091 12.9286 10.8177 12.7133 11.9723C12.5959 12.5203 13.2025 17.7064 13.2025 17.7064H11.0695C6.21602 17.7064 5.21798 16.1212 3.28052 5.37708C2.90869 3.3222 2.61509 2.01102 2.26283 1.28692C1.69529 0.13227 0.110097 0.13227 0.0122459 2.06973Z"
                                          fill={theme.cor.cor_preto_300}
                                        />
                                      </svg>
                                    </Grid>
                                    <Grid item xs={true}>
                                      <p
                                        style={{
                                          marginTop: 0,
                                          fontFamily: theme.fonte.font.fonte_1,
                                          color: theme.cor.cor_preto_300,
                                        }}
                                      >
                                        Leia os Recados: Mantenha-se atualizado
                                        com as novidades
                                      </p>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                            {disciplinas?.items?.length > 0 ? (
                              <Grid item>
                                <Grid
                                  container
                                  height={"100%"}
                                  justifyContent="flex-start"
                                  alignItems="stretch"
                                >
                                  <Grid
                                    container
                                    justifyContent="flex-start"
                                    alignItems="stretch"
                                    spacing={2}
                                    sx={{
                                      paddingBottom: {
                                        xs: "1.5rem",
                                        sm: "1.5rem",
                                        md: "1.5rem",
                                        lg: "1.5rem",
                                        xl: 0,
                                      },
                                    }}
                                  >
                                    <Grid
                                      item
                                      height={"11%"}
                                      sx={{
                                        paddingBottom: {
                                          xs: "1.5rem",
                                          sm: "1.5rem",
                                          md: "1.5rem",
                                          lg: "1.5rem",
                                          xl: 0,
                                        },
                                      }}
                                    >
                                      <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                      >
                                        <Grid item>
                                          <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="center"
                                          >
                                            {/* {(disciplinas?.items?.length % 2 ===
                                            0
                                              ? disciplinas?.items?.length - 1
                                              : disciplinas?.items?.length) *
                                              disciplinas?.size <
                                            disciplinas?.total ? (
                                              <Grid
                                                item
                                                style={{
                                                  marginTop: "-.7rem",
                                                  color:
                                                    theme.cor.cor_preto_300,
                                                }}
                                              >
                                                <div
                                                  onClick={() => {
                                                    if (
                                                      configuracao?.progresso ===
                                                      false
                                                    ) {
                                                      handlePageDisciplina(
                                                        disciplinas?.page - 1 >
                                                          0
                                                          ? disciplinas?.page -
                                                              1
                                                          : Math.ceil(
                                                              disciplinas?.total /
                                                                disciplinas
                                                                  ?.items
                                                                  ?.length
                                                            )
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <NavigateBeforeIcon
                                                    style={{
                                                      width: "24px",
                                                      height: "24px",
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </div>
                                              </Grid>
                                            ) : (
                                              ""
                                            )} */}
                                            <Grid item>
                                              <p
                                                style={{
                                                  fontFamily:
                                                    theme.fonte.font.fonte_1,
                                                  color:
                                                    theme.cor.cor_preto_300,
                                                  fontWeight:
                                                    theme.fonte.bold.semi_bold,
                                                  paddingTop: "0px",
                                                  marginTop: "0px",
                                                  paddingLeft: ".5rem",
                                                  fontSize: "22px",
                                                }}
                                              >
                                                Continuar assistindo
                                              </p>
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                        {/* {(disciplinas?.items?.length % 2 === 0
                                          ? disciplinas?.items?.length - 1
                                          : disciplinas?.items?.length) *
                                          disciplinas?.size <
                                        disciplinas?.total ? (
                                          <Grid
                                            item
                                            style={{
                                              marginTop: "-.7rem",
                                              color: theme.cor.cor_preto_300,
                                            }}
                                          >
                                            <div
                                              onClick={() => {
                                                if (
                                                  configuracao?.progresso ===
                                                  false
                                                ) {
                                                  handlePageDisciplina(
                                                    disciplinas?.page * 2 >=
                                                      disciplinas?.total
                                                      ? disciplinas?.page -
                                                          1 ===
                                                        0
                                                        ? 1
                                                        : 1
                                                      : disciplinas?.page + 1
                                                  );
                                                }
                                              }}
                                            >
                                              <NavigateNextIcon
                                                style={{
                                                  width: "24px",
                                                  height: "24px",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </div>
                                          </Grid>
                                        ) : (
                                          ""
                                        )} */}
                                      </Grid>
                                    </Grid>

                                    {disciplinas?.items?.map(
                                      (disciplina: any) => (
                                        <>
                                          <Grid
                                            item
                                            xs={12}
                                            height={
                                              disciplinas?.items?.length % 2 !==
                                              0
                                                ? "89%"
                                                : "44.5%"
                                            }
                                          >
                                            <Grid
                                              container
                                              xs={12}
                                              key={disciplina?.id}
                                              style={{
                                                background:
                                                  theme.cor.cor_branco_100,
                                                padding: "1rem",
                                                paddingTop: ".1px",
                                                borderRadius: "1.5rem",
                                                boxShadow:
                                                  "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                              }}
                                              minHeight={"100%"}
                                              direction={"column"}
                                            >
                                              {configuracao?.progresso ===
                                              false ? (
                                                <>
                                                  <Grid
                                                    item
                                                    xs
                                                    mt={"1rem"}
                                                    style={{
                                                      paddingTop: "1rem",
                                                      paddingBottom: "1rem",
                                                      background:
                                                        theme.cor.cor_fundo,
                                                      borderRadius: "2.5rem",
                                                      fontFamily:
                                                        theme.fonte.font
                                                          .fonte_1,
                                                      color:
                                                        theme.cor.cor_preto_300,

                                                      width: "100%",
                                                      marginBottom: "-.3rem",
                                                      display: "flex",
                                                      justifyContent: "center",
                                                      alignItems: "center",
                                                      textAlign: "center",
                                                    }}
                                                  >
                                                    {disciplina?.nome}
                                                  </Grid>
                                                  {/* <p
                                                  style={{
                                                    borderRadius: ".5rem",
                                                    fontFamily:
                                                      theme.fonte.font.fonte_1,
                                                    color:
                                                      theme.cor.cor_preto_200,
                                                    padding: "0px",
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    alignItems: "end",
                                                    textAlign: "end",
                                                    fontSize: "12px",
                                                    marginBottom: ".2rem",
                                                  }}
                                                >
                                                  {`${disciplina?.concluido}/${disciplina?.total}`}
                                                </p> */}
                                                  <Grid
                                                    item
                                                    height={"8px"}
                                                    mt=".8rem"
                                                  >
                                                    <LinearProgress
                                                      sx={{
                                                        minHeight: "100%",
                                                        "& .MuiLinearProgress-bar":
                                                          {
                                                            background:
                                                              theme.cor
                                                                .cor_principal_400 /* `linear-gradient(45deg, rgba(${hexToRgb(
                                                          theme.cor
                                                            .cor_principal_500
                                                        )}, 1) 0%, rgba(${hexToRgb(
                                                          theme.cor
                                                            .cor_principal_400
                                                        )}, 1) 100%)`,*/,
                                                            borderRadius:
                                                              "1rem",
                                                          },

                                                        borderRadius: 5,
                                                        background:
                                                          theme.cor
                                                            .cor_branco_300,
                                                      }}
                                                      variant="determinate"
                                                      value={
                                                        disciplina?.total === 0
                                                          ? 0
                                                          : (disciplina?.concluido *
                                                              100) /
                                                            disciplina?.total
                                                      }
                                                    />
                                                  </Grid>
                                                </>
                                              ) : (
                                                <div
                                                  style={{ height: "112.5px" }}
                                                >
                                                  <Loading />
                                                </div>
                                              )}
                                            </Grid>
                                          </Grid>
                                        </>
                                      )
                                    )}
                                  </Grid>
                                </Grid>
                              </Grid>
                            ) : (
                              ""
                            )}
                          </>
                        ) : (
                          <>
                            <Grid item xs>
                              <Grid
                                item
                                style={{
                                  padding: "0",
                                  background: theme.cor.cor_branco_100,
                                  borderRadius: "1rem",
                                  marginBottom: "0px",
                                  height: "100%",
                                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                }}
                              >
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                  adapterLocale="pt-br"
                                  dateFormats={{ monthAndYear: "MMMM, YYYY" }}
                                >
                                  <StyledDateCalendar
                                    dayOfWeekFormatter={(
                                      _day: any,
                                      weekday: any
                                    ): any =>
                                      `${deParaData[weekday?.format("dd")]}`
                                    }
                                    slots={{
                                      day: (slotProps: any) => {
                                        return (
                                          <PickersDay
                                            style={{
                                              backgroundColor: dayjs(
                                                slotProps?.day
                                              ).isAfter(dayjs())
                                                ? decodedToken.contrato ===
                                                  "ips"
                                                  ? theme.cor.cor_branco_500
                                                  : theme.cor.cor_principal_500
                                                : theme.cor
                                                    .cor_verde_secundaria_400,
                                              color: theme.cor.cor_branco_100,
                                              fontWeight:
                                                theme.fonte.bold.semi_bold,
                                              cursor: dayjs(
                                                slotProps?.day
                                              ).isAfter(dayjs())
                                                ? "default"
                                                : "pointer",
                                              fontFamily:
                                                theme.fonte.font.fonte_1,
                                              borderRadius: 10,
                                            }}
                                            day={slotProps?.day?.get("date")}
                                            onDaySelect={(day: any) => {
                                              if (
                                                dayjs(slotProps?.day).isAfter(
                                                  dayjs()
                                                )
                                              ) {
                                              } else {
                                                const inicio = dayjs(
                                                  slotProps?.day
                                                ).format("DD-MM-YYYY");

                                                navigate(
                                                  `/aulas?inicio=${inicio}&fim=${inicio}`
                                                );
                                              }
                                            }}
                                            outsideCurrentMonth={false}
                                            isFirstVisibleCell={false}
                                            isLastVisibleCell={false}
                                          >
                                            {dayjs(slotProps?.day).isAfter(
                                              dayjs()
                                            ) ? (
                                              <HttpsIcon />
                                            ) : (
                                              slotProps?.day?.get("date")
                                            )}
                                          </PickersDay>
                                        );
                                      },
                                    }}
                                  />
                                </LocalizationProvider>
                              </Grid>
                            </Grid>

                            <Grid item xs>
                              <Grid
                                item
                                style={{
                                  background: theme.cor.cor_branco_100,
                                  borderRadius: "1rem",
                                  paddingBottom: "1.5rem",
                                  height: "100%",
                                  minWidth: "239px",
                                  boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                }}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  justifyContent="center"
                                  alignItems="flex-start"
                                  paddingRight={"4rem"}
                                  style={{ paddingLeft: "1rem" }}
                                  minWidth={220}
                                >
                                  <Grid item>
                                    <p
                                      style={{
                                        fontWeight: theme.fonte.bold.semi_bold,
                                        fontFamily: theme.fonte.font.fonte_1,
                                        fontSize: "18px",
                                        color: theme.cor.cor_preto_200,
                                      }}
                                    >
                                      Acesso Rápido
                                    </p>

                                    {/* <Grid
                                  item
                                  style={{
                                    marginLeft: "1rem",
                                    marginRight: "-3rem",
                                  }}
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                  >
                                    <Grid item>
                                      <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        direction="column"
                                        style={{
                                          background: theme.cor.cor_branco_300,
                                          height: "40px",
                                          width: "40px",
                                          borderRadius: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                          <PermIdentityIcon
                                            style={{
                                              color: theme.cor.cor_preto_300,
                                              height: "100%",
                                              width: "100%",
                                              padding: ".3rem",
                                            }}
                                          />
                                       
                                      </Grid>
                                    </Grid>
                                    <p
                                      style={{
                                        marginLeft: "1rem",
                                        fontWeight: theme.fonte.bold.semi_bold,
                                        fontFamily: theme.fonte.font.fonte_1,
                                        color: theme.cor.cor_principal_400,
                                      }}
                                    >
                                      DASHBOARD
                                    </p>
                                  </Grid>
                                </Grid>
                                <Grid
                                  item
                                  style={{
                                    marginLeft: "1rem",
                                    marginRight: "-3rem",
                                  }}
                                >
                                  <Grid
                                    container
                                    direction="row"
                                    justifyContent="flex-start"
                                    alignItems="flex-start"
                                  >
                                    <Grid item>
                                      <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center"
                                        direction="column"
                                        style={{
                                          background: theme.cor.cor_branco_300,
                                          height: "40px",
                                          width: "40px",
                                          borderRadius: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                          <ForumIcon
                                            style={{
                                              color: theme.cor.cor_preto_300,
                                            
                                              padding: ".5rem",
                                            }}
                                          />
                                       
                                      </Grid>
                                    </Grid>
                                    <p
                                      style={{
                                        marginLeft: "1rem",
                                        fontWeight: theme.fonte.bold.semi_bold,
                                        fontFamily: theme.fonte.font.fonte_1,
                                        color: theme.cor.cor_principal_400,
                                      }}
                                    >
                                      MENSAGEM
                                    </p>
                                  </Grid>
                                </Grid> */}
                                    <Grid
                                      item
                                      style={{
                                        marginLeft: "1rem",
                                        marginRight: "-3rem",
                                      }}
                                    >
                                      <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                      >
                                        <Grid item>
                                          <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            direction="column"
                                            style={{
                                              background:
                                                theme.cor.cor_branco_300,
                                              height: "40px",
                                              width: "40px",
                                              borderRadius: "100%",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <VideoLibraryIcon
                                              style={{
                                                color: theme.cor.cor_preto_300,
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                        <Link
                                          href="/videoteca"
                                          style={{
                                            cursor: "pointer",
                                            textDecoration: "none",
                                          }}
                                        >
                                          <p
                                            style={{
                                              marginLeft: "1rem",
                                              fontWeight:
                                                theme.fonte.bold.semi_bold,
                                              fontFamily:
                                                theme.fonte.font.fonte_1,
                                              color:
                                                theme.cor.cor_principal_400,
                                            }}
                                          >
                                            VIDEOTECA
                                          </p>
                                        </Link>
                                      </Grid>
                                    </Grid>
                                    {getComprarContrato(
                                      decodedToken,
                                      "cesan"
                                    ) ? (
                                      <Grid
                                        item
                                        style={{
                                          marginLeft: "1rem",
                                          marginRight: "-3rem",
                                        }}
                                      >
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="flex-start"
                                          alignItems="flex-start"
                                        >
                                          <Grid item>
                                            <Grid
                                              container
                                              justifyContent="center"
                                              alignItems="center"
                                              direction="column"
                                              style={{
                                                background:
                                                  theme.cor.cor_branco_300,
                                                height: "40px",
                                                width: "40px",
                                                borderRadius: "100%",
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                              }}
                                            >
                                              <SchoolIcon
                                                style={{
                                                  color:
                                                    theme.cor.cor_preto_300,

                                                  padding: ".2rem",
                                                }}
                                              />
                                            </Grid>
                                          </Grid>
                                          <Link
                                            href="/trilhas"
                                            style={{
                                              cursor: "pointer",
                                              textDecoration: "none",
                                            }}
                                          >
                                            <p
                                              style={{
                                                marginLeft: "1rem",
                                                fontWeight:
                                                  theme.fonte.bold.semi_bold,
                                                fontFamily:
                                                  theme.fonte.font.fonte_1,
                                                color:
                                                  theme.cor.cor_principal_400,
                                              }}
                                            >
                                              TRILHAS
                                            </p>
                                          </Link>
                                        </Grid>
                                      </Grid>
                                    ) : (
                                      <>
                                        <Grid
                                          item
                                          style={{
                                            marginLeft: "1rem",
                                            marginRight: "-3rem",
                                          }}
                                        >
                                          <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                          >
                                            <Grid item>
                                              <Grid
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                                direction="column"
                                                style={{
                                                  background:
                                                    theme.cor.cor_branco_300,
                                                  height: "40px",
                                                  width: "40px",
                                                  borderRadius: "100%",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <AssignmentIcon
                                                  style={{
                                                    color:
                                                      theme.cor.cor_preto_300,

                                                    padding: ".2rem",
                                                  }}
                                                />
                                              </Grid>
                                            </Grid>
                                            <Link
                                              href="/aulas"
                                              style={{
                                                cursor: "pointer",
                                                textDecoration: "none",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  marginLeft: "1rem",
                                                  fontWeight:
                                                    theme.fonte.bold.semi_bold,
                                                  fontFamily:
                                                    theme.fonte.font.fonte_1,
                                                  color:
                                                    theme.cor.cor_principal_400,
                                                }}
                                              >
                                                AULAS
                                              </p>
                                            </Link>
                                          </Grid>
                                        </Grid>
                                        <Grid
                                          item
                                          style={{
                                            marginLeft: "1rem",
                                            marginRight: "-3rem",
                                          }}
                                        >
                                          <Grid
                                            container
                                            direction="row"
                                            justifyContent="flex-start"
                                            alignItems="flex-start"
                                          >
                                            <Grid item>
                                              <Grid
                                                container
                                                justifyContent="center"
                                                alignItems="center"
                                                direction="column"
                                                style={{
                                                  background:
                                                    theme.cor.cor_branco_300,
                                                  height: "40px",
                                                  width: "40px",
                                                  borderRadius: "100%",
                                                  display: "flex",
                                                  justifyContent: "center",
                                                  alignItems: "center",
                                                }}
                                              >
                                                <SchoolIcon
                                                  style={{
                                                    color:
                                                      theme.cor.cor_preto_300,

                                                    padding: ".2rem",
                                                  }}
                                                />
                                              </Grid>
                                            </Grid>
                                            <Link
                                              href="/trilhas"
                                              style={{
                                                cursor: "pointer",
                                                textDecoration: "none",
                                              }}
                                            >
                                              <p
                                                style={{
                                                  marginLeft: "1rem",
                                                  fontWeight:
                                                    theme.fonte.bold.semi_bold,
                                                  fontFamily:
                                                    theme.fonte.font.fonte_1,
                                                  color:
                                                    theme.cor.cor_principal_400,
                                                }}
                                              >
                                                TRILHAS
                                              </p>
                                            </Link>
                                          </Grid>
                                        </Grid>
                                      </>
                                    )}
                                    <Grid
                                      item
                                      style={{
                                        marginLeft: "1rem",
                                        marginRight: "-3rem",
                                      }}
                                    >
                                      <Grid
                                        container
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="flex-start"
                                      >
                                        <Grid item>
                                          <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            direction="column"
                                            style={{
                                              background:
                                                theme.cor.cor_branco_300,
                                              height: "40px",
                                              width: "40px",
                                              borderRadius: "100%",
                                              display: "flex",
                                              justifyContent: "center",
                                              alignItems: "center",
                                            }}
                                          >
                                            <SettingsApplicationsIcon
                                              style={{
                                                color: theme.cor.cor_preto_300,

                                                padding: ".3rem",
                                              }}
                                            />
                                          </Grid>
                                        </Grid>
                                        <Link
                                          href="/configuracoes"
                                          style={{
                                            cursor: "pointer",
                                            textDecoration: "none",
                                          }}
                                        >
                                          <p
                                            style={{
                                              marginLeft: "1rem",
                                              fontWeight:
                                                theme.fonte.bold.semi_bold,
                                              fontFamily:
                                                theme.fonte.font.fonte_1,
                                              color:
                                                theme.cor.cor_principal_400,
                                            }}
                                          >
                                            CONFIGURAÇÕES
                                          </p>
                                        </Link>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </>
                        )}
                        {!getContratoIps(decodedToken) &&
                        disciplinas?.items?.length > 0 ? (
                          <Grid item xs style={{ minWidth: "180px" }}>
                            <Grid item height={"100%"}>
                              <Grid
                                container
                                height={"100%"}
                                justifyContent="flex-start"
                                alignItems="stretch"
                              >
                                <Grid
                                  container
                                  justifyContent="flex-start"
                                  alignItems="stretch"
                                  spacing={2}
                                  sx={{
                                    paddingBottom: {
                                      xs: "1.5rem",
                                      sm: "1.5rem",
                                      md: "1.5rem",
                                      lg: "1.5rem",
                                      xl: 0,
                                    },
                                  }}
                                >
                                  <Grid
                                    item
                                    height={"11%"}
                                    sx={{
                                      paddingBottom: {
                                        xs: "1.5rem",
                                        sm: "1.5rem",
                                        md: "1.5rem",
                                        lg: "1.5rem",
                                        xl: 0,
                                      },
                                    }}
                                  >
                                    <Grid
                                      container
                                      direction="row"
                                      justifyContent="space-between"
                                      alignItems="center"
                                    >
                                      <Grid item>
                                        <Grid
                                          container
                                          direction="row"
                                          justifyContent="flex-start"
                                          alignItems="center"
                                        >
                                          {/* {(disciplinas?.items?.length % 2 ===
                                            0
                                              ? disciplinas?.items?.length - 1
                                              : disciplinas?.items?.length) *
                                              disciplinas?.size <
                                            disciplinas?.total ? (
                                              <Grid
                                                item
                                                style={{
                                                  marginTop: "-.7rem",
                                                  color:
                                                    theme.cor.cor_preto_300,
                                                }}
                                              >
                                                <div
                                                  onClick={() => {
                                                    if (
                                                      configuracao?.progresso ===
                                                      false
                                                    ) {
                                                      handlePageDisciplina(
                                                        disciplinas?.page - 1 >
                                                          0
                                                          ? disciplinas?.page -
                                                              1
                                                          : Math.ceil(
                                                              disciplinas?.total /
                                                                disciplinas
                                                                  ?.items
                                                                  ?.length
                                                            )
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <NavigateBeforeIcon
                                                    style={{
                                                      width: "24px",
                                                      height: "24px",
                                                      cursor: "pointer",
                                                    }}
                                                  />
                                                </div>
                                              </Grid>
                                            ) : (
                                              ""
                                            )} */}
                                          <Grid item>
                                            <p
                                              style={{
                                                fontFamily:
                                                  theme.fonte.font.fonte_1,
                                                color: theme.cor.cor_preto_300,
                                                fontWeight:
                                                  theme.fonte.bold.semi_bold,
                                                paddingTop: "0px",
                                                marginTop: "0px",
                                                paddingLeft: ".5rem",
                                                fontSize: "22px",
                                              }}
                                            >
                                              Progresso
                                            </p>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                      {/* {(disciplinas?.items?.length % 2 === 0
                                          ? disciplinas?.items?.length - 1
                                          : disciplinas?.items?.length) *
                                          disciplinas?.size <
                                        disciplinas?.total ? (
                                          <Grid
                                            item
                                            style={{
                                              marginTop: "-.7rem",
                                              color: theme.cor.cor_preto_300,
                                            }}
                                          >
                                            <div
                                              onClick={() => {
                                                if (
                                                  configuracao?.progresso ===
                                                  false
                                                ) {
                                                  handlePageDisciplina(
                                                    disciplinas?.page * 2 >=
                                                      disciplinas?.total
                                                      ? disciplinas?.page -
                                                          1 ===
                                                        0
                                                        ? 1
                                                        : 1
                                                      : disciplinas?.page + 1
                                                  );
                                                }
                                              }}
                                            >
                                              <NavigateNextIcon
                                                style={{
                                                  width: "24px",
                                                  height: "24px",
                                                  cursor: "pointer",
                                                }}
                                              />
                                            </div>
                                          </Grid>
                                        ) : (
                                          ""
                                        )} */}
                                    </Grid>
                                  </Grid>

                                  {disciplinas?.items?.map(
                                    (disciplina: any) => (
                                      <>
                                        <Grid
                                          item
                                          xs={12}
                                          height={
                                            disciplinas?.items?.length % 2 !== 0
                                              ? "89%"
                                              : "44.5%"
                                          }
                                        >
                                          <Grid
                                            container
                                            xs={12}
                                            key={disciplina?.id}
                                            style={{
                                              background:
                                                theme.cor.cor_branco_100,
                                              padding: "1rem",
                                              paddingTop: ".1px",
                                              borderRadius: "1.5rem",
                                              boxShadow:
                                                "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                                            }}
                                            minHeight={"100%"}
                                            direction={"column"}
                                          >
                                            {configuracao?.progresso ===
                                            false ? (
                                              <>
                                                <Grid
                                                  item
                                                  xs
                                                  mt={"1rem"}
                                                  style={{
                                                    paddingTop: "1rem",
                                                    paddingBottom: "1rem",
                                                    background:
                                                      theme.cor.cor_fundo,
                                                    borderRadius: "2.5rem",
                                                    fontFamily:
                                                      theme.fonte.font.fonte_1,
                                                    color:
                                                      theme.cor.cor_preto_300,

                                                    width: "100%",
                                                    marginBottom: "-.3rem",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  {disciplina?.nome}
                                                </Grid>
                                                {/* <p
                                                  style={{
                                                    borderRadius: ".5rem",
                                                    fontFamily:
                                                      theme.fonte.font.fonte_1,
                                                    color:
                                                      theme.cor.cor_preto_200,
                                                    padding: "0px",
                                                    display: "flex",
                                                    justifyContent: "end",
                                                    alignItems: "end",
                                                    textAlign: "end",
                                                    fontSize: "12px",
                                                    marginBottom: ".2rem",
                                                  }}
                                                >
                                                  {`${disciplina?.concluido}/${disciplina?.total}`}
                                                </p> */}
                                                <Grid
                                                  item
                                                  height={"8px"}
                                                  mt=".8rem"
                                                >
                                                  <LinearProgress
                                                    sx={{
                                                      minHeight: "100%",
                                                      "& .MuiLinearProgress-bar":
                                                        {
                                                          background:
                                                            theme.cor
                                                              .cor_principal_400 /* `linear-gradient(45deg, rgba(${hexToRgb(
                                                          theme.cor
                                                            .cor_principal_500
                                                        )}, 1) 0%, rgba(${hexToRgb(
                                                          theme.cor
                                                            .cor_principal_400
                                                        )}, 1) 100%)`,*/,
                                                          borderRadius: "1rem",
                                                        },

                                                      borderRadius: 5,
                                                      background:
                                                        theme.cor
                                                          .cor_branco_300,
                                                    }}
                                                    variant="determinate"
                                                    value={
                                                      disciplina?.total === 0
                                                        ? 0
                                                        : (disciplina?.concluido *
                                                            100) /
                                                          disciplina?.total
                                                    }
                                                  />
                                                </Grid>
                                              </>
                                            ) : (
                                              <div
                                                style={{ height: "112.5px" }}
                                              >
                                                <Loading />
                                              </div>
                                            )}
                                          </Grid>
                                        </Grid>
                                      </>
                                    )
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        ) : (
                          ""
                        )}
                      </Grid>
                    </Grid>

                    <Grid item xs={12} style={{}}>
                      {aulas?.items?.length > 0 ? (
                        <span
                          style={{
                            fontFamily: theme.fonte.font.fonte_1,
                            fontWeight: theme.fonte.bold.bold,
                            color: theme.cor.cor_preto_300,
                            fontSize: "20px",
                          }}
                        >
                          {getContratoIps(decodedToken)
                            ? "Próximas aulas"
                            : "Descritivo das aulas no calendário"}
                        </span>
                      ) : (
                        ""
                      )}
                    </Grid>
                    <Grid item xs={12} style={{ overflowWrap: "break-word" }}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="stretch"
                        spacing={2}
                      >
                        {aulas?.items.map((aula: any) => (
                          <Grid xs={6} key={aula?.id} item>
                            <Cards aula={aula} decodedToken={decodedToken} />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md={5} lg={4} xl={3}>
                <Grid
                  item
                  xs={true}
                  style={{
                    marginBottom: "1rem",
                    padding: "1rem",
                    background: theme.cor.cor_branco_100,
                    borderTopLeftRadius: "1rem",
                    borderBottomLeftRadius: "1rem",
                    overflow: "auto",
                    maxHeight: "100vh",
                    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
                  }}
                >
                  <Recados
                    recados={recados}
                    setRecados={setRecados}
                    setConfiguracao={setConfiguracao}
                    configuracao={configuracao}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Home;
